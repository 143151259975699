import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Cookies from 'js-cookie'

import { FontAwesomeIcon } from 'lib/font-awesome'
import AppBarNav from 'components/AppBarNav'
import { connectResource } from 'common/utils/resource'
import { getDomain } from 'common/utils/helpers'
import { ChangeLanguage } from 'components/Pages/Auth/ChangeLanguage'
import './styles.scss';


export class AppBar extends Component {
  static propTypes = {
    user: PropTypes.shape({ data: PropTypes.shape({ id: PropTypes.number }) })
  };

  static defaultProps = {
    user: { data: { id: 0 } }
  };

  interval = null

  state = {
    showNav: false,
  };

  toggleNav = () => {
    this.setState({ showNav: !this.state.showNav });
  };

  componentDidMount() {
    if (get(this.props, 'profile.data.email_confirmation_needed')) {
      this.interval = setInterval(this.fetchProfile, process.env.REACT_APP_PROFILE_UPDATE_INTERVAL)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      get(prevProps, 'profile.data.email_confirmation_needed') &&
      !get(this.props, 'profile.data.email_confirmation_needed')
    ) {
      if (this.interval) {
        clearInterval(this.interval)
      }
    }
    if (
      !get(prevProps, 'profile.data.email_confirmation_needed') &&
      get(this.props, 'profile.data.email_confirmation_needed')
    ) {
      if (!this.interval) {
        this.interval = setInterval(this.fetchProfile, process.env.REACT_APP_PROFILE_UPDATE_INTERVAL)
      }
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  fetchProfile = () => {
    if (get(this.props, 'profile.data.email_confirmation_needed')) {
      this.props.profile.fetch()
    }
  }

  render() {
    const profileData = get(this.props, 'profile.data') || {}
    const userData = get(this.props, 'user.data') || {}
    return (
      <header className="site-header mb-5 br-dark">
        <div className="beta-bar">
          <p>
            <FormattedMessage id="header.privateBeta" />
            {' '}
            <a
              href={`https://${getDomain()}`}
              onClick={ev=> {
                ev.preventDefault()
                Cookies.remove('isBeta', { domain: getDomain() })
                window.location.href = `https://${getDomain()}`
              }}
            >
              <FormattedMessage id="header.goBack" />
            </a>
            {' '}
            <FormattedMessage id="header.toOld" />
            {' '}
            {get(userData, 'id') && (
              <button
                className="btn-link send-feedback"
                onClick={() => this.props.history.push({
                  state: { openFeedback: true },
                })}
              >
                <FormattedMessage id="header.sendFeedback" />
              </button>
            )}
          </p>
        </div>
        <div className="container header-bg">
          <nav className="navbar navbar-expand-md navbar-dark bg-dark">
            <Link to="/" className="navbar-brand mr-4">
              <img alt="" src="/img/logo.svg" />
            </Link>
            {!this.props.session && !userData.id ? <ChangeLanguage show={this.state.showNav} /> : null}
            {this.props.session && userData.id ? <AppBarNav show={this.state.showNav} /> : null}
          </nav>
        </div>
        {
          get(profileData, 'subscription.is_paused') &&
          <div className="header-info header-info--red header-info--static">
            <FontAwesomeIcon className="mr-2 fa-md" icon="exclamation-triangle" />
            <FormattedMessage id="pausedaccount.warning"/>
            {' '}
            <Link to="/account/pause">
              <FormattedMessage id="pausedaccount.activate" />
            </Link>
          </div>
        }
        {
          profileData.email_confirmation_needed &&
          <div className="header-info header-info--red relative">
            <FontAwesomeIcon className="mr-2 fa-md" icon="exclamation-triangle" />
            <FormattedMessage id="emailConfirmation.message" />: <Link to="/email-confirmation"><FormattedMessage id="emailConfirmation.title" /></Link>
          </div>
        }
      </header>
    );
  }
}

export default compose(
  withRouter,
  connect(state => ({ session: get(state, 'resource.session.data.token') })),
  connectResource({
    namespace: 'user',
    endpoint: 'user',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: false,
  }),
)(AppBar);
