import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lib/utils'

const propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

const defaultProps = {
  inputClassName: 'input-custom',
  readOnly: false,
  meta: {},
  onChange: noop
}

export default class TextInput extends PureComponent {
  handleChange = (e) => {
    this.props.onChange(e.target.value)
  }

  render() {
    const { inputClassName, inputComponent, input, meta, label, appendAddon, ...restProps } = this.props
    return (
      <input
        {...restProps}
        className={`form-control ${(this.props.meta.touched && this.props.meta.error) && 'error'} ${(this.props.inputClassName)}`}
        onChange={this.handleChange}
      />
    )
  }
}

TextInput.propTypes = propTypes
TextInput.defaultProps = defaultProps
