import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import Cookies from 'js-cookie'

import { connectFormResource, connectResource } from 'common/utils/resource'
import Signin from './Signin'
import { getCookieDomain } from 'common/utils/helpers'
import { update as intercomUpdate } from 'common/utils/intercom'


export class SigninContainer extends Component {

  onSubmit = (data) => {
    return this.props.session.create(data)
      .then(async _ => {
        if (Cookies.get('jwt')) {
          Cookies.set('logged_in', true, { domain: getCookieDomain() })
          await this.getUsersProfile()
        }
      })
  }

  getUsersProfile = async () => {
    const { user, profile, history } = this.props
    try {
      const userData = await user.fetch()
      await profile.fetch()
      intercomUpdate(userData)
      history.push('/')
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    return (
     <Signin
       {...this.props}
       onSubmit={this.onSubmit}
     />
    )
  }
}

export default compose(
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'user',
    endpoint: 'user',
    async: true,
    prefetch: false,
  }),
  connectFormResource({
    namespace: 'session',
    endpoint: 'api-token-auth',
    prefetch: false,
  }),
  reduxForm({
    form: 'sender',
  }),
)(SigninContainer)
