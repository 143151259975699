
import React from 'react'
import { useIntlContext } from 'providers/IntlContext';

import './styles.scss'

const languages = [
  {
    code: 'en',
    name: 'En'
  },
  {
    code: 'sv',
    name: 'Sv'
  }
]

export const ChangeLanguage = (props) => {
  const { switchTo, locale } = useIntlContext();
  return (
    <div className="change-language ml-auto">
      {
        languages.map((item, index) => {
          const className = `small ml-3 ${locale === item.code ? 'text-muted change-language__active' : ''}`
          return (
            <a
              key={index}
              href="/#"
              onClick={(e) => {
                e.preventDefault()
                switchTo[item.code]()
              }}
              className={className}
            >
              {item.name}
            </a>
          )
        })
      }
    </div>
  );
};
