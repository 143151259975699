import React from 'react'
import { ToastContainer } from 'react-toastify'
import { hot } from 'react-hot-loader'
import { ConnectedRouter } from 'connected-react-router'
import { compose } from 'redux'
import get from 'lodash/get'

import Authenticate from 'components/Authenticate'
import Routes from './Routes'
import ScrollToTop from 'components/ScrollToTop'
import FeedBackLayout from '../Feedback/FeedBackLayout'
import { connectResource } from 'common/utils/resource'
import Cookies from 'js-cookie'
import { IntlProviderWrapper } from "providers/IntlContext";
import { getDomain } from "common/utils/helpers";
import OfflineLayout from 'components/Offline/OfflineLayout'

export function App({ store, history, profile }) {
  const locale = get(profile, 'data.language')
  if (locale) {
    Cookies.set('gan-locale', locale, { domain: getDomain(null) })
  }

  return (
    <ConnectedRouter history={history}>
      <IntlProviderWrapper locale={locale} >
        <>
          <ScrollToTop>
            <OfflineLayout>
              <FeedBackLayout>
                <Authenticate>
                  <Routes />
                </Authenticate>
              </FeedBackLayout>
            </OfflineLayout>
          </ScrollToTop>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </>
      </IntlProviderWrapper>
    </ConnectedRouter>
  )
}

export default compose(
  hot(module),
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: false,
  })
)(App)
