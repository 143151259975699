import React, { memo } from 'react'

import AppBar from 'components/AppBar'
import Footer from 'components/Footer'


export function AppLayout({ children }) {
  return (
    <>
      <AppBar/>
      { children }
      <Footer />
    </>
  )
}

export default memo(AppLayout)
