import 'polyfills' // should be first

import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, combineReducers, compose as reduxCompose } from 'redux'
import { reducer as form } from 'redux-form'
import { createEpicMiddleware } from 'redux-observable'
import { middleware as cacheMiddleware, state as initialState } from './cache'
import { reducers } from 'store'
import { reducer as resource, epic as resourceEpic } from 'common/utils/resource'
import API, { configure as configureAPI } from 'api'

const epicMiddleware = createEpicMiddleware(resourceEpic, { dependencies: { API }})

const history = createBrowserHistory()

// support for redux dev tools
const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose

const initStore = (initialState) => createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    resource,
    ...reducers,
  }),
  initialState,
  compose(
    applyMiddleware(
      epicMiddleware,
      cacheMiddleware,
      routerMiddleware(history),
    )
  )
);

const store = initStore(initialState);

configureAPI(store)

export {
  store, history, initStore
}
