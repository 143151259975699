import { isEmpty, range, get } from 'lodash'

import { getDefaultDate } from './formatDate'
import { postDeliveryPrice } from './constants'


export function generateAttributes(data, values) {
  let obj = {}
  if (Array.isArray(data) && !isEmpty(data)) {
    data.forEach(i => {
      if(values && values.some(attr => attr === i.code)) {
        obj[i.code] = true
      } else {
        obj[i.code] = false
      }
    })
  }

  return obj
}

export function generateListOptions(data, additionalFields = {}) {
  if (!Array.isArray(data) || isEmpty(data)) return []
  return data.map(i => {
    const fields = Object.keys(additionalFields)
      .filter(key => typeof i[key] !== 'undefined')
      .map(key => ({ [`${additionalFields[key]}`]: i[key] }))
      .reduce((result, current) => ({...result, ...current}), {})

    return { value: i.hash, label: i.name, ...fields }
  })
}

export function getColumnsOptions(e) {
  if(!e) return []
  const keys = getColumnsKeys(e)
  const connectOptions = Object.entries(keys[0]).map(([key, value]) => ({ value: key, label: value }))
  return connectOptions
}

export function getColumnsKeys(e) {
  if(!e) return {}
  const paragraph = e.split(/\n/g)
  return paragraph.map(line => {
    const array = line.split(/[,;\t]/)
    const obj = {}
    array.forEach((i, index) => {
      obj[`field${index}`] = i
    })

    return obj
  })
}

export function formatSendAfter(count) {
  if(!count || (!count.days && !count.hours)) return 'Immediately'
  let days = count.days ? count.days + ' days' : ''
  let hours = count.hours? count.hours + ' hours': ''
  return `${days} ${hours}`.trim()
}

export function generateHours(hours = 24) {
  return range(hours).map(value => ({ value, label: value }))
}

export function getStatus(data, slug) {
  if (!data || !data.length) return
  return data.find(i => i.slug === slug).status
}

export function generateListOptionsObject(data, list) {
  if (!Array.isArray(data) || isEmpty(data)) return []
  if(list && Array.isArray(list)) {
    data = data.filter(dataItem => !list.some(listItem => dataItem.hash === listItem.hash))
  }
  return data.map(i => ({ value: { hash: i.hash, name: i.name }, label: i.name }))
}

export function generateSenderOptions(data, withId) {
  if (!Array.isArray(data) || isEmpty(data)) return []
  return data.map(i => ({ value: withId ? i.id : `${i.name}, ${i.email}`, label: `${i.name}, ${i.email}` }))
}

export function cutString(text = '', quantity) {
  if(text.length > quantity) {
    return `${text.substring(0, quantity)}...`
  }

  return text
}

export function getCheckedLists(objLists) {
  const keys = Object.keys(objLists);
  return keys.filter(function(key) {
    return objLists[key]
  })
}

export function onSubmitFail(errors, ...args) {
  for (let field in errors) {
    let invalidElement = document.querySelector(`[name="${field}"]`)
    if (invalidElement && invalidElement.focus) {
      invalidElement.focus()
      break
    }
  }

  return undefined
}

export function mapChartData(data = {}) {
  const labels = Array.isArray(data.unique) ? data.unique.map(item => getDefaultDate(item.end)) : [];
  const openings = Array.isArray(data.unique) ? data.unique.map(item => item.count) : [];
  const clicks = Array.isArray(data.clicks) ? data.clicks.map(item => item.count) : [];

  labels.unshift(0);
  openings.unshift(0);
  clicks.unshift(0);

  return {
    labels: labels,
    datasets: [
      {
        label: 'Openings',
        borderColor: '#2FB68B',
        data: openings,
        fill: false
      },
      {
        label: 'Click rate',
        borderColor: '#FFCC01',
        data: clicks,
        fill: false
      }
    ]
  }
}

export function getDomain(url, withPort = false) {
  const location = url || window.location.hostname
  const port = window.location.hostname === 'localhost' || url === 'localhost'
    ? withPort
      ? window.location.port
      : ''
    : ''
  return `${location.replace('beta.', '')}${port ? `:${port}`: ''}`
}

export function getCookieDomain(url) {
  const location = url || window.location.hostname
  return '.' + location.split('.').splice(2).join('.');
}

export function getPaymentUrl(url) {
  return `${window.location.origin}/${url}`
}

export function getAttributesObject(attributes = {}) {
  const newAttributes = {}
  Object.keys(attributes).forEach((attr) => {
    let newLabel = attr
    newLabel = newLabel.replace(/atr_/g, '').replace(/attributes./g, '');
    newAttributes[newLabel] = attributes[attr] || undefined;
  })
  return newAttributes
}

export function transactionsType(type) {
  switch (type) {
    case 1:
      return 'Registration'
    case 2:
      return 'Free to paid'
    case 3:
      return 'Paid to larger paid'
    case 4:
      return 'Recurring'
    default:
      return 'No'
  }
}

export function checkAccountStatus(profile) {
  return get(profile, 'data.subscription.is_contact_based', true) && get(profile, 'data.subscription.is_free', true)
}

export function tick(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  })
}

export const getUserLanguage = () => {
  const defaultLanguage = 'en'
  const language = window.navigator.language
  if (!language) {
    return defaultLanguage
  }
  if (language.includes('-')) {
    const languageParts = language.split('-')
    if (!languageParts[0]) {
      return defaultLanguage
    }
    return languageParts[0]
  }
  return language
}

export const calculatePriceData = ({ article, profile, address, billing, discount, checkvat }) => {
  const isYear = get(article, 'months', 1) === 12
  const articleSum = (isYear ?  get(article, 'price', 0) * 12 : get(article, 'price', 0))
  const discountMonth = get(article, 'start') === 0 ? get(discount, 'data.order_price', 0) : 0
  const discountSum = isYear ? discountMonth * 12 : discountMonth

  const isInvoiceDeliveryByPost = get(profile, 'data.invoice_delivery') === 1
  const country = get(profile, 'data.different_billing_address')
    ? get(billing, 'data.country', 'SE')
    : get(address, 'data.country', 'SE')
  const isCountrySupportDeliveryByPost = ['SE'].indexOf(country) !== -1
  const currency = get(profile, 'data.currency_code', 'SEK')
  const isAllowedCurrency = ['SEK'].indexOf(currency) !== -1
  const isShowAdditionalPostFee = isInvoiceDeliveryByPost &&
    isYear &&
    isCountrySupportDeliveryByPost &&
    isAllowedCurrency

  const articleSumWithDiscountBase = articleSum - discountSum
  const articleSumWithDiscount = isShowAdditionalPostFee
    ? articleSumWithDiscountBase + postDeliveryPrice
    : articleSumWithDiscountBase

  const articleVat = get(checkvat, 'data.pays_vat') ? articleSumWithDiscount * 0.25 : 0

  const conversionValue = currency === 'SEK'
    ? articleSumWithDiscount
    : articleSumWithDiscount * 10

  return {
    isYear,
    discountMonth,
    discountSum,
    currency,
    isShowAdditionalPostFee,
    articleSumWithDiscount,
    articleVat,
    conversionValue
  }
}
