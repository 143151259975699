export default {
  /*
   * Common words are used at more than one places.
   * Keep in mind when rename
   */
  'common.shown': 'Showing',
  'common.shownOf':'of',
  'common.showAll':'Show all',
  'common.emailToLowerCase': 'email',
  'common.delete': 'Delete',
  'common.close': 'Close',
  'common.toHome': 'to Home',
  'common.name': 'name',
  'common.updated': 'updated',
  'common.active': 'active',
  'common.contacts': 'contacts',
  'common.subscriptions': 'subscriptions',
  'common.attributes': 'attributes',
  'common.billingCompany': 'Billing company',
  'common.editYourInformation': 'Edit your information',
  'common.lists': 'lists',
  'common.mails': 'Mails',
  'common.mailsLowerCase': 'mails',
  'common.yourInformation': 'Your information',
  'common.address': 'Address',
  'common.company': 'Company',
  'common.email': 'Email',
  'common.reference': 'Reference',
  'common.sum': 'Sum',
  'common.select': 'Select',
  'common.description': 'Description',
  'common.yourEmail': 'Your email',
  'common.period': 'Period',
  'common.drafts': 'Drafts',
  'common.price': 'Price',
  'common.sek': ' SEK',
  'common.usd': ' USD',
  'common.card': `card`,
  'common.sent': `Sent`,
  'common.sender': `Sender`,
  'common.sendOut': `Send mail`,
  'common.sendOutTest': 'Send test mail',
  'common.sentAfter': `Sent after`,
  'common.report': `Report`,
  'common.list': `List`,
  'common.listsTitle': `Lists`,
  'common.sentTo': `Sent to`,
  'common.opened': `Opened`,
  'common.actions': `Actions`,
  'common.invoice': `invoice`,
  'common.sekMonth': 'SEK/month',
  "common.definitions": `Definitions`,
  'common.vat': `VAT {percent}`,
  'common.upgradeNow': 'Upgrade now',
  'common.total': 'Total',
  'common.time': 'Time',
  'common.reports': 'reports',
  'common.links': 'links',
  'common.paymentMethod': 'Payment method',
  'common.bounces': 'bounces',
  'common.welcome': 'Welcome',
  'common.templates': 'templates',
  'common.save': 'Save',
  'common.saveAndReturn': 'Save and return',
  'common.manageAttributes': 'Manage attributes',
  'common.showAttributes': 'Show attributes',
  'common.addSubscriptions': 'Add subscriptions',
  'common.goToContacts': 'Go to my contacts',
  'common.createNewEmail': 'Create a new email',
  'common.add': 'Add',
  'common.cancelChange': 'Cancel change',
  'common.cancelled': 'Cancelled!',
  'common.changePassword': 'Change password',
  'common.resetPassword': 'Reset password',
  'common.cancel': 'Cancel',
  'common.submit': 'Submit',
  'common.cancelSubscription': 'Unsubscribe',
  'common.back': 'Back',
  'common.byPlacing': 'By placing an order, I agree to Get a Newsletters',
  'common.myCardWillBeCharged': 'and that my card will be charged every month until I cancel my subscription.',
  'common.termsOfUse': 'terms of use',
  'common.areYouSure': 'Are you sure?',
  'common.areYouSureList': 'Are you sure you want to delete this list?',
  'common.editList': 'Edit list',
  'common.backToLogin': 'Back to login',
  'common.update': 'Update',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.searchNameEmail': 'Search for name or email address...',
  'common.inputs.firstName': 'First Name',
  'common.inputs.lastName': 'Last Name',
  'common.inputs.email': 'Email',
  'common.inputs.emailAddress': 'Email address',
  'common.inputs.password': 'Password',
  'common.inputs.passwordConfirm': 'Confirm password',
  'common.inputs.newPassword': 'New Password',
  'common.inputs.repeatPassword': 'Repeat Password',
  'common.inputs.country': 'Country',
  'common.inputs.company': 'Company',
  'common.inputs.subscribers': 'Subscribers',
  'common.inputs.autoresponders': 'Autoresponders',
  'common.inputs.actions': 'Actions',
  'common.inputs.organizationNumber': 'Organization number',
  'common.inputs.address': 'Address',
  'common.inputs.address2': 'Address 2',
  'common.inputs.zipCode': 'Zip Code',
  'common.inputs.vatId': 'VAT id',
  'common.inputs.city': 'City',
  'common.inputs.extraEmail': 'Extra Email',
  'common.inputs.phoneNumber': 'Phone Number',
  'common.inputs.reference': 'Reference',
  'common.inputs.passwordAgain': 'Repeat password',
  'common.inputs.chooseLists': 'Select a list',
  'common.inputs.subscribersToLetter': 'Subscribe to our newsletter',
  'common.inputs.copyLinkBelow': 'Copy link below',
  'common.inputs.senderName': 'Sender name',
  'common.inputs.senderEmail': 'Sender email',
  'common.inputs.subject': 'Subject',
  'common.inputs.message': 'Message',
  'common.inputs.nextUrl': 'Next URL',
  'common.inputs.copyFormBelow': 'Copy form below',
  'common.inputs.buttonText': 'Button Text',
  'common.titles.contact': 'Contact',
  'common.titles.contacts': 'Contacts',
  'common.titles.list': 'List',
  'common.titles.lists': 'Lists',
  'common.titles.status': 'Status',
  'common.titles.attributes': 'Attributes',
  'common.titles.forms': 'Forms',
  'common.titles.subscriptions': 'Subscriptions',
  'common.titles.created': 'Created',
  'common.titles.cancelled': 'Cancelled',
  'common.titles.autoresponder': 'Autoresponder',
  'common.chooseANewPassword': 'Choose a new password',
  'common.summary': 'Summary',
  'common.start': 'start',
  'common.download': 'Download',
  'common.retry': 'Retry',
  'common.search': 'Search',
  'common.replan': 'Replan',
  'common.support': 'Support',
  "common.security": `Security`,
  'common.preview': 'Preview',
  'common.desktop': 'Desktop',
  'common.mobile': 'Mobile',
  'common.agree': 'Agree',
  'common.schedule': 'Schedule',
  'common.continue': 'Continue',
  'common.SaveAndContinue': 'Save and continue',
  'common.yourOrder': 'Your order',
  'common.choosePayment': 'Choose payment method',
  'common.subscription': ' subscription',
  'common.improvements': 'Improvements',
  'common.ok': 'OK',
  'common.navigation': 'Navigation',
  'common.accountManagement': 'Account management',
  'common.quickAdd': 'Quick add',
  'common.yearly': 'Yearly',
  'common.monthly': 'Monthly',
  'common.year': 'year',
  'common.month': 'month',
  'common.upgrade': 'Upgrade',
  'common.mail': `Mail`,
  'common.xlsChoose': 'Select a file',
  'common.inputs.name': 'Name',
  'common.inputs.description': 'Description',
  'common.inputs.partnerEmail': 'Partner Email',
  'common.inputs.days': 'Days:',
  'common.inputs.hours': 'Hours:',
  'common.inputs.timeAndDate': 'Time and date:',
  // /lists
  'lists.addListBtn': 'Create list',
  'lists.activeSubscribers': `{count, plural,
    one {{count} active subscriber}
    other {{count} active subscribers}
  }`,
  'lists.autoresponder': `{count, plural,
    =0 {No autoresponders are running}
    one {{count} autoresponder is running}
    other {{count} autoresponders are running}
  }`,

  // /attributes
  'attributes.addAttributeBtn': 'Add attribute',
  'attributes.addAttributeInputPlaceholder': 'Enter new attribute name...',
  'attributes.usage': 'Used by {count} contacts',
  'attributes.placeholder.title': 'Are you ready to personalize your newsletter?',
  'attributes.placeholder.description': `Attributes are personal labels.
  Create custom attributes of your own choice such as; company name, member type or country.`,
  'attributes.deleted': 'Attribute deleted successfully',

  // /contacts
  'contacts.checkedBtn': `{count, plural,
    =0 {No checked contacts}
    one {One checked contact}
    other {{count} checked contacts}
  }`,
  'contacts.selectedBtn': `{count, plural,
    =0 {No selected contacts}
    one {One contact}
    other {{count} contacts}
  }`,
  'subscriptions.selectedBtn': `{count, plural,
    =0 {No selected subscriptions}
    one {One subscription}
    other {{count} subscriptions}
  }`,
  'contacts.modalHeader.deleteContacts': `{count, plural,
    one {Delete contact}
    other {Delete contacts}
  }`,
  'contacts.modalHeader.exportContacts': `{count, plural,
    one {Export contact}
    other {Export contacts}
  }`,
  'contacts.modalHeader.addSubscriptions': `{count, plural,
    one {Add subscription}
    other {Add subscriptions}
  }`,
  'contacts.subscribe.areYouSure': `{count, plural,
    one {Are you sure you want to add a subscription for one contact?}
    other {Are you sure you want to add subscriptions for {count} contacts?}
  }`,
  'contacts.exportContacts': `Export contacts`,
  'contacts.connectFields.title': 'Match the columns in your uploaded file to your contact profiles',
  'contacts.connectFields.tellUs': 'Select the column that includes email addresses*',
  'contacts.connectFields.consent': 'I have premission to send an email to them*',
  'contacts.connectFields.consentDescription': 'I guarantee that all imported email addresses belongs to my own clients and/or have approved to receive newsletters.',
  'contacts.connectFields.description': `Match the columns in your uploaded file to your contact profiles by choosing the first row for each field.
  If only an email address, select which row to connect to the e-mail field and continue.`,
  'contacts.file.updateContact': `Update contact profiles with new information.
  Fields excluded from the import will be left out.`,
  'contacts.file.existingContact': `Update existing contact profiles`,
  'contacts.file.title': `Import your contacts from a file`,
  'contacts.file.description': `Select a list, upload your file and choose whether to update existing contacts or not.`,
  'contacts.file.chooseFileDescription': `Choose which list your contacts should subscribe too.`,
  'contacts.file.chooseList': `Select a list`,
  'contacts.file.uploadYourFile': `Upload your file`,
  'contacts.file.uploadYourFileDescription': `Valid file types are xls, xlsx or csv-files.`,
  'contacts.copy.title': `Copy/Paste`,
  'contacts.copy.description': `Select a list, copy/paste your contacts and choose whether to update existing contacts or not.`,
  'contacts.copy.pasteContacts': `Paste contact information`,
  'contacts.copy.pasteContactsDescription': `Paste your contacts one per row. Separate the fields by commas, tabs or semicolons.`,
  'contacts.import.title': 'Import contacts',
  'contacts.import.description': 'How would you like to import your contacts? Copy and paste from a text file or upload a file.',
  'contacts.import.copyDescription': 'Copy and paste contacts from a text file.',
  'contacts.import.importNow': 'Import now',
  'contacts.import.fileDescription': 'Upload xls, xlsx or csv-files.',
  'contacts.success.title': 'Sit back and relax, we’ll take it from here!',
  'contacts.success.description': `Your file is now uploaded and ready to be imported into our system. You can close the browser,
  take a cup of coffee or why not start working on your first newsletter? Meanwhile,
  we’ll take care of your contacts.`,
  'contacts.success.notify': "We'll notify you as soon as it’s done.",
  'contacts.summary.title': "Import done",
  'contacts.summary.description': `Below you can see a summary of the import.
  Duplicates will not be imported but they will still get subscribed to the list you chose in the previous step.`,
  'contacts.summary.newContacts': 'New contacts',
  'contacts.summary.updatedContacts': 'Updated contacts',
  'contacts.summary.newSubscriptions': 'New subscriptions',
  'contacts.attribute.title': 'Add attribute',
  'contacts.attribute.created': 'New attribute has been created.',
  'contacts.attribute.delete': 'Delete attribute',
  'contacts.attribute.edit': 'Edit attribute',
  'contacts.attribute.updated': 'The attribute has been updated.',
  'contacts.attribute.usedBy': 'Used by',
  'contacts.attribute.contacts': 'contacts.',
  'contacts.attribute.occurs': 'An error occurs trying to delete attribute',
  'contacts.attribute.areYouSure': 'Are you sure you want to delete attribute',
  'contacts.attribute.theAttribute': 'The attribute',
  'contacts.attribute.deleting': 'Deleting attribute',
  'contacts.attribute.hasBeen': 'has been removed.',
  'contacts.addContact.title': 'Add contact',
  'contacts.addContact.saved': 'A new contact has been saved.',
  'contacts.addContact.addSubscriptions': 'Add subscriptions',
  'contacts.addContact.addSubscriptionsSelectPlaceholder': 'Choose one or multiple lists...',
  'contacts.addContact.addSubscriptionsSelectHelper': 'Pro tip! Quickly add new lists by typing a list name that does not already exist.',
  'contacts.addAttribute': 'Add Attribute',
  'contacts.cancelSubscriptionText': "This might take a while. You can put this process in the background and we will notify you when it's ready.",
  'contacts.allDone': 'All done',
  'contacts.error': "The error occurs and subscriptions can't be cancelled.",
  'contacts.subscriptionsAreYouSure': "Are you sure you want to cancel {count} subscribers?",
  'contacts.subscriptionsCopyAreYouSure': "Are you sure you want to copy {count} subscribers?",
  'contacts.subscriptionsMoveAreYouSure': "Are you sure you want to move {count} subscribers?",
  'contacts.putProcess': "Put process in background",
  'contacts.subscriptions.takeAWhile': "This might take a while. You can put this process in the background and we will notify you when it's ready.",
  'contacts.subscriptions.getData': "Click the download button to get your data.",
  'contacts.subscriptions.download': "Download",
  'contacts.subscriptions.lists': "Choose lists to subscribe for",
  'contacts.subscriptions.exportSubscription': "Export subscriptions",
  'contacts.api.title': "Subscription forms",
  'contacts.api.addForm': "Create form",
  'contacts.api.create': "Create your first subscription form",
  'contacts.api.createDescription': "Subscription forms are used for your website or social media platforms to let people subscribe to your lists.",
  'contacts.api.createBtn': "Create a subscription form",
  'contacts.api.delete': "You are going to delete the following form: {name}",
  'contacts.api.deleted': "Form deleted successfully",
  'contacts.api.placeholder.title': "Forms leads to signups!",
  'contacts.api.placeholder.description': "Make your followers an offer they cannot refuse and let them know what you are about. Let's get some signups!",
  'contacts.filter': "Filter",
  'contacts.numberOf': "Number of contacts:",
  'contacts.listEmpty': "List of contacts is empty",
  'contacts.placeholder.title': "Import and start connecting",
  'contacts.placeholder.description': "The best way to get started is to import your contacts through a file upload. If you only have a few contacts, just copy and paste and there you go!",
  'contacts.filterByActive': "Filter by active",
  'contacts.filterByUpdated': "Filter by updated",
  'contacts.filterByList': "Filter by list",
  'contacts.radio.showAll': "Show all contacts",
  'contacts.radio.showActive': "Active contacts",
  'contacts.radio.showInactive': "Inactive contacts",
  'contacts.radio.last7Days': "Last 7 days",
  'contacts.radio.last30Days': "Last 30 days",
  'contacts.radio.last365Days': "Last 365 days",
  'contacts.radio.showAllSubscriptions': "Show all subscriptions",
  'contacts.radio.notCancelled': "Not cancelled",
  'contacts.radio.cancelled': "Cancelled",
  'contacts.radio.filterBy': "Filter by list",
  'contacts.showAllContacts': "Show contacts from all lists",
  'contacts.lists.autorespondersRunning': "{value} autoresponder(s) is running",
  'contacts.lists.autorespondersNotRunning': "No autoresponders are running",
  'contacts.lists.activeSubscribers': "{value} active subscribers",
  'contacts.lists.delete': "You are going to delete the following list: '{value}'",
  'contacts.lists.placeholder.title': "Categorize your audience",
  'contacts.lists.placeholder.description': "Your lists helps you to organize your contacts and send customized messages to the right groups. You can create as many lists as you´d like, starting right now.",
  'contacts.options.choose': "Action...",
  'contacts.options.cancel': "Unsubscribe",
  'contacts.options.delete': "Delete",
  'contacts.options.export': "Export",
  'contacts.options.copy': "Copy to...",
  'contacts.options.move': "Move to...",
  'contacts.chooseFor': "Choose lists to subscribe for",
  'contacts.subscription.filter': "Filter creation date",
  'contacts.subscription.cancelled': "Cancelled / Not cancelled",
  'contacts.modal.export.header': "Your request is being processed",
  'contacts.modal.cancelSubscriptions': "Are you sure you want to cancel this subscription?",
  'contacts.modal.reactivateSubscriptions': "You are about to resubscribe a cancelled subscription. Make sure the recipient really wants this before performing this action. Be aware that you can increase the risk of being stuck in spamboxes or even blacklisted if you do this without the recipients explicit approval.",
  'contacts.modal.addANewSubscription': `Add a new subscription`,
  'contacts.delete.areYouSure': `{count, plural,
    one {Are you sure you want to delete one contact?}
    other {Are you sure you want to delete {count} contacts?}
  }`,
  'contacts.delete': `Delete`,
  'contacts.modal.areYouSure': `Are you sure you want to delete {email}?`,
  'contacts.showNotSubscribed': 'Show contacts without a subscription',

  // single contact page
  'contact.editContact': `Edit contact`,
  'contact.activeSince': `Active since`,
  'contact.cancelledOn': `Cancelled on`,
  'contact.cancelSubscription': `Cancel subscription`,
  'contact.reactivateSubscription': `Reactivate subscription`,
  'contact.step.enterContact': `ENTER CONTACT INFORMATION`,
  'contact.step.fillInContactInformation': `Fill in contact information. Email address is required.`,
  'contact.step.manageAttributes': `MANAGE ATTRIBUTES`,
  'contact.step.addExtraInformation': `Add extra information about your contact.`,
  'contact.step.addSubscriptions': `ADD SUBSCRIPTIONS`,
  'contact.step.chooseOneOrMoreLists': `Choose one or more lists the contact should subscribe too.`,
  'contact.contactStatus': `Contact status`,
  'contact.activeStatus': `Active`,
  'contact.lastUpdated': 'Last updated',


  // affiliate
  'affiliate.bannerAndLinks.showCode': 'Show code',
  'affiliate.bannerAndLinks.title': 'Banners & links',
  'affiliate.bannerAndLinks.description': `Below you will find a text link and multiple image banners that you can use on your website or blog to tell your visitors about Get a Newsletter.
  When someone clicks on the link/ad it is registered and if an account is ordered you will receive commission.`,
  'affiliate.bannerAndLinks.textLink': 'Text link',
  'affiliate.bannerAndLinks.imageBanners': 'Image banners',
  'affiliate.bannerAndLinks.imageBannersDescription': `Find a banner that suits your target audience, copy the code and add it to your website or blog to receive commission for the visitor who clicks through to Get a Newsletter and make an order.`,
  'affiliate.getStarted.title': 'Get started',
  'affiliate.getStarted.description': `Getting started is a breeze.
  Copy and paste your personal code for image banners and links to start marketing Get a Newsletter and get money in your pocket.
  We keep track of who has been told by you and you get paid for each person who uses your link to register an account with us.`,
  'affiliate.getStarted.thisIsWhatYouEarn': 'This is what you earn',
  'affiliate.getStarted.signupForFree': 'Signup for free account',
  'affiliate.getStarted.upgradeToPaidAccount': 'Upgrade to paid account',
  'affiliate.getStarted.upgradeToPaidAccountDescription': `Commissions are excluding taxes and is only available for companies registered for F-tax. You get 20% of
  the account price continuously every time a user renews his or hers account – year after year. Your earnings
  can only be paid when you reach 500 SEK or more.`,
  'affiliate.getStarted.howToGetStarted': 'How to get started',
  'affiliate.getStarted.howToGetStartedDescription': `Write a blog post about Get a Newsletter, share us on Facebook/Twitter or put an ad on your site. Remember
  to use a link or banner image that contains your affiliate ID, you can `,
  'affiliate.getStarted.howToGetStartedDescriptionLink': 'find them here.',
  'affiliate.overview.title': 'Overview',
  'affiliate.overview.emptyComissionMainText': `We are glad that you found our affiliate program and that you want to tell your friends about Get a Newsletter!`,
  'affiliate.overview.emptyComissionSmall': `Start by reading our get started guide, you can find it in the menu to the left.
  When you start to earn money this area will show your progress and how much you have earned.`,
  'affiliate.overview.getStarted': 'Get started',
  'affiliate.overview.haveEarned': `Well done! You have earned {total} {currency} so far. Below you can see a summary of the accounts referred by you and the
  money you earned.`,
  'affiliate.overview.moneyEarned': 'Money earned',
  'affiliate.overview.commissionWaiting': 'Commission waiting',
  'affiliate.overview.commissionPaid': 'Commission paid',
  'affiliate.overview.commissionTotal': 'Total commission',
  'affiliate.overview.requestPayment': 'Request payment',
  'affiliate.overview.typeOfAccounts': 'Type of accounts',
  'affiliate.overview.freeAccount': 'Free account',
  'affiliate.overview.paidAccount': 'Paid account',
  'affiliate.overview.viewTransactions': 'View transactions',
  'affiliate.transactions.title': 'Transactions',
  'affiliate.transactions.description': `You have referred {users} user(s),
  {payed} of which have chosen to pay for an account.
  This have generated you a kickback on {summ} {currency} in total.`,
  'affiliate.links.overview': 'Overview',
  'affiliate.links.getStarted': 'Get started',
  'affiliate.links.bannersLinks': 'Banners & links',
  'affiliate.links.transactions': 'Transactions',
  'affiliate.links.requestPayment': 'Request payment',
  'affiliate.links.payouts': 'Payouts',
  'affiliate.payouts.downloadSpec': 'Download specification',
  'affiliate.payouts.downloadInvoice': 'Download invoice',
  'affiliate.payouts.title': 'Payouts',
  'affiliate.payouts.description': `This is a list of all your payouts since we introduced self-billing(Aug 2016).
  You can download the invoice and specification for each payout and see the current status.
  As soon as we make a payout the status will change from Not paid to Paid.`,
  'affiliate.request.title': `Request payment`,
  'affiliate.request.earn': `You need to earn over 500 SEK in commission in order to request a payment.`,
  'affiliate.request.tips': `Tips to reach your target`,
  'affiliate.request.writeBlog': `Write a blog post about Get a Newsletter, share us on Facebook/Twitter or put an ad on your site.
  Remember to use a link or banner image that contains your affiliate ID,
  you can `,
  'affiliate.request.findThemHere': `find them here`,
  'affiliate.request.findTips': `Find more tips`,
  'affiliate.request.description': `Fill in the information below and click Request payment.
  We will create a self-invoice, which means that we will create an invoice in your name.`,
  'affiliate.request.descriptionLink': ` Read more about self-billing`,
  'affiliate.request.comission': `Commission to payout: `,
  'affiliate.request.enterInfo': `Enter your information`,
  'affiliate.request.enterInfoDescription': `The information below is the same as on My account > My details, so if you make updates below you update your information on both places.`,
  'affiliate.request.paymentInfo': `Invoice and payment info`,
  'affiliate.request.paymentInfoDescription': `Enter your bankgiro number and approve the terms.`,
  'affiliate.request.confirm': `I hereby confirm that my company is registered for F-tax and that the above information is correct.
  I also accept that Get a Newsletter Scandinavia AB issues a self-invoice for payment of commission.`,
  'affiliate.request.recommend': `Earn money by recommending Get a Newsletter`,
  'affiliate.request.thanks': `Thank you`,
  'affiliate.request.telling': `Thanks for telling your friends about Get a Newsletter.
  We have created a self-invoice and will pay the earned commission within 10 days.
  You can see the status of your payout under `,
  'affiliate.request.payouts': `payouts`,
  'affiliate.request.also': `We also sent you an email containing both the invoice and the specification for you to download.`,
  'affiliate.request.seeStatus': `See status on Payouts`,

  // API tokens
  'apiTokens.title': 'API tokens',
  'apiTokens.plugins': 'Plugins',
  'apiTokens.pluginsDescriptions': 'Do you use Wordpress? We have a plugin for Wordpress which simplifies the process of adding subscriptions form your web site.',
  'apiTokens.seeInstructions': 'See instructions',
  'apiTokens.description': "If you want to connect to Get a Newsletter's API, you have to create an API token in order to authenticate.",
  'apiTokens.deleteModal': "You are going to delete the following token: '{token}'",
  'apiTokens.addToken': 'Create API token',
  'apiTokens.placeholder.title': 'Let your systems come together',
  'apiTokens.placeholder.description': 'An API allows you to connect your Get a Newsletter account with another system. Create API tokens or check out the API documentation and start building your own connections.',

  // Notifications
  'notifications.unread': 'Unread notifications',
  'notifications.all': 'All notifications',
  'notifications.placeholder.title': 'No unread notifications',
  'notifications.placeholder.description': 'You currently don´t have any notifications, but this is where you will find all your unread messages from now on. Stay tuned!',

  // Profile
  'profile.confirmModal.modalName': 'You need to confirm your password',
  'profile.confirmModal.title': 'You need to confirm your password in order to update your user details.',
  'profile.confirmModal.label': 'Enter current password',
  'profile.confirmModal.button': 'Confirm password',
  'profile.loginDetails': 'Login details',
  'profile.accountOwner': 'Account owner',
  'profile.accountOwner.description': 'Your address and company/organization/personal information is mainly used for invoices. It also helps us to keep track of our users.',
  'profile.loginDetails.description': 'The login details are used when logging in to your account. The system will send messages to the stated email address when needed.',
  'profile.updateOwnerSettings': 'Save owner settings',
  'profile.updateBillingSettings': 'Save billing settings',
  'profile.invoiceSettings': 'Invoice settings',
  'profile.invoiceSettings.description': 'Please bare in mind that "Send invoice by post" is only available for yearly subscriptions and will be charged with 50 sek. If you choose this option for a monthly plan you will not receive your invoices by post.',
  'profile.invoiceSettings.updateInvoiceSettings': 'Update invoice settings',
  'profile.invoiceSettings.differentBillingAddress': 'I would like to have a different billing address',
  'profile.invoiceSettings.sendingOptions': 'Sending options',
  'profile.invoiceSettings.sendingByEmail': 'Send invoice by email',
  'profile.invoiceSettings.sendingByPost': 'Send invoice by post',
  'profile.invoiceByPost': 'Invoice by post',
  'profile.billingAddress': 'Billing address',
  'profile.shortPasswordError': `Password must be at least 6 characters long!`,
  'profile.existPasswordError': `Password do not match!`,
  // Senders
  'senders.title': 'Your senders',
  'senders.description': 'A sender is the name and email you use as the From-address when sending your newsletters.',
  'senders.deleteModal': "You are going to delete the following sender: '{name}'",
  'senders.addSender': 'Create sender',
  'senders.addSender.description': 'We recommend that you use your own name and email address. Avoid using addresses like no-reply@company.com',
  'senders.editSender': 'Edit sender',

  // Settings
  'settings.newsletter': 'Newsletter (English)',
  'settings.newsletterDescription': 'Get the latest news and tips about e-mail marketing by signing up on our monthly newsletter. You can easily unsubscribe by clicking on the unsubscribe link in our newsletters.',
  'settings.language': 'Language',
  'settings.languageDescription': 'Choose between english or swedish.',
  'settings.timeZone': 'Time zone',
  'settings.timeZoneDescription': 'The selected time zone is used for reference when you schedule a draft.',
  'settings.generalSettings': 'General settings',
  'settings.partners': 'Partners',
  'settings.deleteModal': "You are going to delete the following partner: '{name}'",
  'settings.addPartner': 'Add partner',

  // Account
  'account.title': 'Your current subscription',
  'account.description': 'As your list grows we will move you between tiers.',
  'account.billingHistory': 'Billing history',
  'account.upgradeAccount': 'Upgrade your account',
  'account.upgradeAccountDescription': 'Upgrade and select the subscription that suits your needs. Contact us if you want to send to more than 1 million recipients.',
  'account.upgrade': 'Upgrade',
  'account.newCreditCard.thanks': "Thanks for choosing us!",
  'account.newCreditCard.youCanUse': "You can now continue using you account.",
  'account.payByInvoice.yourInvoiceHasBeenSent': 'Your invoice has been sent!',
  'account.payByInvoice.thankYou': 'Thank you! We are looking forward to receive your payment. We promise to do everything we can to make our service a pleasure to use.',
  'account.downloadInvoice': 'Download Invoice',
  'account.payByInvoice.asSoonAsWeRecieveYourPayment': 'As soon as we recieve your payment we will upgrade your account. You can see all your invoices under ',
  'account.payByCard.thanksForUpgrading': 'Thanks for upgrading!',
  'account.payByCard.yourAccountIsNowUpgraded': 'Your account is now upgraded and you can start sending more mails. We promise to do everything we can to make our service a pleasure to use.',
  'account.payByCard.youCanSee': 'You can see all your invoices under',
  'account.pauseAccountDescription': 'Pause your subscription when the current period ends. You can do everything except sending newsletters when the account is paused.',
  'account.activateAccountDescription': 'Activate your account to be able to send newsletters.',
  'account.pause': 'Pause',
  'account.activate': 'Activate',
  'account.pauseAccount': 'Pause your account',
  'account.activateAccount': 'Activate your account',
  'account.deleteAccount': 'Delete account',
  'account.deleteAccountDescription': 'Cancel your subscription and delete all information linked to the account. The account can not be restored once it is deleted.',
  'account.activeCard': 'Your active card',
  'account.activeCardDescription': 'This is your active card. Changed it by clicking the link next to the card number.',
  'account.changeCard': 'Change card',
  'account.downgradeAccount': "Downgrade to free",
  'account.downgradeAccountDescription': "Downgrade to Free account at the end of the current period",
  'account.downgradeAccountModalDescription': "You are sure you want to downgrade to free at the end of the current subscriptions?",
  'account.downgradeAccountBtn': "Downgrade",
  'account.downgradeAccountSuccess': "You account will be downgraded to free after the end of the current subscription.",

  //Auth
  'auth.everyDay': '20 000 users put their trust in us every day',
  'auth.leftCard': 'Bästa med er är att ni ständigt utvecklar verktyget som man gör nyhetsbrevet med så de är så lätt att göra och man har ingen ”ångest” att nu måste jag göra nyhetsbrevet utan enkelt och snabbt att göra de. 5 min sen är de klart så man kan nyttja tiden till att utveckla företaget istället.',
  'auth.rightCard': 'Vi har använt GAN till våra nyhetsbrev i 7 år och är mycket nöjda! Lätt att använda och aldrig några problem. Utan tvekan vår mest prisvärda marknadsföringskanal.',
  'auth.loginToNewsletter': 'Login to Get a Newsletter',
  'auth.forgotPassword': 'Forgot your password?',
  'auth.login': 'Login',
  'auth.loginAgree': 'By signing up, I agree to Get a Newsletter',
  'auth.terms': 'Terms of use.',
  'auth.dontHaveAccount': "Don't have an account yet? ",
  'auth.alreadyHaveAccount': "Already have an account? ",
  'auth.signup': 'Sign Up',
  'auth.signupNow': 'Create free account',
  'auth.signin': 'Sign in',
  'auth.getStarted': 'Get started',
  'auth.forFree': 'for free',
  'auth.signupDescription': 'Send 500 mails/month to 100 contacts for free. No hidden fees or period of notice.',
  'auth.resetPasswordInstructions': "Enter you email address and we'll send you a link to reset your password.",
  'auth.resetPasswordButton': 'Save new password',

  //Delete Account
  'deleteAccount.title': 'Delete account',
  'deleteAccount.links.keep': 'Keep my account',
  'deleteAccount.links.give': 'Give feedback',
  'deleteAccount.links.pause': 'Pause my account',
  'deleteAccount.links.delete': 'Delete',
  'deleteAccount.saveMoney.emailMarketing': 'Did you know that email marketing is almost 40 times better at acquiring new customers than social media?',
  'deleteAccount.saveMoney.didYouKnow': `Did you know that you can get Get a Newsletter for less?
  An annual subscription is 20% cheaper per month than a monthly subscription. `,
  'deleteAccount.saveMoney.didYouKnowLink': `Calculate your annual costs here.`,
  'deleteAccount.technicalProblem.sorry': `Sorry to hear that! We will do our best to help you solve this! There are two ways we can help you:`,
  'deleteAccount.technicalProblem.emailUs': `Email us at {link} and describe your problem.`,
  'deleteAccount.technicalProblem.visitOur': `Visit our {link} – we hope you find the answer to your question there!`,
  'deleteAccount.noResults.sorry': `We are sorry to hear that! Before you make up your mind, you may want to read our top tips on how to achieve your goals with email marketing?`,
  'deleteAccount.noResults.7tips': `7 Tips - how to write newsletters that will get read`,
  'deleteAccount.noResults.5tips': `5 tips on how to find new customers through email marketing`,
  'deleteAccount.noResults.tips': `Tips to increase newsletter engagement rate`,
  'deleteAccount.anotherTool.sad': `We are sad to hear that! We are always working on developing new and great features demanded by our customers.
  If you are missing something in our tool - please contact us and give us your input!
  Before you hit the Cancel button, we would like to remind you on what you get as a customer:`,
  'deleteAccount.anotherTool.blockEditor': `Block editor where you can create stunning newsletters without any tech skills.`,
  'deleteAccount.anotherTool.unlimited': `Unlimited amount of emails to send every month.`,
  'deleteAccount.anotherTool.statistics': `Statistics for better follow up.`,
  'deleteAccount.anotherTool.simple': `Simple pricing based on the number of your contacts.`,
  'deleteAccount.anotherTool.complete': `Complete access to all our features - regardless of your price level.`,
  'deleteAccount.anotherTool.subscription': `Subscription form to help you build an audience.`,
  'deleteAccount.anotherTool.free': `Free support.`,
  'deleteAccount.noContacts.understand': `We understand! Having a large number of subscribers is a goal most companies have.
  Before you cancel your account it can be worthwhile to look at our tips on how you can get more email signups.`,
  'deleteAccount.noContacts.tell': `Tell people about your newsletter on your business card.`,
  'deleteAccount.noContacts.ask': `Ask existing customers if they want to subscribe to your newsletter.`,
  'deleteAccount.noContacts.create': `Create a subscription form and place it in several locations on your website.`,
  'deleteAccount.noContacts.link': `Link to your subscription form in your email signature.`,
  'deleteAccount.noContacts.leave': `Leave a registration form at checkout if you have a physical store.`,
  'deleteAccount.noContacts.share': `Share your newsletter on social media.`,
  'deleteAccount.noContacts.organize': `Organize a competition that requires subscription to your newsletter.`,
  'deleteAccount.closingBusiness': `We are sad to hear that! You can choose to either close your account or pause your account.
  You will not be charged during the paused period and can activate your account when you are ready to get started with your newsletters again.`,
  'deleteAccount.other.before': `Before you hit the Cancel button we have a few questions. Did you know that…`,
  'deleteAccount.other.percent': `85 percent of the world's Internet users use email?`,
  'deleteAccount.other.more': `More and more people get smartphones today which allows people to access their email in multiple devices.`,
  'deleteAccount.other.average': `Average unique email opens is 20%?`,
  'deleteAccount.other.organic': `The organic reach on Facebook is 6% and on Twitter only 8%- 10% of your followers see your tweet.`,
  'deleteAccount.other.emails': `Email marketing is unbeatable when compared to other marketing channels!`,
  'deleteAccount.other.email': `Emails have a click-through rate that is 50 - 100 times higher than posts on social media?`,
  'deleteAccount.radios.technical': `I have a technical problem I need help to solve`,
  'deleteAccount.radios.save': `I am trying to save money`,
  'deleteAccount.radios.getting': `I am not getting any results from my email marketing`,
  'deleteAccount.radios.switching': `I am switching from Get a Newsletter to another email marketing tool`,
  'deleteAccount.radios.enough': `I do not have enough contacts`,
  'deleteAccount.radios.closing': `I am closing my business`,
  'deleteAccount.radios.other': `Other`,

  //Detailed report
  'detailedReport.bounces.title': `Bounces`,
  'detailedReport.bounces.seeAll': `See all bounces`,
  'detailedReport.bounces.goodJob': `Good job! You don't have any bounces.`,
  'detailedReport.bounces.mostActive': `Most active recipients`,
  'detailedReport.bounces.popularLinks': `Popular links`,
  'detailedReport.deleteModal.title': `You are going to delete the following report:`,
  'detailedReport.deleteModal.yes': `Delete the matching sent mail`,
  'detailedReport.share.facebook': `Share newsletter on Facebook`,
  'detailedReport.share.twitter': `Share newsletter on Twitter`,
  'detailedReport.share.linkedin': `Share newsletter on LinkedIn`,
  'detailedReport.share.online': `Link to share newsletter online`,
  'detailedReport.actions.preview': `Preview mail`,
  'detailedReport.actions.export': `Export report`,
  'detailedReport.actions.delete': `Delete report`,
  'detailedReport.sent': `Sent on {sent} to {sentTo} subscribers in `,
  'detailedReport.toggle.share': `Share`,
  'detailedReport.toggle.actions': `Actions`,
  'detailedReport.exportModal.click': `Click the download button to get your data.`,
  'detailedReport.recipients.empty': `There is no recipients`,
  'detailedReport.recipients.seeAll': `See all recipients`,
  'detailedReport.openings.title': `Openings and link clicks`,
  'detailedReport.openings.first': `First 24 hours`,
  'detailedReport.openings.all': `All activity`,
  'detailedReport.openings.openings': `Openings`,
  'detailedReport.openings.rate': `Click rate`,
  'detailedReport.links.title': `There is no links`,
  'detailedReport.links.see': `See who clicked`,
  'detailedReport.links.noRecipients': `There is no Recipients`,
  'detailedReport.links.back': `Back to links`,
  'detailedReport.links.seeAll': `See all links`,
  'detailedReport.links.activeRecipients': `Active recipients`,
  'detailedReport.links.popularLinks': `Popular links`,
  'detailedReport.links.bounces': `Bounces`,
  'detailedReport.links.recipients': `Recipients`,
  'detailedReport.links.links': `Links`,

  //Email confirmation
  'emailConfirmation.title': `You need to confirm your email address`,
  'emailConfirmation.message': `Important message`,
  'emailConfirmation.titleAlready': `Confirm your email address`,
  'emailConfirmation.description': `Your newsletter is now moved to scheduled mails and we will send out your newsletter the following date and time:`,
  'emailConfirmation.descriptionAlready': `Your email address already confirmed`,
  'emailConfirmation.descriptionSuccess': `Your email address is now comfirmed.`,
  'emailConfirmation.text': `A confirmation email has been sent to your inbox.
  Click on the confirmation link in order to confirm your email address.
  If you can not find the email please check your SPAM/Junk folder,
  or use the button below to resend the confirmation mail.`,
  'emailConfirmation.button': `Resend confirmation email`,

  //Add form
  'addForm.title': `Create subscription form`,
  'addForm.question': `Create a form and start collecting signups today.`,
  'addForm.regularForm': `Regular form`,
  'addForm.regularFormText': `A regular form is an embeddable form for your website. Use the snippet code and place it wherever you want on your website.`,
  'addForm.select': `Select`,
  'addForm.popupForm': `Popup form`,
  'addForm.popupFormText': `Create a popup form for your website. You can show it on top of your content when a user is about to
  leave your site.`,
  'addForm.regularForm.description': `Include the form on your website or link to the form on Get a Newsletter.
  If you want to make changes just update the fields below, save and copy the code again.
  If you use a link there's no need to copy it again. Our system automatically saves all new changes.`,
  'addForm.regularForm.use': `Use the subscription form`,
  'addForm.regularForm.copy': `Copy the following HTML-code and paste it on your website and start collecting new subscribers.
  Remember that you have to be in HTML-mode on your website when you paste the code.`,
  'addForm.regularForm.useLink': `Or use a link instead`,
  'addForm.regularForm.copy2': `Copy the following HTML-code and paste it on your website and you have a subscription link.
  A subscription link will link to the same form on Get a newsletter so you don't have to include it on your own site.`,
  'addForm.regularForm.nameForm': `Name your form`,
  'addForm.regularForm.nameFormText': `Use a descriptive name for your form. This is only for internal usage, to keep your forms apart.`,
  'addForm.regularForm.contactFields': `Contact fields`,
  'addForm.regularForm.contactFieldsText': `Choose if you want the fields for first name and last name visible. The e-mail field is mandatory.`,
  'addForm.regularForm.attributeFields': `Attribute fields`,
  'addForm.regularForm.attributeFieldsText': `Choose if you want to collect additional information in the form.`,
  'addForm.regularForm.listAndSender': `List and sender`,
  'addForm.regularForm.showAdvancedSettings': `Show advanced settings`,
  'addForm.regularForm.confirmationEmail': `Confirmation email`,
  'addForm.regularForm.confirmationEmailText': `The confirmation mail is sent out to new subscribers so that they can confirm their subscription.`,
  'addForm.regularForm.formSettings': `Form settings`,
  'addForm.regularForm.formSettingsText': `Use a custom landing page and write a custom text for the submit button.`,
  'addForm.regularForm.updateForm': `Update form`,
  'addForm.regularForm.createForm': `Create form`,

  //Home
  'home.greatJob': `Great job! You have successfully completed all the steps in our get started-guide.`,
  'home.hide': `Hide this section`,
  'home.welcome': `Welcome, {name}!`,
  'home.welcomeEmpty': `Welcome!`,
  'home.welcomeBack': `Welcome back, {name}!`,
  'home.welcomeBackEmpty': `Welcome back!`,
  'home.getStarted': `Get started with Get a Newsletter by completing the steps below.`,
  'home.confirmEmail': `Confirm email address`,
  'home.collect': `Collect subscribers`,
  'home.createLetter': `Create newsletter`,
  'home.sendLetter': `Send newsletter`,
  'home.quickLinks': `Quick links`,
  'home.upgrade': `Upgrade account`,
  'home.createList': `Create list`,
  'home.importContacts': `Import contacts`,
  'home.createMail': `Create mail`,
  'home.currentSubscripton': `My current subscription`,
  'home.theCurrentAccountPeriod': `The current account period ends on:`,
  'home.mailsLeft': `Mails left this month:`,
  'home.balanceResets': `The balance resets on the 10th each month`,
  'home.upgradeAccount': `Upgrade account`,
  'home.viewBillingHistory': `View billing history`,
  'home.youHave': `You have 1 contacts on your account.`,
  'home.weWill': `We will automatically move you between tiers based on how many contacts you have.`,

  //Account status
  'accountStatus.free': `You have a free account`,
  'accountStatus.noFree' : 'You are charged 80.00 {currency} each month',
  'accountStatus.numberOfContacts' : `{minNumberOfContacts } - {maxNumberOfContacts} contacts`,
  'accountStatus.numberOfMails' : `{maxNumberOfMails} mails`,
  'accountStatus.myContacts': `You have {content} contacts in your account.`,
  'accountStatus.mailsLeft': `You currently have: {balance}/{maxNumberOfMails} mails`,
  'accountStatus.freeMail': `The balance resets on the 8th of each month.`,
  'accountStatus.nextBilling': 'Next billing date: {date}.',
  'accountStatus.billingHistory': 'See billing history',
  'accountStatus.freeMails': `You may send another {balance} mails this month.`,
  "accountStatus.upgrade": "You need to upgrade your account in order to send more mails.",

  //Application NavBar
  'appNavBar.profileDropDown.upgrade': 'Upgrade account',
  'appNavBar.profileDropDown.profile': 'Profile',
  'appNavBar.profileDropDown.account': 'Account',
  'appNavBar.profileDropDown.notifications': 'Notifications',
  'appNavBar.profileDropDown.settings': 'Tool settings',
  'appNavBar.profileDropDown.senders': 'Senders',
  'appNavBar.profileDropDown.api': 'API',
  'appNavBar.profileDropDown.affiliate': 'Affiliates',
  'appNavBar.profileDropDown.logout': 'Sign out',
  'appNavBar.actions.createList': 'Create List',
  'appNavBar.actions.createMail': 'API',
  'appNavBar.actions.createForm': 'Create Form',
  'appNavBar.actions.import': 'Import contact',

  //Charts
  "charts.countOfSubscribers": `{subscribers} subscribers`,

  //Footer
  "footer.text": `© {year} Get a Newsletter Scandinavia AB`,
  "footer.support": `Support`,
  "footer.policy": `Privacy policy`,
  "footer.terms": `Terms of use`,

  //Invoices
  "invoices.payCard": `Pay via card`,
  "invoices.paid": `Paid`,
  "invoices.pending": `Pending...`,
  "invoices.overpaid": `Overpaid`,
  "invoices.resolve": `Resolve`,
  "invoices.inProgress": `Repayment in progress...`,
  "invoices.willRepay": `We will repay the amount of {balance} {currency} to you as soon as possible.
  When the amount is repaid the status will change from Repayment pending to Paid.`,
  "invoices.invoiceNo": `Invoice no:`,
  "invoices.amount": `Amount`,
  "invoices.excess": `Excess amount:`,
  "invoices.followingCard": `We will repay {balance} {currency} to the following credit card:`,
  "invoices.repayAmount": `Repay amount`,

  "card.yourCurrentCard": `Your current card`,
  "card.newCard": `Pay with a new card`,
  "card.payCard": "Pay with card",
  "card.date": `Date`,
  "card.pay": `Pay`,

  //MonthSummary
  "monthSummary.heading": `This months summary`,
  "monthSummary.mails": `Mails sent`,
  "monthSummary.newSub": `New subscribers`,
  "monthSummary.cancelSub": `Cancelled subscribers`,

  //Page loading
  "pageLoading.error": `Error occurs when loading page! `,
  "pageLoading.timeOut": `Page loading is taking a long time... `,
  "pageLoading.reload": "Please try to reload you browser window",

  //Pagination
  "pagination.prev": `Previous`,
  "pagination.next": `Next`,

  //Tables
  "tables.isEmpty": `List is empty`,

  //Modal add list
  "listModal.add": `Add list`,

  //Modal delete account
  "modalDeleteAccount.deleteButton": `Delete my account`,
  "modalDeleteAccount.confirm": ` I confirm that I want to delete my account`,
  "modalDeleteAccount.text1": `Are you sure you want to permanently delete your account?`,
  "modalDeleteAccount.text2": `If you confirm, your subscriptions, contacts and reports will be permanently deleted and you will not be able to login to the system.`,
  "modalDeleteAccount.text3": `The remaining period of your subscription won't be reimbursed.`,
  "modalDeleteAccount.deleteHeader": `Delete account`,

  //Modal Autoresponder
  "modalResponderAutoresponder.header": `Auto responder schedule:`,

  //Modal Replan Scheduled
  "modalReplanScheduled.modalTitle": `Replan Email`,
  "modalReplanScheduled.modalText": `Here you can change the date and time for when your mail is going to be sent. The current send time is set to.`,

  //Modal Welcome
  "modalWelcome.didNotForgotAboutWorkflow": `We did not forget about the general worfklow of the tool.`,
  "modalWelcome.newLook": `New look. New features.`,
  "modalWelcome.getANewsletter": `A better Get a Newsletter.`,
  "modalWelcome.weFinallyHaveTheHonour": `We finally have the honour to announce a new and improved version of our tool. Some key upgrades include a new blockeditor with tons of beautiful templates and designed popup forms with endless possibilities.`,
  "modalWelcome.takeATour": `Take a tour`,
  "modalWelcome.newForms": `New forms`,
  "modalWelcome.collectSubscribers": `Collect subscribers in style.`,
  "modalWelcome.createAppealingPopup": `Create appealing popup forms for your website. Show the form on page load or make it stick to the bottom of your site.`,
  "modalWelcome.newBlockedtor": `New Blockeditor`,
  "modalWelcome.createStunningNewsletters": `Create stunning newsletters.`,
  "modalWelcome.dozensOfNewFonts": `Dozens of new fonts. Background images. Flexible layouts. You, the customer, spoke. We listened. The wish list has been long and we think we have made some appreciated updates.`,
  "modalWelcome.betterWorkflow": `Better workflow`,
  "modalWelcome.majorImprovements": `We've announced some major improvements, but we did not forget about the general worfklow of the tool.`,

  //Upgrade Page
  "upgrade.upgradeAccount": `Upgrade your account`,
  "upgrade.separateBillingAddress": `Separate billing address`,
  "upgrade.billingCompany": `Billing company`,
  "upgrade.youNeverPayMore": `No need to pay more than necessary! Your current number of contacts will always keep you in the right price range.`,
  "upgrade.alwaysTheRightPrice": `Always the right price`,
  "upgrade.noEntryFee": `Cancel at any time`,
  "upgrade.freeSupport": `Free support`,
  "upgrade.yourInformation": `Your information`,
  "upgrade.accountOwner": `Account owner`,
  "upgrade.yourAddress": `Your address and company/organization/personal information is mainly used for invoices. It also helps us to keep track of our users.`,
  "upgrade.invoiceSettings": `Invoice settings`,
  "upgrade.billingAddress": `Billing address`,
  "upgrade.payByCard": `Pay by card`,
  "upgrade.payBuy": `Pay by`,
  "upgrade.getInstantAccess": `Get instant access to your upgraded account.`,
  "upgrade.payByInvoice": `Pay by invoice`,
  "upgrade.getAccessToYourUpgradedAccount": `Get access to your upgraded account in 3–5 days after payment.`,
  "upgrade.sek120": `120 SEK/Month`,
  "upgrade.sek150": `150 SEK/Month including VAT`,
  "upgrade.youWillBeCharged": `You will be charged today for the coming month, and then on the {day}th each month until you cancel your subscription.`,
  "upgrade.payYearly": `Pay yearly and save 20%.`,
  "upgrade.reviewYourInformation": `Review your information before completing the payment. All your information is safe with us and you can cancel the subscription at any time.`,
  "upgrade.orgNr": `VAT: `,
  "upgrade.byPlacing": `By placing an order, I agree to Get a Newsletters `,
  "upgrade.termsOfUse": ` terms of use `,
  "upgrade.myCardWillBeCharged": ` and that my card will be charged every month until I cancel my subscription.`,
  "upgrade.showingMonthlyPrices": `Showing monthly prices excluding VAT.`,
  "upgrade.switchToYearlyAndSave": 'Switch to yearly and save 20%. Pay by invoice is only available for yearly subscriptions.',
  "upgrade.myCurrentSubscription": `My current subscription`,
  "upgrade.upgradeSubscription": `Upgrade subscription`,
  "upgrade.howManyContacts": `How many contacts do you have?`,
  "upgrade.payMonthly": `Pay monthly or yearly`,
  "upgrade.chooseCurrency": 'Choose currency',
  "upgrade.choosePeriod": 'Choose period',
  "upgrade.startUpgradeNow": 'Start upgrade now',
  "upgrade.upgradeNow": 'Upgrade now',
  "upgrade.upgradeLater": 'Upgrade later',
  "upgrade.startUpgradeLater": 'Start upgrade later',
  "upgrade.startTheUpgradeRightNowAndPay": 'Start the upgrade right now and pay full price for the current period.',
  "upgrade.startTheUpgradeAtTheBeginning": `Start the upgrade at the beginning of the next reset. You wont be able the send extra mail during the current period.`,
  "upgrade.currentSubscription": `Current subscription`,
  "upgrade.discount": `Discount`,
  "upgrade.totalDiscount": `Total discount`,



  //Start Autoresponder Mail
  "autoresponderMail.start": `Start Autoresponder Mail`,

  //Support Page
  "supportPage.theBestThing": `Bästa med er är att ni ständigt utvecklar verktyget som man gör nyhetsbrevet med så de är så lätt att göra och man har ingen ”ångest” att nu måste jag göra nyhetsbrevet utan enkelt och snabbt att göra de. 5 min sen är de klart så man kan nyttja tiden till att utveckla företaget istället.`,
  "supportPage.weHaveUsedGan": `Vi har använt GAN till våra nyhetsbrev i 7 år och är mycket nöjda! Lätt att använda och aldrig några problem. Utan tvekan vår mest prisvärda marknadsföringskanal.`,
  "supportPage.takeAdvantage": `Take advantage of our knowledge of newsletters in the form of email school, handbook, blog, youtube channel, inspiration gallery and our newsletter.`,
  "supportPage.trustInUs": `20 000 users put their trust in us every day`,

  //Templates
  "templates.create": `Create Template`,
  "templates.blockEditor": `Block editor templates`,
  "templates.advancedTemplates": `Advanced templates`,
  "templates.placeholder.title": `What kind of layout do you want today?`,
  "templates.placeholder.description": `A template is a saved email design that can be reused when creating new newsletters. It´s time to get creative and set up the foundation for your newsletter!`,


  //Reports
  "reports.view": `View report`,
  "reports.previewMail": `Preview mail`,
  "reports.copyMail": `Copy mail`,
  "reports.deleteReport": `Delete report`,
  "reports.matching": `Delete the matching sent mail`,
  "reports.delete": 'You are going to delete the following report: {name}',

  //Terms
  "terms.title": `Terms of use`,
  "terms.theseTerms": `These Terms of Service (the “Agreement”) which govern the use of our Service (as defined below) have been entered into between Get a Newsletter Scandinavia AB (“Get a Newsletter”, "the company" “we”, “us” or “our”), Swedish registration number 556831-5765, a company incorporated under the laws of Sweden, and you as a user of the Service (“you” or the “User”).`,
  "terms.theFollowingWords": `The following words shall, in this Agreement, have the meaning assigned to them below:`,
  "terms.content": `Content, meaning all texts, graphics, videos, pictures and all other information that the User stores, publishes or sends to others via the Service`,
  "terms.originalContent": `Original Content, meaning the original content of the Service, such as code, texts, graphics, videos, pictures and all other information provided by Get a Newsletter`,
  "terms.meaningThePerson": `Subscriber, meaning the person who is a recipient of the User’s newsletter`,
  "terms.serviceMeaningAnyOfTheServices": `Service, meaning any of the services that Get a Newsletter provides, including, but not limited to, our online tool for sending newsletters`,
  "terms.siteMeaningTheDomain": `Site, meaning the domain or sub-domains of getanewsletter.com and all other domains owned by Get a Newsletter`,
  "terms.userAccount": `User Account, meaning the individual account used by the User`,
  "terms.scopeOfTheService": `The scope of the service`,
  "terms.theServiceIsAnOnlineTool": `The Service is an online tool for creating and sending newsletters via email. The Service consist of three branches, through which you can; (i) collect contacts through websites or add existing contacts which you can organize in different lists for customized mailings, (ii) create your own designed newsletters and (iii) see results and statistics regarding your Subscribers engagement.`,
  "terms.theUserShallUseTheSite": `The User shall use the Site and the Service in accordance with the Agreement, instructions from Get a Newsletter, applicable law, rules and regulations.`,
  "terms.userAccounts": `User accounts`,
  "terms.inOrderToUse": `In order to use the Service, you have to register a User Account. Get a Newsletter reserves the right to deny access to the Service.`,
  "terms.youMustAtAllTimes": `You must at all times keep your user information current, true and complete.`,
  "terms.theUserAccountMayOnlyBeUsed": `The User Account may only be used by the account owner and it’s representatives. The account owner is solely responsible and liable for all access to the User Account and all activities conducted therein. The User undertakes to handle credentials and other account information in a safe manner and to immediately inform Get a Newsletter of any unauthorized use of the User Account.`,
  "terms.theUserAccountHasASubscription": `The User Account has a subscription that includes a certain number of contacts and/or emails (“Capacity”) per period. The Capacity is reset on a specific date each month and any unused Capacity will not be transferred to the following period.`,
  "terms.theServiceIncludes": `The Service includes functions for uploading, sending, linking, communicating and otherwise making certain information available including, but not limited to, text, graphics, videos, photos or other material. You are at all times responsible for the Content uploaded and/or created under your User Account.`,
  "terms.byUploadingContent": `By uploading Content to the Service, you warrant that the Content in no way violates any national or international legislation and that you (i) are the owner of the uploaded Content or (ii) hold a valid license or other permit to such Content from the appropriate rights holder.`,
  "terms.youHaveTheResponsibility": `You have the responsibility to ensure that any information provided during the use of the Service is accurate, complete and current at all times.`,
  "terms.paymentAndFees": `Payment and fees`,
  "terms.thePriceForTheService": `The price for the Service is based on the number of contacts you have on your account or the number of mails reserved per month. The current price list is available on the Site and is shown excluding VAT. Get a Newsletter reserves the right to change the price list for the Service by publishing a new pricing list on the Site and/or sending a notification by email to the User.`,
  "terms.feesForTheService": `Fees for the Service are paid in advance on a monthly or annual basis. When the contract period has ended, it will automatically be renewed for the same period the Customer has previously chosen.`,
  "terms.theUserWarrants": `The User warrants that the User has the right to place an order and provide us with the invoice details and/or use the provided credit card.`,
  "terms.theUserIsObligated": `The User is obligated to always provide Get a Newsletter with correct invoice details and/or valid credit card information and agrees that we will charge the monthly or annual amount regularly. The User also has an obligation to have funding available for us to collect the fees as we require, and make sure that the invoices are paid in due time.`,
  "terms.interestOnOverduePayment": `Interest on overdue payment shall accrue according to the Swedish Interest Act (Sw. räntelag (1975:635), from the day the invoice is due. Get a Newsletter also reserves the right to turn off the user's access to the Service until full payment is made.`,
  "terms.affiliateAndPartner": `Affiliate and partner`,
  "terms.getANewsletterOffers": `Get a Newsletter offers the User the possibility to advertise, link to and promote the Service to third parties. The User will be entitled to remuneration for each third party that signs up for the Service after having clicked the users unique link (hereinafter, the “Remuneration”).`,
  "terms.theSizeOfTheRemuneration": `The size of the Remuneration is determined in accordance with Get a Newsletter’s, at each time applicable, remuneration policy published in the service. Get a Newsletter may, in its sole discretion and at any time, change the remuneration policy. All changes to the said policy will be published in the service.`,
  "terms.theRemunerationIsTransferred": `The Remuneration is transferred to the User upon request and will be paid out maximum once a month and only if the Remuneration exceeds a total amount of SEK 500 at the time of the payment. Remuneration that is not transferred to the User will be carried over to the following month.`,
  "terms.theRemunerationIsOnlyTransferred": `The Remuneration is only transferred out to Swedish entities holding a valid F-tax certificate. Please note that the User is solely responsible for the payment of taxes and other fees. Get a Newsletter reserves the right to make deductions from the Remuneration in order to cover any potential fees when administrating or paying the Remuneration.`,
  "terms.transferWillOnlyBeMade": `Transfer will only be made to the bank account that is registered in the User Account. If no bank account details are registered in the User Account, the transfer will be retained until the User provides Get a Newsletter with correct bank details.`,
  "terms.getANewsletterReserves": `Get a Newsletter reserves the right to, at any time and in its sole discretion, withdraw the right to Remuneration and exclude the User from the possibility to collaborate with Get a Newsletter. This may be the case, but is not limited to, User’s breach of the Agreement, if Get a Newsletter considers that the traffic generated by the User isn’t qualitative or if the traffic is generated in an unloyal way.`,
  "terms.theUserAcknowledges": `The User acknowledges and agrees that:`,
  "terms.trafficToTheService": `traffic to the Service generated by the User must be made through an active action, meaning that the person following the User’s ad, link or recommendation must be aware of the consequences of following such ad, link or recommendation.`,
  "terms.thatHasBeenObtained": `that has been obtained by the User must be repaid to Get a Newsletter if the orders, on which the Remuneration is based, are repurchased or cancelled.`,
  "terms.collectionOfData": `collection of data on which the Remuneration is based on is handled by Get a Newsletter or a third party contracted by Get a Newsletter. Get a Newsletter uses cookies, in accordance with our Privacy Policy, to track the activities on which the Remuneration is based.`,
  "terms.theAgreementShallBeInForce": `The Agreement shall be in force as long as the User has a registered User Account. If the User wishes to terminate the Agreement, the User must terminate the User Account.`,
  "terms.ifTheUserTerminates": `If the User terminates the Account, there will be no refund of payment for any paid fees and the account will be deleted and the User will no longer have access to the Service`,
  "terms.thisAgreementIsConsidered": `This Agreement is considered to be terminated when the User Account has been deleted.`,
  "terms.getANewsletterReservesTheRight": `Get a Newsletter reserves the right to suspend any User or terminate any User Account immediately if due payment is not made or activities occur which constitutes or may constitute a violation of the Agreement, our instructions or of any applicable local or international law, rules or regulations. In the case of such activities no refunds will be made of prepaid fees.`,
  "terms.terminationOfThisAgreement": `Termination of this Agreement does not remedy the User from any liability which has occurred during the time the Agreement was in force.`,
  "terms.getANewsletterMaintains": `Get a Newsletter maintains the right to use the User’s information, including Content and information about Subscribers, in an anonymized, consolidated and aggregated manner to improve, develop and modify the Service and to compile statistics and other marketing information.`,
  "terms.allInformationThatIsStored": `All information that is stored by the user in the Service are owned by the User, is confidential and only used by the User. Get a Newsletter reserves the right to use the information to verify that the Agreement is followed. Under no circumstances will Get a Newsletter sell or otherwise grant third parties access to User information unless so required by law.`,
  "terms.inEventOfTerminationOfTheAgreement": `In event of termination of the Agreement, the User’s files, settings and emails will be deleted within ninety (90) days from the said termination. Get a Newsletter will, after the termination of the Agreement, only store such information that follows from this Agreement, is required by law or that is required in order to administrate any remaining invoices or other remaining business with the User.`,
  "terms.getANewsletterWillAlsoAfterTheTermination": `Get a Newsletter will also after the termination of the Agreement store information about previous Users for conventional CRM-use, including marketing, as well as for statistical purposes and to improve the Service.`,
  "terms.getANewsletterWillProcessPersonalData": `Get a Newsletter will process personal data regarding Subscribers on the User’s behalf. Hence, the User is considered the data controller whereas Get a Newsletter is the User’s data processor. The User’s privacy policies or instructions are therefore applicable to the processing of personal data executed by Get a Newsletter on the User’s behalf, in the extent that such policies and instructions have been announced to and accepted by Get a Newsletter.`,
  "terms.intellectualProperty": `Intellectual property`,
  "terms.theServiceAndItsOriginalContent": `The Service and its Original Content, features, functionality, and design elements are and will remain Get a Newsletter’s, or its suppliers’, exclusive property. The User’s use of the Site and the Service is limited to the right granted to the User under the Agreement. Get a Newsletter’s intellectual property may not be used in connection with any product or service without Get a Newsletter’s prior written consent.`,
  "terms.theSite": `The Site, the Service or any portion thereof may not be reproduced, duplicated, copied, sold, resold, visited or otherwise exploited for any purpose inconsistent with the limited rights granted to the User under the Agreement.`,
  "terms.changesOfTheService": `Changes of the service and system maintenance`,
  "terms.getANewsletterReservesToModify": `Get a Newsletter reserves the right to modify, suspend, and/or discontinue the Service or parts of the Service at any time including, but not limited to (i) functionality, (ii) features, and (iii) services, with or without notice. All new functionalities, features or services introduced to the Service will be subjected to the Agreement.`,
  "terms.weWillMakeReasonableEfforts": `We will make reasonable efforts to keep the Service operational and fully functional during updates. If the changes or the system maintenance are of significant nature and requires downtime, Get a Newsletter will notify the User.`,
  "terms.getANewsletterReservesTheRightToShutDown": `Get a Newsletter reserves the right to shut down the Service for updating and/or for safety reasons.`,
  "terms.limitationOfLiability": `Limitation of liability`,
  "terms.allUseOfTheService": `All use of the service is at your own risk and the service is provided "as is", without warranties of any kind. This means that Get a Newsletter accepts no responsibility for any loss of data, the response the user receives on his newsletters or that all mails arrives to the recipient. Get a Newsletter can not guarantee that the service is completely free from downtime or errors.`,
  "terms.getANewsletterIsNotResponsible": `Get a Newsletter is not responsible for any damage, loss, or injury resulting from hacking, tampering, or other unauthorized access or use of the Service or User Account.`,
  "terms.getANewslettersLiability": `Get a Newsletter’s liability under the Agreement shall always be limited to a total amount corresponding to the fees paid by the User during the last twelve (12) months.`,
  "terms.prohibitedUseOfService": `Prohibited use of service`,
  "terms.theUserAgreesToUseTheService": `The user agrees to use the service for legal and ethical purposes only and agrees to not use the Service to send, transmit or otherwise communicate information that may be; insulting, offensive, racist, terrorist, indecent or illegal. It is also forbidden to use the service for affiliate marketing or destructive activities such as the spread of viruses, spam, or other activities that may harm Get a Newsletter, the Service, or the recipients in any way.`,
  "terms.youAreNotAllowedToSell": `You are not allowed to sell, license, sublicense, modify, distribute, reproduce, transmit, publish or decompile the Service or create derivative works of the Service.`,
  "terms.theServiceMayNeverBeUsed": `The Service may never be used to send unwanted emails (so-called “spam”) and no mailing may under any circumstances be made to Subscribers that has not approved their subscription.`,
  "terms.changesOfTheAgreement": `Changes of the agreement`,
  "terms.getANewsletterMay": `Get a Newsletter may, at any time and for any reason, amend the Agreement by publishing the amended Agreement on the Site. The amended Agreement shall automatically be effective upon publishing.`,
  "terms.governingLaw": `Governing law and dispute resolution`,
  "terms.theAgreementShall": `The Agreement shall be construed in accordance with, and governed by, Swedish law.`,
  "terms.anyDisputeArising ": `Any dispute arising from or relating to this Agreement shall be settled by the public courts of Sweden, Stockholm district court being the first instance.`,
  "terms.theAgreementIsTranslated": `The agreement is translated into several languages, if the agreement can be interpreted differently because of linguistic differences, the Swedish agreement has precedence.`,
  "terms.dataProcessingAgreement": `Data processing agreement`,
  "terms.addendumToTheCurrentTerms": `Addendum to the current Terms of service ("TOS") between you (the "Controller") and Get a Newsletter Scandinavia AB (the "Processor") regarding the processing of the Controller's personal data within the service. This Data processing agreement is henceforth referred to as the "Agreement". In cases where there are contradictions between TOS and the Agreement, TOS will rule.`,
  "terms.theProcessorUndertakesToProcess": `The Processor undertakes to process Personal Data only in accordance with TOS, the Controller’s instructions and the Swedish Personal Data Act (1998:204) and Personal Data Regulation (1998:1191). On May 25 2018 these regulations will be replaced by The EU General Data Protection Regulation 2016/679 ("GDPR"), which instead applies as of this date.`,
  "terms.itMayBeThatTheUser": `It may be that the user of the service is a data processor. In such cases this Agreement shall be considered a sub-processing agreement where the user is considered the processor and Get a Newsletter is the sub-processor. In such cases, what is stated regarding Controller in this Agreement shall apply to the processor and what is stated regarding the Processor shall apply to the sub-processor.`,
  "terms.instructionsFrom": `Instructions from, and control by, the Controller`,
  "terms.theProcessorAndAnyPerson": `The Processor and any person within its organization may only process Personal Data in accordance with the TOS or such instructions that otherwise are given by the Controller. If the Processor deems the instructions insufficient for the fulfilment of this Agreement, the Processor shall, without delay, inform the Controller thereof and of that the fulfilment of the TOS or this Agreement may be affected by the lack of instructions and await further instructions from the Controller.`,
  "terms.theControllerShallHaveTheRight": `The Controller shall have the right to, at its own cost, verify that the Processor complies with this Agreement. The Processor shall be obligated to give such assistance necessary to perform such verification. If the Controller should find breaches or flaws of importance to the Controller, the Controller shall have the right to terminate this Agreement and TOS effective immediately.`,
  "terms.newFeaturesAffecting": `New features affecting the TOS`,
  "terms.ifNewFeatures": `If new features of the service result in altered data handling or the introduction of new data types, it must be published to the service website. The Controller can oppose the changes within two (2) weeks from the date of publication and, if reasonable reasons are stated, have the right to terminate the TOS. Such termination shall not be regarded as a breach of contract and no liability shall be incurred on either side due to the termination.`,
  "terms.prohibitionAgainstTransfer": `Prohibition against transfer to third country`,
  "terms.theProcessorShallProcessPersonalData": `The Processor shall process Personal Data only within, and on devices physically located within, the EU/EEA or such third country deemed to offer an adequate level of security by the European Commission.`,
  "terms.requestsFromAndContacts": `Requests from and contacts with authorities and data subjects`,
  "terms.inCaseADataSubject": `In case a data subject, the Swedish Data Protection Authority (Sw. Datainspektionen/Integritetsskydds-myndigheten) or any third party requests information regarding the processing of Personal Data from the Processor, the Processor shall refer the request to the Controller. The Processor shall not be entitled to disclose any Personal Data or information regarding the processing of Personal Data unless it is required by law or explicitly instructed by the Controller.`,
  "terms.theProcessorShallWithoutDela": `The Processor shall, without delay, inform the Controller about any requests or other contacts with the Swedish Data Protection Authority or any other authority that concerns or may affect the processing of Personal Data. The Processor has no right to represent or act on behalf of the Controller in relation to the data subject, the Swedish Data Protection Authority, any other authority or any third party.`,
  "terms.theProcessorShallAgainstReasonable": `The Processor shall, against reasonable compensation, assist the Controller in presenting such information that has been requested by the Swedish Data Protection Authority, another authority or the data subject.`,
  "terms.theProcessorShallTakeAppropriate": `The Processor shall take appropriate technical and organizational measures to protect the Personal Data from unauthorized access, destruction, loss or alteration. The measures shall be appropriate with respect to (a) available technology, (b) costs, (c) specific risks associated with the processing, and (d) the sensitivity of the Personal Data.`,
  "terms.theProcessorShallForThisPurpose": `The Processor shall for this purpose comply with the Swedish Data Protection Authority’s instructions, in particular the general advice on Security of Personal Data (Sw. Datainspektionens allmänna råd om säkerhet för personuppgifter).`,
  "terms.theProcessorShallTakeAppropriate ": `The Processor shall take appropriate technical and practical measures to enable investigations of possible and suspected security breaches regarding personal data, such as unauthorized access, destruction, loss or alteration.`,
  "terms.theProcessorWarrantsThatAll": `The Processor warrants that all who have access to Personal Data are bound by confidentiality. For the avoidance of any doubt, such confidentiality shall apply also in contacts with authorities and data subjects.`,
  "terms.theProcessorShallHaveTheRightToUseSubcontractors": `The Processor shall have the right to use subcontractors for the processing of Personal Data (“Sub-processors”), provided that the Sub-processors are bound by at least the same commitments and obligations toward the Controller as the Processor, in accordance with this Agreement. The Processor is fully liable toward the Controller for the Sub-processors’ actions and any default to adhere to the obligations by the Sub-processor.`,
  "terms.erasureAndReturning": `Erasure and returning of personal data`,
  "terms.theProcessorAndAnyOfItsSub": `The Processor and any of its Sub-processors shall, following the Controller’s decision on erasure of Personal Data, either completely erase such Personal Data from any medium where it is stored, in a way that the Personal Data cannot be restored, or ensure that it is anonymized in such way that it is not possible to connect to an individual or possible to recreate. The erasure or anonymization shall be completed within ninety (90) days following the Controller’s decision on erasure of Personal Data.`,
  "terms.theControllerAndProcessorAgree": `The Controller and Processor agree that the Processor and any Sub-processors shall, following the termination of processing, either return all transferred Personal Data, including copies, to the Controller, or to erase them in accordance with the above paragraph.`,
  "terms.uponRequest": `Upon request, the Processor shall attest in writing (e-mail or a written message in the service) that such return and/or erasure or anonymization has been completed or, if applicable, the time-frame within which such action will be performed.`,
  "terms.theControllerIsResponsible": `The Controller is responsible that the processing is in accordance with the legislation in force at any time.`,
  "terms.theProcessorShallBeLiable": `The Processor shall be liable for any damages caused to the Controller following the Processor’s processing of Personal Data in violation with the Controller’s instructions, this Agreement or the TOS. The Processor’s liability shall however be subject to the same restrictions as are stated in the TOS.`,
  "terms.termAndTermination": `Term and termination`,
  "terms.thisAgreementShallRemain": `This Agreement shall remain in force during the time the Processor is processing Personal Data for the Controller.`,
  "terms.thePartiesAgree": `The parties agree that, in the event that the Legislation or applicable governmental instructions change significantly, the terms of this Agreement shall be adjusted to the extent that they correspond to the principles that the parties originally intended when entering into this Agreement.`,
  "terms.disputeAndApplicable": `Dispute and applicable law`,
  "terms.theAgreementShallBeConstrued": `The Agreement shall be construed in accordance with, and governed by, Swedish law.`,
  "terms.anyDisputeArising": `Any dispute arising from or relating to this Agreement shall be settled by the public courts of Sweden, Stockholm district court being the first instance.`,
  "terms.contentTitle": `Content`,
  "terms.durationAndTermination": `Duration and termination`,
  "terms.useOfData": `Use of data`,
  "terms.personalData": `Personal data`,
  "terms.generalTitle": `General`,
  "terms.subProcessors": `Sub-processors`,
  "terms.liability": `Liability`,
  "terms.byAcceptingTheAgreement": `By accepting the Agreement,`,
  "terms.theDataProcessingAgreement": `the Data Processing Agreement`,
  "terms.alsoBecomesBinding": `also becomes binding between the parties.`,
  "terms.furtherInformation": `Further information about Get a Newsletter’s processing of personal data can be found in`,
  "terms.thePrivacyPolicy": `the Privacy Policy.`,

  //Privacy page
  "privacy.privacyPolicy": `Privacy policy`,
  "privacy.thisPrivacyPolicy": `This privacy policy (“Privacy Policy”) explains how we at Get a Newsletter Scandinavia AB - Swedish registration number 556831-5765, a company incorporated under the laws of Sweden - collect, use and protect your information.`,
  "privacy.theFollowingWords": `The following words shall, in this Privacy Policy, have the meaning assigned below:`,
  "privacy.refersToTheCompany": `“Get a Newsletter”, “our”, “us” or “we” refers to the company Get a Newsletter Scandinavia AB;`,
  "privacy.refersToAnyOfTheServices": `the “Service” refers to any of the services that Get a Newsletter provide, including, but not limited to, our online tool for sending newsletters;`,
  "privacy.refersToTheDomain": `the “Site” refers to the domain or sub-domains of getanewsletter.com and all other domains owned by Get a Newsletter;`,
  "privacy.refersToYou": `the “User” refers to you as a user of the Service;`,
  "privacy.refersToTheRecipients": `the “Subscriber” refers to the recipients of the newsletters from the User;`,
  "privacy.refersToTheList": `“Subscriber List” refers to the list of Subscribers and all associated information related to them, including but not limited to email addresses;`,
  "privacy.refersToYouAsEither": `“You” refers to you as either a User, a visitor of our Site or any other person communicating with us.`,
  "privacy.thePurpose": `The purpose of this privacy policy`,
  "privacy.thisPrivacyPolicyIsDesigned": `This Privacy Policy is designed to help you understand how Get a Newsletter collect and use your personal data. By using or accessing the Site or the Service you agree to the personal data being handled as described in this Privacy Policy.`,
  "privacy.informationToTheSubscribers": `Information to the subscribers (recipients of newsletters)`,
  "privacy.allNewsletters": `All newsletters sent through Get a Newsletter are sent by our Users. If you are a Subscriber and no longer want to be contacted by one of our Users, please unsubscribe directly from that User’s newsletter or contact the User directly to update or delete your data.`,
  "privacy.personalData": `Personal data`,
  "privacy.withPersonalData": `With “Personal Data”, we mean all information through which you directly or indirectly can be identified. For instance: your name, your address, your personal registration number, your IP address, your login information, your email address and any other data through which you can be identified.`,
  "privacy.dataCollectedFor": `Data collected for and by the User`,
  "privacy.whenYouAsAUser": `When you as a User use our Service, you may import personal information that you have collected from your Subscribers or other individuals into our system. Please be aware of that we do not have any direct relationship with your Subscribers or any other person than you. You, as the User is legally responsible for the collection and processing of Subscribers’ Personal Data.`,
  "privacy.theUserIsTherefore": `The User is therefore the controller (“Controller”) of the Personal Data attributable, in accordance with Swedish and EU legislation, whereas we are the Personal Data processor (“Processor”). This means that the User decides the purpose and manner of the processing and is responsible for its own and our processing of Personal Data.`,
  "privacy.whenYouAdd": `When you add information to your user account or create a newsletter with the Service, we have access to and may access such information and data for the purposes of providing the Service and as otherwise instructed.`,
  "privacy.getANewsletterAcknowledges": `Get a Newsletter acknowledges and agrees that the Subscriber Lists are owned by the User. We will not sell or claim ownership to your Subscriber Lists.`,
  "privacy.getANewsletterUndertakes": `Get a Newsletter undertakes to take appropriate measures to ensure that the Subscriber Lists are securely stored.`,
  "privacy.dataCollectedBy": `Data collected by and for Get a Newsletter`,
  "privacy.getANewsletterCollect": `Get a Newsletter collect information that web browsers and servers typically make available, such as your browser type, language preference, IP, referring site, and the date and time of each visitor request. We collect such statistics for the Service and the Site to better understand how our visitors use the Service and the Site and for marketing and advertising purposes.`,
  "privacy.weReceiveInformation": `We receive information that you voluntarily provide us with when you sign up for and use the Service, send us emails or connect with us in any way. That information may include Personal Data such as name, email address, phone number, credit card information, company information, physical address and other information connected to the user. By giving us this information, you are considered to be informed about which Personal Data we are processing about you. We are processing such data for the purpose of providing the Service (purpose of performance of a contract) and for keeping you informed about updates in the Service, for conventional CRM-use, including marketing, as well as for statistical purposes and to improve the Service (legitimate interest). If you inform us that you do not want your Personal Data to be used in a specific manner, please let us know and we will try and accommodate your request without obstructing your access to the Service. Please note that some processing, such as invoicing, need to be processed by legal requirement.`,
  "privacy.getANewsletterPersonal": `We, Get a Newsletter, are the Personal Data Controller for the processing under this clause (Data collected by and for Get a Newsletter).`,
  "privacy.weAlsoCollectYouIPNumber": `We also collect your IP-number and use cookies (see section 10 below) in order to prevent misuse of the Service or the Site, to understand usage of the Service and the Site better and to be able to offer the best possible user experience.`,
  "privacy.rightToInformation": `Right to information and correction et cetera`,
  "privacy.youMayRequest": `You may request information about what Personal Data Get a Newsletter controls about you. To make such a request, please send an e-mail to the address supplied below (paragraph Contact). Note that Get a Newsletter may charge a reasonable fee, covering administrative costs, for complying with such requests if the requests are considered unreasonable.`,
  "privacy.youHaveTheRight": `You have the right to demand that any erroneous Personal Data is corrected.`,
  "privacy.underCertainCircumstances": `Under certain circumstances, you may have a right of data portability.`,
  "privacy.ifYouAreUncertain": `If you are uncertain about how we handle your Personal Data, if you don’t agree with how we handle your Personal Data or if you otherwise have questions or complaints, please send us an e-mail to the address supplied below (paragraph Contact). You also have the right to file a complaint with the relevant authority (for contact details, see www.integritetsskyddsmyndigheten.se).`,
  "privacy.thirdParties": `Third parties`,
  "privacy.getANewsletter": `Get a Newsletter will not sell or otherwise share any of your Personal Data to any third party or use your email address for unsolicited emails.`,
  "privacy.howeverGetANewsletter": `However, Get a Newsletter may share your Personal Data with our subcontractors to facilitate, provide and perform the Service or to analyze how the Service is used. In such case, we remain responsible for our subcontractors’ processing of your Personal Data. Our subcontractors have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use your Personal Data for any other purpose.`,
  "privacy.weWillTakeAllSteps": `We will take all steps reasonably necessary to ensure that your Personal Data is treated securely and in accordance with this Privacy Policy. All Personal Data provided to us is stored on secure servers. Unfortunately, the transmission of any information via the internet is not entirely secure. Although we will do our best to protect your Personal Data, we cannot guarantee the security of the Personal Data transmitted to our Site. Any transmission is at your own risk. However, once we have received your Personal Data, we will use strict procedures and security features to try to prevent unauthorized access.`,
  "privacy.inTheEvent": `In the event of a security breach, that affects either you or your Subscribers, Get a Newsletter will, as soon as possible, notify you about the security breach and the measures taken by us.`,
  "privacy.cookies": `Cookies`,
  "privacy.getANewsletterUsesCookies": `Get a Newsletter uses Cookies on the Site in order to improve our Service and create statistics of your use of the Site. Cookies are files that are saved by your web browser on your device (e.g. computer or tablet). These files consist of text and are named after the websites you have visited. You can choose to not receive Cookies in your web browser. However, if you choose to not receive cookies, the feasibility and the features of the Site might be affected negatively.`,
  "privacy.changes": `Changes`,
  "privacy.weMayChange": `We may change this Privacy Policy at any time and will communicate such updates, changes or amendments by any means, including but not limited to, by posting the latest version of this Privacy Policy on the Site. All changes are effective immediately upon communication.`,
  "privacy.contact": `Contact`,
  "privacy.ifYouHaveAnyQuestions": `If you have any questions about our Privacy Policy, how we handle Personal Data or if you want to update, delete or change any personal information we hold, please send a message via our`,
  "privacy.contactForm": `contact form`,

  //Pause Account
  "pause.pausePageTitle" : `Pause account`,
  "pause.activatePageTitle" : `Activate account`,
  "pause.areYouSureYouWant" : `Are you sure you want to pause your account?`,
  "pause.ifYouConfirm" : `If you confirm, your account will be paused at the end of your current period({end_time}) and we will not charge you until you choose to activate your account again.
                You can still access your account during the paused period but you can not send any further newsletters.
                You can choose to activate your account again at anytime.`,
  "pause.iConfirm" : ` I confirm that I want to pause my account`,
  "pause.pauseMyAccount" : ` Pause my account`,
  "pause.willBePaused" : `Your account will be paused: `,
  "pause.willBePausedDescription" : `When your current period ends on {date} your account will be paused and you will not be able to send any further
  newsletter. You can still access your account during the paused period and you can choose to activate it at anytime.`,
  "pause.reactivateFailed": "You have tried to activate your account but your payment failed. Check your payment information and try again.",

   //Activate Account
   "activate.pageTitle" : `Activate account`,
   "activate.description" : `Your account is currently paused and you need to activate it in order to send newsletter again. Activate your account by paying for the next subscriptions period below.`,
   "activate.changeMind" : `Did you change your mind? No problem – just click Activate my account to cancel your decision to pause.`,
   "activate.activateMyAccount" : `Activate my account`,

  //404 Page is not found!
  "page404.heading": `404 Page not found!`,

  //Mails Pages
  "mails.sendOutATestMail": `Send a test mail`,
  "mails.yourTestMail": `Your test mail is on the way`,
  "mails.weHaveNowSent": `Your test mail is not sent to the subscribers in the list you specified.`,
  "mails.CheckOutYourInbox": `Check out your inbox and see so the mail looks as expected before sending it out for real.`,
  "mails.editMail": `Edit mail`,
  "mails.sendMail": 'How would you like to send out your newsletter?',
  "mails.sendMailBtn": 'Send draft',
  "mails.ifYourTestMail": `If your test mail doesnt show up, dont freak out! First, check your spam folder.`,
  "mails.wait30Minutes": `Second, wait 30 minutes due to possible traffic stocking. Third,contact us at Get a Newsletter support and we will check into it.`,
  "mails.youAreOnlyAStepTestMail": `You're only a step away from sending a test mail. Remember that there's a limit of`,
  "mails.chooseWhichList": `Choose the list you want to connect this autoresponder to. Only subscribers that signs up after the autoresponder is started will receive this mail.`,
  "mails.chooseWhichLists": `Choose which list(s) you want to send out your newsletter to. You can send to several lists at once. Duplicates will only receive the newsletter once.`,
  "mails.chooseNewSubscribersWhichList": `Since you are sending to multiple lists, you must select which list new subscribers will be subscribed to. New subscribers can join your lists if you, or a current subscriber, shares your newsletter.`,
  "mails.chooseSubject": `Choose subject and sender`,
  "mails.label.chooseSubject": `Choose subject`,
  "mails.label.chooseSender": `Choose sender`,
  "mails.reviewBeforeSending": `Review before sending`,
  "mails.takeASecondAndReview": `Take a second and review you choices below before sending. Take a quick look at the newsletter and make sure you have chosen the right list.`,
  "mails.receiver": `20 receiver`,
  "mails.forTestMails": `for test mails.`,
  "mails.ifYouWouldLikeToSend": `If you would like to send a regular email instead,`,
  "mails.clickHere": `click here.`,
  "mails.theMailYouHave": `You can send it right now, schedule a time in the future or choose to start an autoresponder.`,
  "mails.getYourMail": `Get your mail tested by sending it to yourself and a couple of friends.`,
  "mails.sendOutANewsletter": `Send now`,
  "mails.sendTheMail": `Send the mail immediately to multiple lists.`,
  "mails.scheduleANewsletter": `Send later`,
  "mails.scheduleTheMail": `Send the mail at a specific time to multiple lists.`,
  "mails.startAutoresponder": `Start autoresponder`,
  "mails.sendOutTheMail": `Send out the mail based on when the subscriber signs up.`,
  "mails.yourMailIsScheduled": `Your mail is scheduled for sending`,
  "mails.youAreOnlyAStepScheduling": `You're only a step away from scheduling a mail. If you would like to send a test mail instead,click here.`,
  "mails.yourNewsletterIsNowMover": `Your newsletter is now moved to scheduled mails and your newsletter will be sent out on the following date and time:`,
  "mails.toScheduledMails": `To scheduled mails`,
  "mails.rescheduleNow": `Reschedule now`,
  "mails.youAreOnlyAStepMail": `You're only a step away from scheduling a mail. If you would like to send a test mail instead,click here.`,
  "mails.chooseSendDayAndTime": `Choose send day & time`,
  "mails.chooseSendDay": `Days`,
  "mails.chooseSendHour": `Hours`,
  "mails.yourAutoresponderHasNowStarted": `Your autoresponder has now started`,
  "mails.yourAutoresponderHasNowStartedDescription": `Your autoresponder is now activated and the mail will go out to new subscribers.`,
  "mails.chooseLists": `Choose list`,
  "mails.chooseNewSubscribersList": `Manage new subscribers`,
  "mails.noteThatOnlyContacts": `Note that only contacts that start their subscription from now on will receive this mail.`,
  "mails.afterTheSubscription": `after the subscription is confirmed.`,
  "mails.sendingToList": `Sending to list`,
  "mails.createMail": `Create mail`,
  "mails.createTemplate": `Create template`,
  "mails.publicTemplates": `Designed templates`,
  "mails.ownTemplates": `Your own templates`,
  "mails.designedTemplates": 'Design templates',
  "mails.standardTemplates": 'Standard templates',
  "mails.advancedTemplates": `Advanced templates`,
  "mails.advancedEditor": `Advanced editor`,
  "mails.addGoogle": `Add Google analytics tracking`,
  "mails.trackMyNewsletter": `Yes, track my newsletter with Google analytics`,
  "mails.googleAnalyticsCanHelp": `Google Analytics can help track the newsletter by providing information about how many readers access your site.`,
  "mails.googleAnalytics": `Google analytics`,
  "mails.sendWhen": `Choose when to send your newsletter`,
  "mails.sendWhenDate": `Time and date`,
  "mails.yesTheNewsletterIsTracked": `Yes, the newsletter is tracked with Google analytics`,
  "mails.NoTheNewsletterIsNotTracked": `No, the newsletter isn't tracked with Google analytics`,
  "mails.doYouWantToScheduleYourMail": `Right now. Do you want to schedule your mail instead?`,
  "mails.doYouWantToSendNowInstead": `Do you want to send your mail right now instead?`,
  "mails.youAreOnlyAStep": `You're only a step away from sending a regular mail. If you would like to send a test mail instead,`,
  "mails.yourNewsletter": `Your newsletter is on its way`,
  "mails.scheduleBtn": `Schedule newsletter`,
  "mails.shareOnSocialMedia": `Share newsletter`,
  "mails.linkToShareOnline": `Link to share online`,
  "mails.copyToClipboard": `Copy to clipboard`,
  "mails.openInNewTab": `Link to online version`,
  "mails.shareOnFacebook": `Share on Facebook`,
  "mails.shareOnTwitter": `Share on Twitter`,
  "mails.shareOnLinkedIn": `Share on LinkedIn`,
  "mails.weHaveMovedYourMail": `We have moved your mail to your sent mails and created a report where you can keep track of the stats.`,
  "mails.stoppedMails": `Stopped mails`,
  "mails.pendingMails": `Pending mails`,
  "mails.sentMails": `Sent mails`,
  "mails.autoresponders": `Autoresponders`,
  "mails.overview": `Overview`,
  "mails.scheduledMails": `Scheduled mails`,
  "mails.templates": `Templates`,
  "mails.drafts.placeholder.title": 'Almost ready for take off - let´s do this!',
  "mails.drafts.placeholder.description": 'Your draft is a mail in the making, and so you are just one step away from sending your email.',
  "mails.drafts.title": `Drafts`,
  "mails.drafts.add": `Create draft`,
  "mails.drafts.edit": `Edit draft`,
  "mails.drafts.preview": `Preview draft`,
  "mails.drafts.copy": `Copy draft`,
  "mails.drafts.going": `You are going to delete the following draft: {draft}`,
  "mails.drafts.delete": `Delete draft`,
  "mails.drafts.prepare": `Prepare to send`,
  "mails.drafts.pendingReview.back": 'Back to overview',
  "mails.drafts.pendingReview.title": 'Mails pending review',
  "mails.drafts.pendingReview.description": 'The first time you send a newsletter we perform an audit to ensure that the content complies with our terms of use. Once the review is complete, we will send the email to your subscribers.',
  //Page Logged Out
  "loggedOut.loggedOut": `Logged out`,
  "loggedOut.thanksForSpendingQualityTime": `Thanks for spending quality time with Get a Newsletter today.`,
  "loggedOut.latestFromOurBlog": `Latest from our blog`,
  "loggedOut.needHelp": `Need help?`,
  "loggedOut.weRegularlyUpdateOurKnowledge": `We regularly update our knowledge base with relevant information. Search through our articles and videos to find your answer.`,
  "loggedOut.yourVanityUrl": `Your vanity URL`,
  "loggedOut.orByBrowsing": `Or by browsing our support pages`,
  "loggedOut.recommendUs": `Recommend us`,
  "loggedOut.weReallyHope": `We really hope you're satisfied with our tool and the service we provide. If so, please share it with your friends.`,
  "loggedOut.recommendUsOnFacebook": `Recommend us on Facebook`,
  "loggedOut.recommendUsOnTwitter": `Recommend us on Twitter`,
  "loggedOut.recommendUsOnGoogle": `Recommend us on Google plus`,
  "loggedOut.secondThoughts": `Second thoughts?`,
  "loggedOut.loginAgain": `Login again`,

  // Feedback
  "feedback.modal.header": "Your feedback means the world to us",
  "feedback.modal.text": "Any suggestions on how to make the tool even better? Fill in the form below and we´ll get right back to you. ",

  //Template
  "template.editAdvancedTemplate": 'Edit advanced template',
  "template.createAdvancedTemplate": 'Create advanced template',

  //Subscriptions
  "subscription.delete.areYouSure": `{count, plural,
    one {Are you sure you want to delete one subscription?}
    other {Are you sure you want to delete {count} subscriptions?}
  }`,
  "subscription.cancel.areYouSure": `{count, plural,
    one {Are you sure you want to unsubscribe one subscription?}
    other {Are you sure you want to unsubscribe {count} subscriptions?}
  }`,
  "subscription.move.areYouSure": `{count, plural,
    one {Are you sure you want to move one subscription?}
    other {Are you sure you want to move {count} subscriptions?}
  }`,
  "subscription.copy.areYouSure": `{count, plural,
    one {Are you sure you want to copy one subscription?}
    other {Are you sure you want to copy {count} subscriptions?}
  }`,
  'subscriptions.checkedBtn': `{count, plural,
    =0 {No checked subscriptions}
    one {One checked subscription}
    other {{count} checked subscriptions}
  }`,
  'subscriptions.modalHeader.deleteSubscriptions': `{count, plural,
    one {Delete subscription}
    other {Delete subscriptions}
  }`,
  'subscriptions.modalHeader.exportSubscriptions': `{count, plural,
    one {Export subscription}
    other {Export subscriptions}
  }`,
  'subscriptions.delete.areYouSure': `{count, plural,
    one {Are you sure to delete one subscription?}
    other {Are you sure to delete {count} subscriptions?}
  }`,
  'subscriptions.listEmpty': "List of subscriptions is empty",
  'subscriptions.cancelled': 'Cancelled the subscription {date}',
  'subscriptions.created': 'Created the subscription {date}',

  "contacts.subscription.title": "Bring your readers the content they really want",
  "contacts.subscription.description": "Your subscribers is the key to success. In order to add a subscription you need to import contacts. Let´s get you set up!",

  //Page titles
  "title.default": 'Get a Newsletter',
  "title.privacyPolicy": 'Privacy policy',
  "title.signup": 'Signup',
  "title.myAccount": 'My account',
  "title.forms": 'Forms',
  "title.attributes": 'Attributes',
  "title.contacts": 'Contacts',
  "title.autoresponders": 'Autoresponders',
  "title.termsOfUse": 'Terms of use',
  "title.reports": 'Reports',
  "title.upgradeAccount": 'Upgrade Account',
  "title.lists": 'Lists',
  "title.mails": 'Mails',
  "title.templates": 'Templates',
  "title.subscriptions": 'Subscriptions',
  "title.forgotPassword": 'Forgot Password',
  "title.login": 'Login',
  "title.dashboard": 'Dashboard',
  "title.createSubscriptionForm": 'Create subscription form',
  "title.popupForm": 'Popup form',
  "title.regularForm": 'Regular Form',
  "title.scheduledMails": 'Scheduled mails',
  "title.stoppedMails": 'Stopped Mails',
  "title.pendingMails": 'Pending Mails',
  "title.drafts": 'Drafts',
  "title.sentMails": 'Sent Mails',


  //Feedback
  "feedback.title": `FEEDBACK`,

  "header.privateBeta": `You are in the private beta of our new tool.`,
  "header.goBack": `Go back`,
  "header.toOld": `to the old version or`,
  "header.sendFeedback": `send feedback`,

  // 27.06.2019
  "reports.sendToSubscribers.text": `We are gathering your new subscribers. This can take some time, you can put this process in the background and we will send the mails when it's ready.`,
  "reports.sendToSubscribers.success": 'All done, sent to {count} new subscribers.',
  "reports.sendToSubscribers.error": 'Something went wrong :(',
  "reports.sendToSubscribers.btn": 'Send to new subscribers',

  // 01.07.2019
  "mails.pending.delete": `Delete mail`,

  // 04.07.2019
  "contacts.yourFilteredResult": "Your filtered result: Showing {firstNum} of {secNum} contacts.",
  "subscriptions.yourFilteredResult": "Your filtered result: Showing {firstNum} of {secNum} subscriptions.",
  "contacts.resetFilters": "Reset filter",
  "contacts.noResultsInFilter": "No results in filter",
  "contacts.noSearchInSearch": "No search results",

  //05.07.2019
  "accountStatus.nextPaymentDue": "Next payment due {date}",
  "accountStatus.theBalanceReset": "The balance resets on the {numOfDay}th each month",
  'subscriptions.numberOf': "Number of subscriptions:",

  //09.07.2019
  "importContacts.summary.errorsLink": 'Download errors file',
  "importContacts.summary.errorsCount": '{count} Errors',

  //10.07.2019
  "overviewChart.empty.noActivities": 'No activity yet',
  "overviewChart.empty.title": 'You have not sent any newsletter yet',
  "overviewChart.empty.text": `This section will display statistics from your latest sent newsletter.
  Send your first newsletter and start tracking your results today.`,
  "overviewChart.emptyLinks": 'There are no links in this newsletter',
  "overviewChart.emptyRecipients": 'Here you will see all of most active recipients.',

  //11.07.2019
  "common.rememberMe": 'Remember me',
  "recipients.cancelled.title": 'Great work!',
  "recipients.cancelled.description": 'No one has cancelled their subscription.',
  "recipients.opened.title": "No one has opened but don't worry.",
  "recipients.opened.description": "Soon you will see a list of all recipients that have read your newsletter.",
  "subscriptions.copy.agree": `{count, plural,
    one {Copy subscription}
    other {Copy subscriptions}
  }`,
  "subscriptions.move.agree": `{count, plural,
    one {Move subscription}
    other {Move subscriptions}
  }`,
  "contacts.modal.reactivate": `Reactivate contact`,
  "contacts.modal.reactivateModal": 'Are you sure you want to reactivate this contact?',
  "contacts.modal.reactivateBtn": 'Reactivate',

  // 15.07.2019
  "toasters.feedback.sent": 'Feedback sent successfully',
  "toasters.token.updated": 'Token updated successfully',
  "toasters.token.created": 'Token created successfully',
  "toasters.token.deleted": 'Token deleted successfully',
  "toasters.owner.updated": 'Account owner profile updated',
  "toasters.billingAddress.updated": 'Account billing address updated',
  "toasters.invoice.updated": 'Account invoice settings updated',
  "toasters.accountDetails.updated": 'Account details updated successfully',
  "toasters.sender.updated": 'Sender updated successfully',
  "toasters.sender.created": 'Sender created successfully',
  "toasters.sender.deleted": 'Sender deleted successfully',
  "toasters.profile.updated": 'Profile updated successfully',
  "toasters.partner.removed": 'Partner removed successfully',
  "toasters.resetLink.check": 'Check your email for password reset link.',
  "toasters.password.changed": 'Successfully changed your password.',
  "toasters.subscription.reactivated": 'Subscription reactivated',
  "toasters.subscription.cancelled": `{count, plural,
    one {One subscription unsubscribed}
    other {{count} subscriptions unsubscribed}
  }`,
  "toasters.subscription.deleted": `{count, plural,
    one {One subscription deleted}
    other {{count} subscriptions deleted}
  }`,
  "toasters.subscription.copied": `{count, plural,
    one {One subscription copied}
    other {{count} subscriptions copied}
  }`,
  "toasters.subscription.moved": `{count, plural,
    one {One subscription moved}
    other {{count} subscriptions moved}
  }`,
  "toasters.list.removed": 'List removed successfully',
  "toasters.contact.edited": 'Contact edited successfully',
  "toasters.contact.reactivated": 'Contact reactivated successfully',
  "toasters.form.deleted": 'Form deleted successfully',
  "toasters.attribute.deleted": 'Attribute deleted successfully',
  "toasters.attribute.created": 'Attribute created successfully',
  "toasters.attribute.updated": 'Attribute updated successfully',
  "toasters.contact.added": 'Contact added successfully',
  "toasters.subscription.added": `{count, plural,
    one {One subscription added}
    other {{count} subscriptions added}
  }`,
  "toasters.contact.updated": 'Contact updated successfully',
  "toasters.contact.created": 'Contact created successfully',
  "toasters.contact.moved": 'Contact moved successfully',
  "toasters.contact.copied": 'Contact copied successfully',
  "toasters.contact.cancelled": 'Contact cancelled successfully',
  "toasters.contact.deleted": `{count, plural,
    one {One contact deleted}
    other {{count} contacts deleted}
  }`,
  "toasters.mail.sent": 'Mail sent successfully',
  "toasters.email.confirmed": 'Email confirmed successfully',
  "toasters.form.updated": 'Form updated successfully',
  "toasters.mail.updated": 'Mail updated successfully',
  "toasters.mail.created": 'Mail created successfully',
  "toasters.template.updated": 'Template updated successfully',
  "toasters.template.created": 'Template created successfully',
  "toasters.autoresponder.paused": 'Autoresponder paused successfully',
  "toasters.autoresponder.resumed": 'Autoresponder resumed successfully',
  "toasters.autoresponder.copied": 'Autoresponder copied successfully',
  "toasters.autoresponder.deleted": 'Autoresponder deleted successfully',
  "toasters.draft.copied": 'Draft copied successfully',
  "toasters.draft.deleted": 'Draft deleted successfully',
  "toasters.template.copied": 'Template copied successfully',
  "toasters.template.deleted": 'Template deleted successfully',
  "toasters.pending.deleted": 'Pending mail deleted successfully',
  "toasters.scheduled.copied": 'Scheduled mail copied successfully',
  "toasters.scheduled.stopped": 'Scheduled mail stopped successfully',
  "toasters.sent.copied": 'Sent mail copied successfully',
  "toasters.sent.deleted": 'Sent mail deleted successfully',
  "toasters.sending.inprocess": 'Sending in process',
  "toasters.mail.setAsDraft": 'Mail set as draft successfully',
  "toasters.mail.deleted": 'Mail deleted successfully',
  "toasters.account.paused": 'Account will be paused soon',
  "toasters.account.activated": 'Account will be activated soon',
  "toasters.report.deleted": 'Report deleted successfully',
  "toasters.list.updated": 'List updated successfully',
  "toasters.list.created": 'List created successfully',
  "toasters.partner.updated": 'Partner updated successfully',
  "toasters.partner.created": 'Partner created successfully',

  // validation
  "validations.email": 'Email is not valid',
  "validations.url": 'Url is not valid',
  "validations.password.match": "The password and confirm password fields doesn't match",
  "validations.characters.max": "Must be {max} characters or less",
  "validations.characters.min": "Ensure this field has at least {min} characters",
  "validations.required": 'This field is required',
  "validations.html": 'Visual HTML or HTML Code is required',
  "validations.columns": "You can't connect a column to multiple fields",

  // errors
  "errors.payment": "Your payment could not be completed, please try another card",

  // 17.07.2019
  "dashboard.latestNewsletter": 'My latest newsletter {data}',
  "overview.opened": 'have opened',
  "overview.notOpened": 'have not opened',
  "overview.bounced": 'have bounced',
  "overview.cancelled": 'have cancelled',
  "overview.clicked": 'have clicked',
  "common.send": 'Send',
  "contacts.action.choose": 'Choose...',
  "contacts.action.export": 'Export',
  "contacts.action.delete": 'Delete',
  "contacts.action.addSubscription": 'Add subscriptions for...',
  "contacts.table.email": 'Email',
  "contacts.table.name": 'Name',
  "contacts.table.subscriptions": 'Subscriptions',
  "contacts.table.updated": 'Updated',
  "contacts.table.active": 'Active',
  "contacts.table.search": 'Search for name or email address...',
  "common.download.browse": 'Browse',
  "common.download.fileChoosen": 'File choosen',
  "common.select.dots": 'Select...',
  "common.select.noOptions": "No options",
  "common.select.create": 'Create "{inputValue}"',
  "subscriptions.table.contact": 'Contact',
  "subscriptions.table.list": 'List',
  "subscriptions.table.status": 'Status',
  "subscriptions.table.created": 'Created',
  "subscriptions.table.cancelled": 'Cancelled',
  "subscriptions.table.autoresponder": 'Autoresponder',
  "attributes.table.name": 'Attribute Name',
  "attributes.table.usage": 'Usage',
  "attributes.table.code": 'Code',
  "attributes.modal.delete": 'You are going to delete the following attribute: {value}',
  "forms.table.name": 'Name',
  "forms.table.list": 'List',
  "forms.table.forms": 'forms',
  "drafts.table.subject": 'Subject',
  "drafts.table.updated": 'Updated',
  "drafts.table.actions": 'Actions',
  "drafts.table.items": 'drafts',
  "autoresponder.table.subject": 'Subject',
  "autoresponder.table.status": 'Status',
  "autoresponder.table.sentAfter": 'Sent after',
  "autoresponder.table.list": 'List',
  "autoresponder.table.actions": 'Actions',
  "autoresponder.table.preview": 'Preview autoresponder',
  "autoresponder.table.new": 'New draft',
  "autoresponder.table.deleteModal": 'You are going to delete the following autoresponder: {name}',
  "autoresponder.table.deleteReport": 'Yes, I want to delete the matching report',
  "autoresponder.table.delete": 'Delete autoresponder',
  "autoresponder.table.pause": 'Pause autoresponder',
  "autoresponder.table.resume": 'Resume autoresponder',
  "autoresponder.table.edit": 'Edit autoresponder',
  "autoresponder.table.replan": 'Replan autoresponder',
  "autoresponder.table.pausedSince": 'Paused since {time}',
  "autoresponder.table.running": 'Running',
  "autoresponder.table.title": 'autoresponders',
  "scheduled.table.items": 'scheduled mails',
  "scheduled.table.subject": 'Subject',
  "scheduled.table.isSent": 'Is sent',
  "scheduled.table.list": 'List',
  "scheduled.table.actions": 'Actions',
  "scheduled.table.pause": 'Pause mail',
  "scheduled.table.replan": 'Replan mail',
  "scheduled.table.stopMessage": 'Are you sure you want to stop this email from beeing sent?',
  "sent.table.items": 'sent mails',
  "sent.table.subject": 'Subject',
  "sent.table.sent": 'Sent',
  "sent.table.list": 'List',
  "sent.table.actions": 'Actions',
  "mail.delete.areYouSure": 'Are you sure you want to delete the mail: {subject}?',
  "stopped.table.items": 'stopped mails',
  "stopped.table.subject": 'Subject',
  "stopped.table.status": 'Status',
  "stopped.table.list": 'List',
  "stopped.table.actions": 'Actions',
  "stopped.table.setAsDraft": 'Set as draft',
  "stopped.table.sendNow": 'Send now',
  "stopped.table.notEnough": 'Stopped, not enough credits -',
  "pending.table.items": 'pending mails',
  "pending.table.subject": 'Subject',
  "pending.table.list": 'List',
  "pending.table.actions": 'Actions',
  "templates.table.items": 'templates',
  "templates.table.subject": 'Subject',
  "templates.table.updated": 'Updated',
  "templates.table.actions": 'Actions',
  "templates.table.edit": 'Edit template',
  "templates.table.preview": 'Preview template',
  "templates.table.copy": 'Copy template',
  "templates.table.deleteMessage": 'You are going to delete the following tempate: {name}',
  "templates.table.delete": 'Delete template',
  "templates.table.openAsNew": 'Open template as new draft',
  "reports.placeholder.title": `Stay on top of things!`,
  "reports.placeholder.description": `You currently don´t have any reports, but once you´ve sent a mail, you will have the opportunity to learn more about your subscribers through your reports.`,
  "reports.table.items": 'reports',
  "reports.table.report": 'Report',
  "reports.table.sent": 'Sent',
  "reports.table.lists": 'Lists',
  "reports.table.sentTo": 'Sent To',
  "reports.table.opened": 'Opened',
  "reports.table.actions": 'Actions',
  "reports.tab.newsletters": 'Newsletters',
  "reports.tab.autoresponders": 'Autoresponders',
  "invoices.table.items": 'invoices',
  "invoices.table.invoiceNo": 'Invoice no',
  "invoices.table.dueDate": 'Due date',
  "invoices.table.amount": 'Amount',
  "invoices.table.payment": 'Payment',
  "invoices.table.actions": 'Actions',
  "invoices.table.notes": 'Notes',
  "account.card.removed": 'If you click continue your current card will be removed.',
  "languages.swedish": 'Swedish',
  "languages.english": 'English',
  "senders.table.items": 'senders',
  "senders.table.name": 'Sender name',
  "senders.table.email": 'Sender email',
  "senders.table.created": 'Created',
  "senders.table.actions": 'Actions',
  "common.create": 'Create',
  "lists.table.items": 'lists',
  "forms.input.placeholder": 'My awesome form...',
  "forms.input.confirmationMail": 'Welcome as a subscriber to ##list_name##',
  "forms.input.message": `Hello!
  You have been added as a subscriber to ##list_name##.
  Before you can receive our newsletter, please confirm your subscription by clicking the following link:
  ##confirmation_link##`,
  "forms.input.subscribe": 'Subscribe',
  "templates.startFromScratch": 'Start from scratch',
  "upgrade.largestAccount.title": 'You already have the largest account',
  "upgrade.largestAccount.description": 'Please {link} for more information',
  "upgrade.largestAccount.getInTouch": 'get in touch',

  // 25.07.2019
  "draft.editAdvancedDraft": 'Edit advanced draft',
  "draft.createAdvancedDraft": 'Create advanced draft',

  // Actions tooltips
  "actions.list.edit": 'Edit list',
  "actions.list.remove": 'Delete list',
  "actions.attribute.edit": 'Edit attribute',
  "actions.attribute.remove": 'Delete attribute',
  "actions.form.edit": 'Edit form',
  "actions.form.remove": 'Delete form',
  "actions.partner.remove": 'Delete partner',
  "actions.sender.edit": 'Edit sender',
  "actions.sender.remove": 'Delete sender',
  "actions.token.edit": 'Edit token',
  "actions.token.remove": 'Delete token',

  // 11.11.2019
  "bounces.permanent": "permanent bounces",
  "bounces.temporary": "temporary bounces",

  // 12.11.2019
  "common.subscribers": "Subscribers",

  // 13.11.2019
  "common.titles.didnotopen": "Did not open",
  "common.table.column.contact": "Email",
  "common.table.column.opens": "Openings",
  "common.table.column.clicks": "Link Clicks",
  "common.table.column.error_type": "Type",
  "common.table.column.description": "Description",
  "common.table.column.active": "Active",

  "common.delete.areYouReallySure": "Yes, I am sure. I am also aware that this action is permanent and can not be undone.",

  // 21.11.2019
  "recaptcha.protected.text": "This site is protected by reCAPTCHA and the Google {privacy} and {terms} apply.",
  "recaptcha.privacy.text": "Privacy Policy",
  "recaptcha.terms.text": "Terms of Service",

  "common.welcomeBack": "Welcome back.",
  "common.loginToGetStarted": "Login to get started.",

  "common.authlayout.signin.img.alt": "Login to Get a Newsletter",
  "common.authlayout.signup.img.alt": "Upgrade account",
  "common.authlayout.forgot.img.alt": "Forgot password",

  "commoun.authlayout.bluelogo.alt": "Get a Newsletter blue logo",
  "advancededitor.saveAndPreview": "Save and preview",
  "advancededitor.visualHTML": "Visual HTML",
  "advancededitor.saveAndGo": "Save and go",
  "advancededitor.saveAndStay": "Save and stay",
  "advancededitor.HTMLCode": "HTML code",
  "advancededitor.editCss": "Edit CSS",
  "advancededitor.textMessage": "Text Message",
  "advancededitor.tab.html.text": "To add CSS to you mail, add it in the field below. You need to save the mail before the css give any affect. The CSS is added to the head in the mail and you don't need to specify the style-tag, just write the CSS directly.",
  "advancededitor.tab.css.text": "To add CSS to you mail, add it in the field below. You need to save the mail before the css give any affect. The CSS is added to the head in the mail and you don't need to specify the style-tag, just write the CSS directly.",
  "advancededitor.tab.plain.text": "As a complement to the newsletter with images, write a version in pure text for those who can't read html messages in their e-mail program. If the text message field is empty, the subscriber gets our standard message and a link to open the mail in their browser.",

  // 25.11.2019
  "extramail.description": "In order to start the sending you can either upgrade your account to the next level or {newline} buy {mailsToBuy} for a total of {priceCurrency}",
  "extramail.mailsToBuy": "{count} mails",
  "extramail.question": "How you would like to proceed?",
  "extramail.buyExtraMail": "Buy extra mails",
  "extramail.or": "or",
  "extramail.upgradeAccount": "Upgrade your account",
  "common.export.error": "The error occurs and report's data can't be exported.",

  // 28.11.2019
  'common.notifications': 'notifications',

  // 02.12.2019
  "contacts.subscription.nextAutoresponder": "Next mail",
  "extramail.title": "You don't have enough mails left on your account",

  // 04.12.2019
  "extracontacts.title": "You have too many contacts on your account.",
  "extracontacts.description": "In order to start the sending you need to upgrade your account",

  // 09.12.2019
  "paymentmodal.epay.security.message": "ePay / Payment Solutions is PCI certified by VISA / Mastercard and all communication is encrypted via a secure connection.",
  "apitoken.edit": "Edit API token",
  "pausedaccount.warning": "Your account is currently paused and you need to activate it in order to send mails again.",
  "pausedaccount.activate": "Activate your account",

  // 10.12.2019
  "common.saveAndContinue": "Save and continue",

  // 11.12.2019
  "form.error.invalidVat": "Invalid VAT ID",
  "form.error.invalidOrgNumber": "Invalid Org number",

  // 19.12.2019
  "common.select.field.dots": 'Select {field} column...',

  //16.01.2020
  "auth.form.title.common": 'Register account',
  "auth.form.title.forFree.common": ' ',

  "auth.form.description.starweb": 'As a Starweb customer you have 1000 free mail / month. No hidden fees, no notice period.',
  "auth.logo.title.starweb": "IN COOPERATION WITH STARWEB",

  "auth.form.description.wikinggruppen": 'As a customer of the Wikinggruppen, you have {mail} free mail / month. No hidden fees, no notice period.',
  "auth.logo.title.wikinggruppen": "IN COOPERATION WITH WIKINGGRUPPEN",

  "auth.form.description.ehandel": 'As a member of Ehandel.se you get free space for 2000 contacts. No hidden fees, no notice period.',
  "auth.logo.title.ehandel": "IN COOPERATION WITH EHANDEL.SE",

  "auth.form.description.payson": 'As a customer of Payson, you can send newsletters for free up to 2000 contacts for three months. No hidden fees, no notice period.',
  "auth.logo.title.payson": "IN COOPERATION WITH PAYSON",

  "auth.form.description.nyforetagar": 'As a customer of NyföretagarCentrum, you can send 2,500 emails / month to 500 contacts completely free of charge. No hidden fees, no notice period.',
  "auth.logo.title.nyforetagar": "IN COOPERATION WITH NYFÖRETAGARCENTRUM",

  "contacts.table.lists.search": 'Search for name...',
  'lists.numberOf': "Number of lists:",
  "lists.yourFilteredResult": "Your filtered result: Showing {firstNum} of {secNum} lists.",
  "banner.title": "Tools for newsletters",
  "common.form.name.placeholder": "My awesome form...",

  "offline.modal.title": "Connection Error",
  "offline.modal.description": "We can't seem to connect to our servers right now. Try reconnecting by clicking the button below.",
  "offline.modal.checkIfOnlineBtn": "Try reconnecting",
}
