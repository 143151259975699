import React from 'react'
import { FormattedMessage } from 'react-intl'
import { signupCodesHash } from 'common/utils/constants'

export const AUTH_LAYOUT_TYPES = {
  SIGNIN: 'signin',
  SIGNUP: 'signup',
  FORGOT: 'forgot'
}

export default function AuthLayout({ children, type, code }) {
  const signupCode = signupCodesHash[code]
  return (
    <div className="auth-container d-flex align-items-stretch">
      {children}
      <div className={`auth-container--info type-${type}`}>
        {
          type === AUTH_LAYOUT_TYPES.SIGNIN && (
            <FormattedMessage id="common.authlayout.signin.img.alt">
              {alt => (
                <img
                  alt={alt}
                  src="/img/auth-login.svg"
                />
              )}
            </FormattedMessage>
          )
        }
        {
          type === AUTH_LAYOUT_TYPES.SIGNUP && (signupCode && signupCode.isCustom ? (
            <div>
              <p className="small font-weight-bold text-center">{signupCode.logo.title}</p>
              <FormattedMessage id="common.authlayout.signup.img.alt">
                {alt => (
                  <img
                    alt={alt}
                    src={signupCode.logo.url}
                  />
                )}
              </FormattedMessage>
            </div>
          ) : (
              <FormattedMessage id="common.authlayout.signup.img.alt">
                {alt => (
                  <img
                    alt={alt}
                    src="/img/auth-signup.svg"
                  />
                )}
              </FormattedMessage>
            ))
        }
        {
          type === AUTH_LAYOUT_TYPES.FORGOT && (
            <FormattedMessage id="common.authlayout.forgot.img.alt">
              {alt => (
                <img
                  alt={alt}
                  src="/img/auth-forgot.svg"
                />
              )}
            </FormattedMessage>
          )
        }
      </div>
    </div>
  )
}
