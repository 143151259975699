import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'

import Spinner from '../Spinner'
import { offline } from 'store/actions/offline'

export const PageLoading = ({ error, timedOut, pastDelay, retry, isOffline, offline }) => {
  if (error) {
    if (isOffline === false) {
      offline()
    }
    // When the loader has errored
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card py-5">
              <div className="row">
                <div className="col-8 offset-2 text-center">
                  <img
                    alt=""
                    rel="preload"
                    className="w-25 mb-4"
                    src="/img/empty.svg"
                  />
                  <h3><FormattedMessage id="pageLoading.error" /></h3>
                  <p>
                    <FormattedMessage id="pageLoading.reload" />
                  </p>
                  <button type="button" className="btn btn-success" onClick={retry}>
                    <FormattedMessage id="common.retry" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else if (timedOut) {
    // When the loader has taken longer than the timeout
    if (isOffline === false) {
      offline()
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card py-5">
              <div className="row">
                <div className="col-8 offset-2 text-center">
                  <img
                    alt=""
                    rel="preload"
                    className="w-25 mb-4"
                    src="/img/empty.svg"
                  />
                  <h3><FormattedMessage id="pageLoading.timeOut" /></h3>
                  <p>
                    <FormattedMessage id="pageLoading.reload" />
                  </p>
                  <button type="button" className="btn btn-success" onClick={retry}>
                    <FormattedMessage id="common.retry" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else if (pastDelay) {
    return <Spinner show={true} />// When the loader has taken longer than the delay
  } else {
    return null
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  offline: () => dispatch(offline()),
})

export default compose(
  connect(
    (state) => ({
      isOffline: get(state, 'offline.isOffline'),
    }),
    mapDispatchToProps
  )
)(PageLoading)
