import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import Cookies from 'js-cookie'
import en from "react-intl/locale-data/en";
import sv from "react-intl/locale-data/sv";

import allMessages from 'i18n'
import { getDomain, getUserLanguage } from "common/utils/helpers";

addLocaleData([...en, ...sv]);

export const IntlContext = React.createContext();

export class IntlProviderWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.switchToEnglish = () => {
      const locale = 'en'
      Cookies.set('gan-locale', locale, { domain: getDomain(null) })
      this.setState({ locale, messages: allMessages[locale] })
    }

    this.switchToSwedish = () => {
      const locale = 'sv'
      Cookies.set('gan-locale', locale, { domain: getDomain(null) })
      this.setState({ locale, messages: allMessages[locale] })
    }

    const locale = props.locale || Cookies.get('gan-locale') || getUserLanguage()
    this.state = {
      locale,
      messages: allMessages[locale],
      switchTo: {
        en: this.switchToEnglish,
        sv: this.switchToSwedish
      }
    };
  }

  componentDidUpdate(props, state) {
    if (this.props.locale && props.locale !== this.props.locale && this.state.locale !== this.props.locale) {
      this.setState({
        locale: this.props.locale,
        messages: allMessages[this.props.locale]
      })
    }
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state
    return (
      <IntlContext.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </IntlContext.Provider>
    );
  }
}

export const useIntlContext = () => React.useContext(IntlContext);

export default IntlContext;
