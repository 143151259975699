import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { required } from 'validations'
import { TextField, CheckboxField } from 'common/forms'
import ButtonSpinner from 'components/ButtonSpinner'
import { ChangeLanguage } from './ChangeLanguage'


export default function Signin({ handleSubmit, onSubmit, session }) {
  return (
    <div className="auth-container--form">
      <FormattedMessage id="commoun.authlayout.bluelogo.alt">
        {alt => (
          <img
            alt={alt}
            width="200px"
            src="/img/logo-dark.svg"
          />
        )}
      </FormattedMessage>
      <form
        className="w-100 flex-grow-1 d-flex flex-column justify-content-center mt-2"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <h1 className="mb-5 mt-4">
          <div className="text-success"><FormattedMessage id="common.welcomeBack" /></div>
          <FormattedMessage id="common.loginToGetStarted" />
        </h1>
        <div className="form-group">
          <TextField
            name="username"
            id="email"
            label={<FormattedMessage id="common.inputs.emailAddress" />}
            validate={[ required ]}
          />
        </div>
        <div className="form-group mb-4">
          <TextField
            type="password"
            name="password"
            id="password"
            label={<FormattedMessage id="common.inputs.password" />}
            validate={[required]}
          />
        </div>
        <div className="row mb-4 align-items-center">
          <div className="col-6">
            <CheckboxField name="remember_me" boxLabel={<FormattedMessage id="common.rememberMe" />} />
          </div>
          <div className="col-6 text-right">
            <Link to="/forgot" className="btn-link small font-weight-bold">
              <FormattedMessage id="auth.forgotPassword" />
            </Link>
          </div>
        </div>
        <div className="form-group mb-4">
          <ButtonSpinner
            spin={session.isLoading}
            className="btn btn-lg btn-block btn-success"
          >
            <FormattedMessage id="auth.login" />
          </ButtonSpinner>
        </div>
        <div className="dotted-border-box small font-weight-bold mt-2">
          <FormattedMessage id="auth.dontHaveAccount" />
          {' '}
          <Link className="btn-link font-weight-bold" to="/signup">
            <FormattedMessage id="auth.signup" />
          </Link>
        </div>
      </form>
      <div className="d-flex w-100 mt-4">
        <Link to="/privacy" className="small mr-3">
          <FormattedMessage id="footer.policy" />
        </Link>
        <Link to="/terms" className="small">
          <FormattedMessage id="footer.terms" />
        </Link>
        <ChangeLanguage />
      </div>
    </div>
  )
}
