import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faEnvelope,
  faUserFriends,
  faChartBar,
  faBars,
  faLifeRing,
  faCommentAlt,
  faRocket,
  faCog,
  faEye,
  faTimes,
  faTimesCircle,
  faSpinner,
  faCheck,
  faSignOutAlt,
  faUserCog,
  faUserCircle,
  faCheckCircle,
  faEdit,
  faEllipsisH,
  faInfoCircle,
  faCopy,
  faBell,
  faSearch,
  faClipboardList,
  faList,
  faVideo,
  faTrashAlt,
  faPaperPlane,
  faArrowCircleRight,
  faPause,
  faStop,
  faSignal,
  faPlay,
  faClock,
  faArrowCircleLeft,
  faCaretDown,
  faCaretUp,
  faExclamationTriangle,
  faAddressCard,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
export { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
  faHome,
  faPaperPlane,
  faEnvelope,
  faUserFriends,
  faChartBar,
  faBars,
  faLifeRing,
  faCommentAlt,
  faRocket,
  faCog,
  faEye,
  faTimes,
  faTimesCircle,
  faSpinner,
  faCheck,
  faSignOutAlt,
  faUserCog,
  faCheckCircle,
  faUserCircle,
  faEdit,
  faEllipsisH,
  faInfoCircle,
  faCopy,
  faBell,
  faSearch,
  faClipboardList,
  faList,
  faVideo,
  faTrashAlt,
  faArrowCircleRight,
  faPause,
  faStop,
  faSignal,
  faPlay,
  faClock,
  faCaretDown,
  faCaretUp,
  faExclamationTriangle,
  faArrowCircleLeft,
  faAddressCard,
  faPlus
);
