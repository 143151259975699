import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { calculatePriceData } from './helpers'

export const getDataLayer = () => {
  window.dataLayer = window.dataLayer || []
  return window.dataLayer
}

export const addEvent = (event) => {
  const dataLayer = getDataLayer()
  dataLayer.push(event)
}

const pageviewTrack = (store) => (location, action) => {
  const state = store.getState()

  const userId = get(state, 'resource.user.data.id')
  const isFree = get(state, 'resource.profile.data.subscription.is_free')
  const emailConfirmationNeeded = get(state, 'resource.profile.data.email_confirmation_needed')

  const event = {
    'event': 'pageviewCustomEvent',
    'pagePath': `${location.pathname}${location.search}${location.hash}`,
    'pageTitle': window.document.title,
    'userId': userId ? userId : undefined,
    'dimension1': userId ? '1' : undefined,
    'dimension2': emailConfirmationNeeded === true
      ? true
      : emailConfirmationNeeded === false
        ? false
        : undefined,
    'dimension3': isFree === true
      ? false
      : isFree === false
        ? true
        : undefined,
  }

  addEvent(event)
}

export const prepareTransactionEvent = (response, props) => {
  const { articleData, discount, profile, address, billing, checkvat } = props

  const {
    currency,
    articleVat,
    conversionValue
  } = calculatePriceData({
    article: get(articleData, 'data'),
    profile,
    address,
    billing,
    discount,
    checkvat
  })

  const event = {
    'event': 'transactionComplete',
    'transactionId': response.order_id,
    'transactionTotal': conversionValue,
    'transactionCurrency': currency,
    'transactionTax': articleVat,
    'transactionProducts': [
      {
        'sku': response.order_id,
        'name': get(articleData, 'data.name'),
        'price': conversionValue,
        'quantity': 1
      }
    ]
  }
  return event
}

export const prepareSignupEvent = (label) => {
  const event = {
    'event': 'customEvent',
    'eventCategory': 'accounts',
    'eventAction': 'signed up',
    'eventLabel': label,
    'eventValue': 1
  }
  return event
}

export const initPageviewTrack = (history, store, delay = 300) => {
  const pageviewTrackWithStore = debounce(pageviewTrack(store), delay)
  setTimeout(() => {
    const dataLayer = getDataLayer()
    if (!dataLayer.find(item => item.event === 'pageviewCustomEvent')) {
      pageviewTrackWithStore(window.location)
    }
  }, 1000)
  history.listen(pageviewTrackWithStore)
}
