import React, { memo } from 'react'
import FormValidationError from 'components/FormValidationError'

export const BaseFieldLayout = (props) => {
  const {
    icon,
    label,
    prefix,
    required,
    inputComponent: InputComponent,
    appendAddon
  } = props

  const Component = (
    <InputComponent
      {...props}
      {...{...props.input, required: false}}
    />
  )

  return (
    <>
      {icon}
      {label && (
        <label htmlFor={props.name}>
          {label}
          {required && <span className='control-asterisk'>*</span>}
        </label>
      )}
      <div className='control-field'>
        <div className='control-element'>
          {prefix && <div className='control-prefix'>{prefix}</div>}
          {
            appendAddon ? (
              <div className="input-group">
                {Component}
                {appendAddon}
              </div>
            ) : (
              Component
            )
          }

          <FormValidationError validationMessages={props.meta.touched && props.meta.error} className="visible" />
        </div>
      </div>
    </>
  )
}

export default memo(BaseFieldLayout)
