export const update = (user) => {
  if (window.Intercom && window.INTERCOME_APP_ID) {
    window.intercomSettings = {
      app_id: window.INTERCOME_APP_ID,
      email: user.email,
      user_id: user.id,
      created_at: Date.now() / 1000,
    }
    window.Intercom('update')
  }
}

export const reset = () => {
  if (window.Intercom && window.INTERCOME_APP_ID) {
    window.intercomSettings = {
      app_id: window.INTERCOME_APP_ID,
    }
    window.Intercom('shutdown')
    window.Intercom('boot', window.intercomSettings)
  }
}
