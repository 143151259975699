import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { injectIntl } from 'react-intl'

import { connectResource } from 'common/utils/resource'
import { SelectField } from 'common/forms/fields'
import { generateListOptions } from 'common/utils/helpers'
import { defaultPaginationFull } from 'common/utils/constants'

export class ListSelect extends Component {
  formatOptionLabel = (option, meta) => {
    const { intl: { formatMessage } } = this.props

    if (meta.context === 'value' || typeof option.count === 'undefined') {
      return option.label;
    }
    return (
      <>
        <div>
          {option.label}
        </div>
        <span className="d-block small text-muted">
          {formatMessage({ id: 'common.subscribers' })}: {option.count}
        </span>
      </>
    );
  }

  render() {
    return <SelectField
      {...this.props}
      classNamePrefix="list-select"
      options={this.props.options}
      formatOptionLabel={this.formatOptionLabel}
    />
  }
}

export default compose(
  injectIntl,
  connectResource({
    namespace: 'lists',
    endpoint: 'lists',
    refresh: true,
    async: true,
    list: true,
    filters: {
      paginate_by: defaultPaginationFull,
    },
  }),
  connect((state, props) => ({
    options: generateListOptions(get(state.resource, 'lists.data'), { active_subscribers_count: 'count' }),
  })),
)(ListSelect)
