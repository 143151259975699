import { reducer as offline } from './reducers/offline'

const reducers = {
  offline
}
const epics = {}

export {
  reducers,
  epics,
}
