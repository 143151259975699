export default {
  /*
   * Common words are used at more than one places.
   * Keep in mind when rename
   */
  'common.shown':'Visar',
  'common.shownOf':'av',
  'common.showAll':'Visa alla',
  'common.emailToLowerCase': 'e-postadress',
  'common.delete': 'Radera',
  'common.close': 'Stäng',
  'common.name': 'Namn',
  'common.updated': 'Uppdaterad',
  'common.active': 'Aktiv',
  'common.contacts': 'Kontakter',
  'common.subscriptions': 'Prenumerationer',
  'common.attributes': 'Attribut',
  'common.billingCompany': 'Faktureringsadress',
  'common.editYourInformation': 'Redigera din information',
  'common.lists': 'Listor',
  'common.mails': 'Utskick',
  'common.mailsLowerCase': 'Utskick',
  'common.yourInformation': 'Din information',
  'common.address': 'Adress',
  'common.company': 'Företag',
  'common.email': 'E-post',
  'common.reference': 'Referens',
  'common.sum': 'Summa',
  'common.select': 'Välj',
  'common.description': 'Beskrivning',
  'common.yourEmail': 'Din e-postadress',
  'common.period': 'Period',
  'common.drafts': 'Utkast',
  'common.price': 'Pris',
  'common.sek': ' SEK',
  'common.usd': ' USD',
  'common.card': 'kort',
  'common.sent': 'Skickat',
  'common.sender': 'Avsändare',
  'common.sendOut': 'Skicka mail',
  'common.sendOutTest': 'Skicka testmail',
  'common.sentAfter': 'Skickat efter',
  'common.report': 'Rapport',
  'common.list': 'Lista',
  'common.listsTitle': 'Listor',
  'common.sentTo': 'Skickat till',
  'common.opened': 'Öppnade',
  'common.actions': 'Verktyg',
  'common.invoice': 'faktura',
  'common.sekMonth': 'Kr/månad',
  "common.definitions": 'Definitioner',
  'common.vat': 'VAT {percent}',
  'common.upgradeNow': 'Uppgradera nu',
  'common.total': 'Total',
  'common.time': 'Tid',
  'common.reports': 'Rapporter',
  'common.links': 'Länkar',
  'common.paymentMethod': 'Betalsätt',
  'common.bounces': 'Studsar',
  'common.welcome': 'Välkommen',
  'common.templates': 'Mallar',
  'common.save': 'Spara',
  'common.saveAndReturn': 'Spara och återvänd',
  'common.manageAttributes': 'Hantera attribut',
  'common.showAttributes': 'Visa attribut',
  'common.addSubscriptions': 'Lägg till prenumeration',
  'common.goToContacts': 'Gå till dina kontakter',
  'common.createNewEmail': 'Skapa nytt nyhetsbrev',
  'common.add': 'Lägg till',
  'common.cancelChange': 'Ångra åtgärd',
  'common.cancelled': 'Avslutad!',
  'common.changePassword': 'Byt lösenord',
  'common.resetPassword': 'Återställ lösenord',
  'common.cancel': 'Avbryt',
  'common.submit': 'Spara',
  'common.cancelSubscription': 'Avprenumerera',
  'common.back': 'Tillbaka',
  'common.byPlacing': 'Genom att skapa ett konto godkänner jag Get a Newsletters',
  'common.myCardWillBeCharged': 'och att mitt kort kommer att debiteras varje månad tills jag väljer att säga upp min prenumeration',
  'common.termsOfUse': 'Användarvillkor',
  'common.areYouSure': 'Är du säker',
  'common.areYouSureList': 'Är du säker på att du vill radera den här prenumerationen?',
  'common.editList': 'Redigera lista',
  'common.backToLogin': 'Tillbaka till inloggning',
  'common.update': 'Uppdatera',
  'common.yes': 'Ja',
  'common.no': 'Nej',
  'common.searchNameEmail': 'Sök efter namn eller e-postadress...',
  'common.inputs.firstName': 'Förnamn',
  'common.inputs.lastName': 'Efternamn',
  'common.inputs.email': 'E-postadress',
  'common.inputs.emailAddress': 'E-postadress',
  'common.inputs.password': 'Lösenord',
  'common.inputs.passwordConfirm': 'Bekräfta lösenord',
  'common.inputs.newPassword': 'Nytt lösenord',
  'common.inputs.repeatPassword': 'Upprepa nytt lösenord',
  'common.inputs.country': 'Land',
  'common.inputs.company': 'Företag',
  'common.inputs.subscribers': 'Prenumeranter',
  'common.inputs.autoresponders': 'Automatiska utskick',
  'common.inputs.actions': 'Verktyg',
  'common.inputs.organizationNumber': 'Organisationsnummer',
  'common.inputs.address': 'Adress',
  'common.inputs.address2': 'Address 2',
  'common.inputs.zipCode': 'Postnummer',
  'common.inputs.vatId': 'Momsregistreringsnummer',
  'common.inputs.city': 'Stad',
  'common.inputs.extraEmail': 'Extra e-postadress',
  'common.inputs.phoneNumber': 'Telefonnummer',
  'common.inputs.reference': 'Referens',
  'common.inputs.passwordAgain': 'Upprepa lösenord',
  'common.inputs.chooseLists': 'Välj lista',
  'common.inputs.subscribersToLetter': 'Prenumerera på vårt nyhetsbrev',
  'common.inputs.copyLinkBelow': 'Kopiera länken enligt nedan',
  'common.inputs.senderName': 'Avsändarnamn',
  'common.inputs.senderEmail': 'Avsändarmail',
  'common.inputs.subject': 'Ämne',
  'common.inputs.message': 'Meddelande',
  'common.inputs.nextUrl': 'Nästa URL',
  'common.inputs.copyFormBelow': 'Kopiera formuläret enligt nedan',
  'common.inputs.buttonText': 'Knapptext',
  'common.titles.contact': 'Kontakt',
  'common.titles.contacts': 'Kontakter',
  'common.titles.list': 'Lista',
  'common.titles.lists': 'Listor',
  'common.titles.status': 'Status',
  'common.titles.attributes': 'Attribut',
  'common.titles.forms': 'Formulär',
  'common.titles.subscriptions': 'Prenumerationer',
  'common.titles.created': 'Skapad',
  'common.titles.cancelled': 'Avslutad',
  'common.titles.autoresponder': 'Automatiskt utskick',
  'common.chooseANewPassword': 'Välj ett nytt lösenord',
  'common.summary': 'Sammanställning',
  'common.start': 'Start',
  'common.download': 'Ladda ned',
  'common.retry': 'Försök igen',
  'common.search': 'Sök',
  'common.replan': 'Planera om',
  'common.support': 'Support',
  "common.security": 'Säkerhet',
  'common.preview': 'Förhandsgranska',
  'common.desktop': 'Standard',
  'common.mobile': 'Mobil',
  'common.agree': 'Godkänner',
  'common.schedule': 'Schemalägg',
  'common.continue': 'Fortsätt',
  'common.SaveAndContinue': 'Spara och fortsätt',
  'common.yourOrder': 'Din beställning',
  'common.choosePayment': 'Välj betalsätt',
  'common.subscription': 'Prenumeration',
  'common.improvements': 'Förbättringar',
  'common.ok': 'Ok',
  'common.navigation': 'Navigering',
  'common.accountManagement': 'Kontoägare',
  'common.quickAdd': 'Snabbval',
  'common.yearly': 'Betala per år',
  'common.monthly': 'Betala per månad',
  'common.year': 'år',
  'common.month': 'månad',
  'common.upgrade': 'Uppgradera',
  'common.mail': 'Utskick',
  'common.xlsChoose': 'Välj fil',
  'common.inputs.name': 'Namn',
  'common.inputs.description': 'Beskrivning',
  'common.inputs.partnerEmail': 'Ange e-postadress till personen som ska ha tillgång till ditt konto',
  'common.inputs.days': 'Dagar:',
  'common.inputs.hours': 'Timmar:',
  'common.inputs.timeAndDate': 'Tid och datum:',
  // /lists
  'lists.addListBtn': 'Skapa lista',
  'lists.activeSubscribers': `{count, plural,
    one {{count} aktiv prenumerant}
    other {{count} aktiva prenumeranter}
  }`,
  'lists.autoresponder': `{count, plural,
    =0 {Inga automatiska utskick körs för tillfället}
    one {{count} automatiskt utskick är igång}
    other {{count} automatiska utskick är igång}
  }`,

  // /attributes
  'attributes.addAttributeBtn': 'Skapa attribut',
  'attributes.addAttributeInputPlaceholder': 'Ge ditt nya attribut ett namn...',
  'attributes.usage': 'Används av {count} kontakter',
  'attributes.placeholder.title': 'Gör ditt nyhetsbrev mer personligt!',
  'attributes.placeholder.description': 'Attribut är personliga markörer och etiketter. Skapa anpassade attribut så som; företagsnamn, medlemstyp eller land.',
  'attributes.deleted': 'Attributen är nu raderade',

  // /contacts
  'contacts.checkedBtn': `{count, plural,
    =0 {Inga markerade kontakter}
    one {En markerad kontakt}
    other {{count} markerade kontakter}
  }`,
  'contacts.selectedBtn': `{count, plural,
    =0 {Inga kontakter valda}
    one {En kontakt}
    other {{count} kontakter}
  }`,
  'subscriptions.checkedBtn': `{count, plural,
    =0 {Inga markerade prenumerationer}
    one {En markerad prenumeration}
    other {{count} markerade prenumerationer}
  }`,
  'subscriptions.selectedBtn': `{count, plural,
    =0 {Inga prenumerationer valda}
    one {En prenumeration}
    other {{count} prenumerationer}
  }`,
  'contacts.modalHeader.deleteContacts': `{count, plural,
    one {Radera kontakt}
    other {Radera {count} kontakter}
  }`,
  'contacts.modalHeader.exportContacts': `{count, plural,
    one {Exportera kontakt}
    other {Exportera {count} kontakter}
  }`,
  'contacts.modalHeader.addSubscriptions': `{count, plural,
    one {Lägg till prenumeration}
    other {Lägg till prenumerationer}
  }`,
  'contacts.delete.areYouSure': `{count, plural,
    one {Är du säker på att du vill radera en kontakt?}
    other {Är du säker på att du vill radera {count} kontakter?}
  }`,
  'contacts.subscribe.areYouSure': `{count, plural,
    one {Är du säker på att du vill skapa en ny prenumeration för den här kontakten?}
    other {Är du säker på att du vill skapa nya prenumerationer för de här {count} kontakterna?}
  }`,
  'contacts.exportContacts': `Exportera kontakter`,
  'contacts.connectFields.title': 'Matcha fälten för de kontakter du ska importera',
  'contacts.connectFields.tellUs': 'Berätta vilket fält som är e-postadress *',
  'contacts.connectFields.consent': 'Godkänn*',
  'contacts.connectFields.consentDescription': 'Jag lovar att samtliga importerade e-postadresser tillhör mina egna kunder och/eller att de har samtyckt till att ta emot nyhetsbrev.',
  'contacts.connectFields.description': `Koppla ihop fälten med rätt värde.
  Värdena i exemplet är tagna från den översta raden/kontakten i din import
  Om du inte har för/efternamn, välj e-postadressen och fortsätt.`,
  'contacts.file.updateContact': `Uppdatera dina kontakter med den nya informationen från de importerade kontakterna.
  De fält som inte inkluderas av importen kommer att lämnas orörda.`,
  'contacts.file.existingContact': 'Uppdatera existerande kontakter',
  'contacts.file.title': 'Ladda upp fil',
  'contacts.file.description': 'Välj lista, ladda upp din fil och välj om du vill uppdatera existerande kontakter eller inte.',
  'contacts.file.chooseFileDescription': 'Välj vilken lista dina kontakter ska prenumerera på.',
  'contacts.file.chooseList': 'Välj en lista',
  'contacts.file.uploadYourFile': 'Ladda upp din fil',
  'contacts.file.uploadYourFileDescription': `Vi tar emot Excel-filer (xls or xlsx) och CSV-filer.
  Välj fil genom att klicka på knappen för välj fil nedanför.`,
  'contacts.copy.title': 'Kopiera/Klistra',
  'contacts.copy.description': 'Välj en lista, kopiera/klistra in dina kontakter och välj om du vill uppdatera existerande kontakter eller inte.',
  'contacts.copy.pasteContacts': 'Klistra in kontakter',
  'contacts.copy.pasteContactsDescription': `Klistra in de kontakter du vill importera i formuläret nedanför, antingen från en Excel-fil eller en textfil.
  Kontaktlistan måste ha en kontakt per rad. Fälten kan separeras med kommatecken, mellanslag eller semikolon.`,
  'contacts.import.title': 'Importera kontakter',
  'contacts.import.description': 'Hur vill du importera dina kontakter? Kopiera och klistra eller ladda upp en fil.',
  'contacts.import.copyDescription': 'Kopiera och klistra in kontakter från en textfil.',
  'contacts.import.importNow': 'Importera nu',
  'contacts.import.fileDescription': 'Ladda upp xls, xlsx eller csv-filer.',
  'contacts.success.title': 'Luta dig tillbaka och koppla av, så sköter vi resten',
  'contacts.success.description': `Din fil har nu laddats upp och kontakterna importeras. Importen sker i bakgrunden
  så varför inte börja arbeta med ditt nyhetsbrev? Vi tar hand om dina kontakter under tiden.`,
  'contacts.success.notify': 'Du får ett meddelande i verktyget när importen är klar',
  'contacts.summary.title': "Importen är klar",
  'contacts.summary.description': `Du kan se en sammanfattning av importen nedanför.
  Dubbletter kommer inte att  importeras, men de kommer ändå att läggas till listan du valt.`,
  'contacts.summary.newContacts': 'Nya kontakter',
  'contacts.summary.updatedContacts': 'Uppdaterade kontakter',
  'contacts.summary.newSubscriptions': 'Nya prenumerationer',
  'contacts.attribute.title': 'Skapa attribut',
  'contacts.attribute.created': 'Ett nytt attribut har skapats.',
  'contacts.attribute.delete': 'Radera attribut',
  'contacts.attribute.edit': 'Redigera attribut',
  'contacts.attribute.updated': 'Attributet har uppdaterats.',
  'contacts.attribute.usedBy': 'Används av',
  'contacts.attribute.contacts': 'kontakter.',
  'contacts.attribute.occurs': 'Ett fel uppstod när attributet skulle raderas',
  'contacts.attribute.areYouSure': 'Är du säker på att du vill radera attribut',
  'contacts.attribute.theAttribute': 'Attributet',
  'contacts.attribute.deleting': 'Radera attribut',
  'contacts.attribute.hasBeen': 'Har tagits bort.',
  'contacts.addContact.title': 'Lägg till kontakt',
  'contacts.addContact.saved': 'En ny kontakt har sparats.',
  'contacts.addContact.addSubscriptions': 'Lägg till prenumerationer',
  'contacts.addContact.addSubscriptionsSelectPlaceholder': 'Välj en eller flera listor...',
  'contacts.addContact.addSubscriptionsSelectHelper': 'Tips! Lägg snabbt till nya listor genom att ange ett namn som inte redan existerar.',
  'contacts.addAttribute': 'Lägg till Attribut',
  'contacts.cancelSubscriptionText': "Det här kan ta en stund. Lägg den här processen i bakgrunden så talar vi om när det är klart.",
  'contacts.allDone': 'Då var det klart',
  'contacts.error': "Ett fel har uppstått och prenumerationerna kan inte avslutas.",
  'contacts.subscriptionsAreYouSure': "Är du säker på att du vill avprenumerera {count} prenumeranter?",
  'contacts.subscriptionsCopyAreYouSure': "Är du säker på att du vill kopiera {count} prenumeranter?",
  'contacts.subscriptionsMoveAreYouSure': "Är du säker på att du vill flytta {count} prenumeranter?",
  'contacts.putProcess': "Lägg processen i bakgrunden",
  'contacts.subscriptions.takeAWhile': "Det här kan ta en stund. Lägg den här processen i bakgrunden så talar vi om när det är klart.",
  'contacts.subscriptions.getData': 'Klicka på knappen "Ladda ner" för att hämta din fil.',
  'contacts.subscriptions.download': "Ladda ned",
  'contacts.subscriptions.lists': "Välj listan att prenumerera på",
  'contacts.subscriptions.exportSubscription': "Exportera prenumerationer",
  'contacts.api.title': "Prenumerationsformulär",
  'contacts.api.addForm': "Skapa formulär",
  'contacts.api.create': "Skapa ditt första prenumerationsformulär",
  'contacts.api.createDescription': "Prenumerationsformulär kan användas på din hemsida eller sociala plattformar så att människor kan prenumerera på dina listor",
  'contacts.api.createBtn': "Skapa formulär",
  'contacts.api.delete': 'Du kommer att radera följande formulär "{name}"',
  'contacts.api.deleted': "Formuläret har raderats",
  'contacts.api.placeholder.title': "Gör det enkelt att följa dig!",
  'contacts.api.placeholder.description': "Med ett formulär på din hemsida samlar du smidigt nya prenumeranter och samlar dem i en lista. Redo att sätta igång?",
  'contacts.filter': "Filtrera",
  'contacts.numberOf': "Antal kontakter:",
  'contacts.listEmpty': "Listan över kontakter är tom",
  'contacts.placeholder.title': "Importera och börja nätverka",
  'contacts.placeholder.description': "Det bästa sättet att komma igång är genom att importera dina kontakter via en fil. Har du bara några enstaka kontakter kan du enkelt kopiera och klistra in dem och sedan är det klart!",
  'contacts.filterByActive': "Filtrera på aktiv",
  'contacts.filterByUpdated': "Filtrera på uppdaterad",
  'contacts.filterByList': "Filtrera på lista",
  'contacts.radio.showAll': "Visa alla kontakter",
  'contacts.radio.showActive': "Aktiva kontakter",
  'contacts.radio.showInactive': "Inaktiva kontakter",
  'contacts.radio.last7Days': "De senaste 7 dagarna",
  'contacts.radio.last30Days': "De senaste 30 dagarna",
  'contacts.radio.last365Days': "Det senaste året",
  'contacts.radio.showAllSubscriptions': "Visa alla prenumerationer",
  'contacts.radio.notCancelled': "Ej avslutad",
  'contacts.radio.cancelled': "Avslutad",
  'contacts.radio.filterBy': "Filtrera på lista",
  'contacts.showAllContacts': "Visa kontakter för alla listor",
  'contacts.lists.autorespondersRunning': "{value} automatiska utskick är igång",
  'contacts.lists.autorespondersNotRunning': "Inga automatiska utskick är igång",
  'contacts.lists.activeSubscribers': "{value} aktiva prenumeranter",
  'contacts.lists.delete': 'Du kommer att radera följande lista: "{value}"',
  'contacts.lists.placeholder.title': "Segmentera din målgrupp",
  'contacts.lists.placeholder.description': "Dina listor hjälper dig att organisera dina kontakter och skicka anpassade meddelanden till rätt personer. Du kan ha så många listor som du behöver.",
  'contacts.options.choose': "Välj åtgärd",
  'contacts.options.cancel': "Avprenumerera",
  'contacts.options.delete': "Radera",
  'contacts.options.export': "Exportera",
  'contacts.options.copy': "Kopiera till...",
  'contacts.options.move': "Flytta till...",
  'contacts.chooseFor': "Välj listor att prenumerera på",
  'contacts.subscription.filter': "Filtrera på startdatum",
  'contacts.subscription.cancelled': "Avslutad/ Ej avslutad",
  'contacts.modal.export.header': "Din fil håller på att skapas",
  'contacts.modal.cancelSubscriptions': "Är du säker på att du vill avprenumerera den här prenumerationen?",
  'contacts.modal.reactivateSubscriptions': "Du är på väg att återaktivera en avslutad prenumeration. Var säker på att du har mottagarens godkännande innan du genomför den här ändringen. Tänk också på att riskerna för att fastna i spamfilter eller till och med svartlistas ökar om du gör detta utan mottagarens särskilda godkännande.",
  'contacts.modal.addANewSubscription': 'Lägg till en ny prenumeration',
  "contacts.modal.reactivateBtn": 'Återaktivera',
  'contacts.showNotSubscribed': 'Visa kontakter utan prenumeration',

  // single contact page
  'contact.editContact': 'Redigera kontakt',
  'contact.activeSince': 'Aktiv sedan',
  'contact.cancelledOn': 'Avslutad den',
  'contact.cancelSubscription': 'Avsluta prenumeration',
  'contact.reactivateSubscription': 'Reaktivera prenumeration',
  'contact.step.enterContact': 'FYLL I KONTAKTINFORMATION',
  'contact.step.fillInContactInformation': 'Fyll i kontaktinformation. E-postadress krävs.',
  'contact.step.manageAttributes': 'HANTERA ATTRIBUT',
  'contact.step.addExtraInformation': 'Lägg till extra information för din kontakt.',
  'contact.step.addSubscriptions': 'LÄGG TILL PRENUMERATIONER',
  'contact.step.chooseOneOrMoreLists': 'Välj en eller flera listor som kontakten ska prenumerera på.',
  'contact.contactStatus': 'Status för kontakt',
  'contact.activeStatus': 'Aktiv',
  'contact.lastUpdated': 'Senast uppdaterad',
  'contacts.delete': `Radera`,
  'contacts.modal.areYouSure': `Är du säker på att du vill radera {email}?`,

  // affiliate
  'affiliate.bannerAndLinks.showCode': '-visa kod',
  'affiliate.bannerAndLinks.title': 'Annonser & länkar',
  'affiliate.bannerAndLinks.description': `Här hittar du en webbadress och ett antal bildannonser som du kan använda till din hemsida eller blogg för att tipsa dina besökare om Get a Newsletter.
  Varje gång någon klickar på länken/annonsen registreras det och om ett konto skapas kommer du att få provision.`,
  'affiliate.bannerAndLinks.textLink': 'Webbadress',
  'affiliate.bannerAndLinks.imageBanners': 'Bildannonser',
  'affiliate.bannerAndLinks.imageBannersDescription': 'Hitta en annons som du tycker passar din målgrupp, kopiera koden nedanför och klistra in den på din hemsida eller blogg för att få provision för de besökare som klickar sig vidare till Get a Newsletter och registrerar ett konto.',
  'affiliate.getStarted.title': 'Kom igång',
  'affiliate.getStarted.description': `Det är busenkelt att komma igång.
  Bara kopiera och klistra in din personliga kod för att börja marknadsföra Get a Newsletter med bildannonser och länkar och tjäna extrapengar. Vi håller koll på vilka som blivit tipsade av dig och du får betalt för varje person som skapar ett konto hos oss via din länk.`,
  'affiliate.getStarted.thisIsWhatYouEarn': 'Så här mycket tjänar du',
  'affiliate.getStarted.signupForFree': 'Registrering för gratiskonto',
  'affiliate.getStarted.upgradeToPaidAccount': 'Uppgradering till betalkonto',
  'affiliate.getStarted.upgradeToPaidAccountDescription': `Provisionen är exklusive moms och avser företag som är godkända för F-skatt. Du får 20% av kontopriset löpande för varje gång en användare förnyar sitt konto - år efter år.
  Du behöver tjäna in 500 kr eller mer för att kunna begära en utbetalning.`,
  'affiliate.getStarted.howToGetStarted': 'Så kommer du igång',
  'affiliate.getStarted.howToGetStartedDescription': 'Skriv ett blogginlägg om Get a Newsletter, dela med dig på Facebook/ Twitter eller lägg upp en annons på din hemsida. Kom ihåg att använda de länkar eller annonser som innehåller ditt särskilda affiliate-ID, ',
  'affiliate.getStarted.howToGetStartedDescriptionLink': 'du hittar dem här',
  'affiliate.overview.title': 'Översikt',
  'affiliate.overview.emptyComissionMainText': 'Vad roligt att du hittat till affiliateprogrammet och vill tipsa vänner och bekanta om Get a Newsletter!',
  'affiliate.overview.emptyComissionSmall': `Börja med att läsa igenom vår kom igång-guide som du hittar i menyn till vänster.
  När du kommit igång kommer du kunna se hur mycket pengar du tjänat och följa dina framgångar här.`,
  'affiliate.overview.getStarted': 'Kom igång',
  'affiliate.overview.haveEarned': 'Snyggt jobbat! Du har tjänat {total} {currency} så här långt. Här hittar du en sammanställning över de konton som värvats via dig och summan du tjänat in.',
  'affiliate.overview.moneyEarned': 'Intjänad provision',
  'affiliate.overview.commissionWaiting': 'Kommande provision',
  'affiliate.overview.commissionPaid': 'Provision utbetald',
  'affiliate.overview.commissionTotal': 'Sammantagen provision',
  'affiliate.overview.requestPayment': 'Begär utbetalning',
  'affiliate.overview.typeOfAccounts': 'Kontotyp',
  'affiliate.overview.freeAccount': 'Gratiskonto',
  'affiliate.overview.paidAccount': 'Betalkonto',
  'affiliate.overview.viewTransactions': 'Visa transaktioner',
  'affiliate.transactions.title': 'Transaktioner',
  'affiliate.transactions.description': `Du har värvat {users} användare,
  {payed} som har valt att betala för ett konto.
  Det har genererat i en kickback på totalt {summ} {currency}.`,
  'affiliate.links.overview': 'Översikt',
  'affiliate.links.getStarted': 'Kom igång',
  'affiliate.links.bannersLinks': 'Annonser & länkar',
  'affiliate.links.transactions': 'Transaktioner',
  'affiliate.links.requestPayment': 'Begär utbetalning',
  'affiliate.links.payouts': 'Utbetalningar',
  'affiliate.payouts.downloadSpec': 'Ladda ned specifikation',
  'affiliate.payouts.downloadInvoice': 'Ladda ned faktura',
  'affiliate.payouts.title': 'Utbetalningar',
  'affiliate.payouts.description': `Det här är en lista över alla utbetalningar som gjorts sedan vi införde självfakturering (aug 2016).
  Du kan ladda ned fakturan och specifikationen för varje utbetalning och se nuvarande status. Så snart vi gjort en utbetalning kommer statusen att ändras från Ej betald till Utbetald.`,
  'affiliate.request.title': 'Begär utbetalning',
  'affiliate.request.earn': 'Du behöver tjäna in 500 kr eller mer för att kunna begära en utbetalning.',
  'affiliate.request.tips': 'Tips för att uppnå dina mål',
  'affiliate.request.writeBlog': `Skriv ett blogginlägg om Get a Newsletter, dela med dig på Facebook,Twitter eller lägg upp en annons på din hemsida.
  Kom ihåg att använda de länkar eller annonser som innehåller ditt särskilda affiliate-ID, `,
  'affiliate.request.findThemHere': 'du hittar dem här',
  'affiliate.request.findTips': 'Hitta fler tips',
  'affiliate.request.description': `Fyll i informationen nedanför och klicka på Begär utbetalning.
  Vi kommer att skapa en självfakturering, vilket innebär att vi skapar en faktura i ditt namn.`,
  'affiliate.request.descriptionLink': 'Läs mer om självfakturering',
  'affiliate.request.comission': ' Provision att betala ut: ',
  'affiliate.request.enterInfo': 'Fyll i din information',
  'affiliate.request.enterInfoDescription': 'Informationen nedanför är densamma som under Mitt konto > Mina uppgifter, vilket gör att informationen uppdateras på båda ställena om du ändrar något.',
  'affiliate.request.paymentInfo': 'Fakturor och betalinformation',
  'affiliate.request.paymentInfoDescription': 'Skriv in ditt bankgironummer och godkänn villkoren.',
  'affiliate.request.confirm': `Härmed bekräftar jag att mitt företag är registrerat för f-skatt och att ovan information är korrekt.
  Jag godkänner också att Get a Newsletter Scandinavia AB använder självfakturering vid utbetalning av provision.`,
  'affiliate.request.recommend': 'Tjäna pengar genom att rekommendera Get a Newsletter',
  'affiliate.request.thanks': 'Tack så mycket',
  'affiliate.request.telling': `Tack för att du tipsar dina vänner och bekanta om Get a Newsletter.
  Vi har skapat en självfaktura och kommer betala ut den intjänade provisionen inom 10 dagar.
  Du kan se status för din utbetalning under '`,
  'affiliate.request.payouts': 'utbetalningar',
  'affiliate.request.also': 'Vi har även skickat ett mail till dig med din faktura och en nedladdningsbar specifikation.',
  'affiliate.request.seeStatus': 'Se status under Utbetalningar.',

  // API tokens
  'apiTokens.title': 'API Tokens',
  'apiTokens.plugins': 'Plugins',
  'apiTokens.pluginsDescriptions': 'Använder du Wordpress? Vi har ett plugin för Wordpress som gör det enklare att lägga till ett prenumerationsformulär på din webbplats.',
  'apiTokens.seeInstructions': 'Se instruktioner',
  'apiTokens.description': "Skapa en API-token för att enkelt integrera Get a Newsletter med andra system.",
  'apiTokens.deleteModal': 'Du kommer att radera fäljande token: "{token}"',
  'apiTokens.addToken': 'Skapa API token',
  'apiTokens.placeholder.title': 'Integrera dina system',
  'apiTokens.placeholder.description': `Med vårt API kopplar du smidigt ihop Get a Newsletter med andra applikationer.

  Skapa API Tokens eller kika närmare på vår API-dokumentation och kom igång redan idag!`,

  // Notifications
  'notifications.unread': 'Olästa meddelanden',
  'notifications.all': 'Alla meddelanden',
  'notifications.placeholder.title': 'Inga olästa meddelanden',
  'notifications.placeholder.description': 'Just nu har du inga meddelanden, men det är här du hittar alla olästa meddelanden som rör ditt konto från och med nu.',

  // Profile
  'profile.confirmModal.modalName': 'Du måste bekräfta ditt lösenord',
  'profile.confirmModal.title': 'Du måste bekräfta ditt lösenord för att uppdatera dina Inloggningsuppgifter.',
  'profile.confirmModal.label': 'Ange ditt nuvarande lösenord',
  'profile.confirmModal.button': 'Bekräfta lösenord',
  'profile.loginDetails': 'Inloggningsuppgifter',
  'profile.accountOwner': 'Kontoägare',
  'profile.accountOwner.description': 'Din adress och företagets/organisationens/personliga information användas vid handläggning av fakturor och för att vi skall kunna hålla koll på vilka som använder vår tjänst.',
  'profile.loginDetails.description': 'Inloggningsuppgifterna används när du loggar in på ditt konto. Vårt system kommer att skicka meddelanden till den angivna e-postadressen vid behov.',
  'profile.updateOwnerSettings': 'Spara kontoägare',
  'profile.updateBillingSettings': 'Spara fakturainställningar',
  'profile.invoiceSettings': 'Fakturainställningar',
  'profile.invoiceSettings.description': 'Faktura är endast tillgängligt när du betalar årsvis. Om du vill att fakturan ska skickas via post tillkommer en kostnad på 50 SEK.',
  'profile.invoiceSettings.updateInvoiceSettings': 'Uppdatera fakturainställningar',
  'profile.invoiceSettings.differentBillingAddress': 'Jag vill ha en annan faktureringsadress',
  'profile.invoiceSettings.sendingOptions': 'Sändningsalternativ',
  'profile.invoiceSettings.sendingByEmail': 'Skicka faktura via mejl',
  'profile.invoiceSettings.sendingByPost': 'Skicka faktura via post',
  'profile.invoiceByPost': 'Faktura via post',
  'profile.billingAddress': 'Faktureringsadress',
  'profile.shortPasswordError': 'Lösenordet måste innehålla minst 6 tecken!',
  'profile.existPasswordError': 'Lösenordet är felaktigt!',
  // Senders
  'senders.title': 'Dina avsändare',
  'senders.description': 'En avsändare är namnet och e-postadressen som visar vem som skickat nyhetsbrevet.',
  'senders.deleteModal': "Du håller på att radera den här avsändaren: `{name}'",
  'senders.addSender': 'Skapa avsändare',
  'senders.addSender.description': 'Vi rekommenderar att du använder ditt eget namn och e-postadress. Undvik att använda adresser som no-reply@foretag.se',
  'senders.editSender': 'Redigera avsändare',

  // Settings
  'settings.newsletter': 'Nyhetsbrev',
  'settings.newsletterDescription': 'Få de senaste nyheterna och tips om e-postmarknadsföring genom att registrera dig för vår nyhetsbrev. Du kan enkelt avsluta genom att klicka på länken för avprenumeration i våra nyhetsbrev.',
  'settings.language': 'Språk',
  'settings.languageDescription': 'Välj mellan svenska eller engelska.',
  'settings.timeZone': 'Tidszon',
  'settings.timeZoneDescription': 'Ditt val av tidszon används som referens när du schemalägger dina utskick.',
  'settings.generalSettings': 'Allmänna inställningar',
  'settings.partners': 'Partners',
  'settings.deleteModal': "Du håller på att radera den här partnern: `{name}'",
  'settings.addPartner': 'Lägg till partner',

  // Account
  'account.title': 'Din nuvarande prenumeration',
  'account.description': 'I takt med att antalet kontakter växer kommer vi automatiskt flytta dig mellan de olika kontonivåerna.',
  'account.billingHistory': 'Köphistorik',
  'account.upgradeAccount': 'Uppgradera ditt konto',
  'account.upgradeAccountDescription': 'Upgradera och välj det abonnemang som passar dig bäst. Kontakta oss om du vill skicka till fler än 1 miljon kontakter.',
  'account.upgrade': 'Uppgradera',
  'account.newCreditCard.thanks': "Tack för att du väljer oss!",
  'account.newCreditCard.youCanUse': "Nu kan du fortsätta använda verktyget.",
  'account.payByInvoice.yourInvoiceHasBeenSent': 'Din faktura har skickats!',
  'account.payByInvoice.thankYou': 'Tack så mycket! Vi ser fram emot din betalning. Vi lovar att göra allt vi kan för att göra din upplevelse i verktyget så bra som möjlig.',
  'account.downloadInvoice': 'Ladda ner faktura',
  'account.payByInvoice.asSoonAsWeRecieveYourPayment': 'Så fort vi har fått din betalning kommer vi att uppgradera ditt konto. Du kan se alla dina fakturor under ',
  'account.payByCard.thanksForUpgrading': 'Tack för din uppgradering!',
  'account.payByCard.yourAccountIsNowUpgraded': 'Ditt konto är nu uppgraderat och du kan komma igång med att skicka mer mail. Vi ska göra vårt absolut bästa för att du ska få en så bra upplevelse som möjligt.',
  'account.payByCard.youCanSee': 'Du kan se alla dina fakturor under',
  'account.pauseAccountDescription': 'Pausa din prenumeration efter nuvarande period. Du kan göra allt förutom att skicka nyhetsbrev när kontot är pausat.',
  'account.activateAccountDescription': 'Aktivera ditt konto för att kunna skicka nyhetsbrev.',
  'account.pause': 'Pausa',
  'account.activate': 'Aktivera',
  'account.pauseAccount': 'Pausa konto',
  'account.activateAccount': 'Aktivera konto',
  'account.deleteAccount': 'Radera konto',
  'account.deleteAccountDescription': 'Säg upp ditt abonnemang och radera all information tillhörande kontot. Kontot går inte att återställa när det är raderat.',
  'account.activeCard': 'Ditt aktiva kort',
  'account.activeCardDescription': 'Det här är ditt aktiva kort. För att byta kort klickar du på länken intill kortnumret.',
  'account.changeCard': 'Byt kort',
  'account.downgradeAccount': "Nedgradera till gratis",
  'account.downgradeAccountDescription': "Nedgradera till gratis konto i slutet av den aktuella perioden",
  'account.downgradeAccountModalDescription': "Är du säker på att du vill nedgradera till gratis i slutet av de aktuella prenumerationerna?",
  'account.downgradeAccountBtn': "Downgrade",
  'account.downgradeAccountSuccess': "Ditt konto kommer att nedgraderas till gratis efter slutet av den nuvarande prenumerationen.",

  //Auth
  'auth.everyDay': 'Varje dag förlitar sig 20 000 användare på oss',
  'auth.leftCard': 'Det bästa med er är att ni ständigt utvecklar verktyget så att det är lätt att göra nyhetsbreven. Man behöver inte ha ”ångest” utan kan enkelt och snabbt slutföra. 5 min senare är det klart och tiden kan istället utnyttjas till att utveckla företaget.',
  'auth.rightCard': 'Vi har använt GAN till våra nyhetsbrev i 7 år och är mycket nöjda! Lätt att använda och aldrig några problem. Utan tvekan vår mest prisvärda marknadsföringskanal.',
  'auth.loginToNewsletter': 'Logga in på Get a Newsletter',
  'auth.forgotPassword': 'Har du glömt ditt lösenord?',
  'auth.login': 'Logga in',
  'auth.loginAgree': 'När du registrerar ett konto godkänner du samtidigt våra',
  'auth.terms': 'villkor.',
  'auth.and': 'och ',
  'auth.conditions': 'conditions',
  'auth.dontHaveAccount': "Har du inte ett konto ännu? ",
  'auth.alreadyHaveAccount': "Har du redan ett konto? ",
  'auth.signup': 'Registrera dig',
  'auth.signupNow': 'Registrera dig gratis',
  'auth.signin': 'Logga in',
  'auth.getStarted': 'Kom igång',
  'auth.forFree': 'helt gratis',
  'auth.signupDescription': 'Skicka 500 mail/månad till 100 kontakter utan kostnad. Ingen uppsägningstid eller några dolda avgifter.',
  'auth.resetPasswordInstructions': "Ange din e-postadress så skickar vi en länk för att återställa ditt lösenord.",
  'auth.resetPasswordButton': 'Spara nytt lösenord',

  //Delete Account
  'deleteAccount.title': 'Radera konto',
  'deleteAccount.links.keep': 'Behåll mitt konto',
  'deleteAccount.links.give': 'Lämna feedback',
  'deleteAccount.links.pause': 'Pausa mitt konto',
  'deleteAccount.links.delete': 'Radera ',
  'deleteAccount.saveMoney.emailMarketing': 'Visste du att marknadsföring via e-post är nästan 40 gånger mera effektivt än den via sociala medier när det kommer till att värva kunder?',
  'deleteAccount.saveMoney.didYouKnow': `Visste du att du kan få Get a Newsletter för ännu mindre?
  Med ett årsabonnemang tjänar du 20% på priset du betalar per månad. `,
  'deleteAccount.saveMoney.didYouKnowLink': 'Beräkna din årliga årliga kostnad här.',
  'deleteAccount.technicalProblem.sorry': 'Vad tråkigt att höra! Vi lovar att göra vårt bästa för att lösa det här åt dig! Det finns två sätt vi kan hjälpa dig på:',
  'deleteAccount.technicalProblem.emailUs': 'Maila oss på {link} och beskriv ditt ärende.',
  'deleteAccount.technicalProblem.visitOur': 'Besök vår hjälparea {link} – Vi hoppas att du finner svaret på din fråga där!',
  'deleteAccount.noResults.sorry': 'Vad tråkigt att höra! Men innan du bestämmer dig, vill du kanske ta del av våra bästa tips på hur man uppnår sina mål med hjälp av marknadsföring via e-post',
  'deleteAccount.noResults.7tips': '7 Tips - Så skriver du nyhetsbrev som blir lästa',
  'deleteAccount.noResults.5tips': '5 tips på hur du hittar nya kunder med e-postmarknadsföring',
  'deleteAccount.noResults.tips': 'Tips på hur du ökar engagemanget hos dina läsare',
  'deleteAccount.anotherTool.sad': `Vi är ledsna att höra det! Vi arbetar alltid med  att utveckla nya lösningar och funktioner enligt våra kunders önskemål.
  Kontakta oss gärna och tala om vad du tycker!
  Innan du trycker på avsluta-knappen, skulle vi först vilja påminna dig om allt som ingår när du är kund hos oss:`,
  'deleteAccount.anotherTool.blockEditor': 'En blockeditor där du kan skapa fantastiska nyhetsbrev utan några tekniska förkunskaper.',
  'deleteAccount.anotherTool.unlimited': 'Obegränsat antal med mail att skicka varje månad.',
  'deleteAccount.anotherTool.statistics': 'Löpande statistik för bättre uppföljning.',
  'deleteAccount.anotherTool.simple': 'Enkel prissättning baserat på det antal kontakter du har.',
  'deleteAccount.anotherTool.complete': 'Alltid tillgång till alla våra funktioner - oavsett prisnivå.',
  'deleteAccount.anotherTool.subscription': 'Ett prenumerationsformulär som hjälper dig bygga upp en kundkrets.',
  'deleteAccount.anotherTool.free': 'Fri support.',
  'deleteAccount.noContacts.understand': `Vi förstår! Ett stort antal prenumeranter är ett mål som de flesta företag eftersträvar.
  Innan du avslutar ditt konto kan det vara värt att läsa igenom våra tips på hur du samlar nya prenumeranter.`,
  'deleteAccount.noContacts.tell': 'Tipsa människor om ditt nyhetsbrev på ditt visitkort.',
  'deleteAccount.noContacts.ask': 'Fråga befintliga kunder om de vill prenumerera på ditt nyhetsbrev.',
  'deleteAccount.noContacts.create': 'Skapa ett prenumerationsformulär och placera det på flera ställen på din webbplats.',
  'deleteAccount.noContacts.link': 'Länka till ditt prenumerationsformulär i din mailsignatur.',
  'deleteAccount.noContacts.leave': 'Lämna ett anmälningsformulär i kassan om du har en fysisk butiik.',
  'deleteAccount.noContacts.share': 'Dela ditt nyhetsbrev på sociala medier.',
  'deleteAccount.noContacts.organize': 'Arrangera en tävling som kräver en prenumeration på ditt nyhetsbrev.',
  'deleteAccount.closingBusiness': `Vad tråkigt att höra! Du kan välja mellan att antingen avsluta eller pausa ditt konto.
  Du behöver inte betala något när ditt kort är pausat och kan aktivera ditt konto när du känner dig redo att återuppta dina utskick på nytt.`,
  'deleteAccount.other.before': 'Innan du trycker på avsluta-knappen har vi några frågor till dig. Visste du att…',
  'deleteAccount.other.percent': '85 procent av världens internetanvändare använder e-post?',
  'deleteAccount.other.more': 'Fler och fler människor använder sig av smartphones idag vilket ger dem tillgång till sin mail på flera enheter.',
  'deleteAccount.other.average': 'Genomsnittet av unika öppningar för ett mail är 20%?',
  'deleteAccount.other.organic': 'Den organiska räckvidden på Facebook är 6% och att det på Twitter bara är 8%- 10% av dina följare som ser din tweet.',
  'deleteAccount.other.emails': 'Marknadsföring via e-post är oslagbart jämfört med andra marknadsföringskanaler!',
  'deleteAccount.other.email': 'Nyhetsbrev har en klickfrekvens som är 50 - 100 % högre än liknande inlägg på sociala medier?',
  'deleteAccount.radios.technical': 'Jag har ett tekniskt problem som jag behöver lösa.',
  'deleteAccount.radios.save': 'Jag försöker spara pengar.',
  'deleteAccount.radios.getting': 'Jag uppnår inga resultat med min e-postmarknadsföring.',
  'deleteAccount.radios.switching': 'Jag byter från Get a Newsletter till ett annat verktyg för nyhetsbrev',
  'deleteAccount.radios.enough': 'Jag har inte tillräckligt många kontakter.',
  'deleteAccount.radios.closing': 'Min verksamhet skall upphöra',
  'deleteAccount.radios.other': 'Annan',

  //Detailed report
  'detailedReport.bounces.title': 'Studsar',
  'detailedReport.bounces.seeAll': 'Se alla studsar',
  'detailedReport.bounces.goodJob': 'Det finns inga studsar - bra jobbat!',
  'detailedReport.bounces.mostActive': 'Mest aktiva mottagare',
  'detailedReport.bounces.popularLinks': 'Populära länkar',
  'detailedReport.deleteModal.title': 'Du håller på att radera den här rapporten:',
  'detailedReport.deleteModal.yes': 'Ja tack, jag vill radera det tillhörande utskicket',
  'detailedReport.share.facebook': 'Dela nyhetsbrevet på Facebook',
  'detailedReport.share.twitter': 'Dela nyhetsbrevet på Twitter',
  'detailedReport.share.linkedin': 'Dela nyhetsbrevet på LinkedIn',
  'detailedReport.share.online': 'Länk för att dela nyhetsbrevet online',
  'detailedReport.actions.preview': 'Förhandsgranska utskick',
  'detailedReport.actions.export': 'Exportera rapport',
  'detailedReport.actions.delete': 'Radera rapport',
  'detailedReport.sent': 'Skickat {sent} till {sentTo} prenumeranter på ',
  'detailedReport.toggle.share': 'Dela',
  'detailedReport.toggle.actions': 'Verktyg',
  'detailedReport.exportModal.click': 'Klicka på knappen för nedladdning för att komma åt informationen.',
  'detailedReport.recipients.empty': 'Det finns inga mottagare',
  'detailedReport.recipients.seeAll': 'Se alla mottagare',
  'detailedReport.openings.title': 'Öppningar och länkklick',
  'detailedReport.openings.first': 'Första 24 timmarna',
  'detailedReport.openings.all': 'All aktivitet',
  'detailedReport.openings.openings': 'Öppningar',
  'detailedReport.openings.rate': 'Klickfrekvens',
  'detailedReport.links.title': 'Det finns inga länkar',
  'detailedReport.links.see': 'Se vem som klickat',
  'detailedReport.links.noRecipients': 'Det finns inga Mottagare',
  'detailedReport.links.back': 'Tillbaka till länkar',
  'detailedReport.links.seeAll': 'Se alla länkar',
  'detailedReport.links.activeRecipients': 'Aktiva mottagare',
  'detailedReport.links.popularLinks': 'Populära länkar',
  'detailedReport.links.bounces': 'Studsar',
  'detailedReport.links.recipients': 'Mottagare',
  'detailedReport.links.links': 'Länkar',

  //Email confirmation
  'emailConfirmation.title': 'Du behöver bekräfta din e-postadress',
  'emailConfirmation.message': `Viktigt meddelande`,
  'emailConfirmation.titleAlready': 'E-postadressen är bekräftad',
  'emailConfirmation.description': 'Ditt nyhetsbrev är nu flyttat till schemalagda utskick och vi kommer att skicka ut ditt nyhetsbrev enligt önskat datum och tid.: ',
  'emailConfirmation.descriptionAlready': 'E-postadressen är redan bekräftad',
  'emailConfirmation.descriptionSuccess': 'E-postadressen är nu bekräftad',
  'emailConfirmation.inOrderToUseTheFullFunctionality': 'En bekräftelselänk har skickats till din e-postadress.',
  'emailConfirmation.text': `Vänligen följ instruktionerna i det här mailet för att bekräfta din e-postadress.Om du inte hittar mailet ber vi dig kika in din SPAM/Skräpkorg,
  eller använda knappen nedanför för att få ett nytt bekräftelsemail. `,
  'emailConfirmation.button': 'Skicka ett nytt bekräftelsemail',

  //Add form
  'addForm.title': 'Skapa ett prenumerationsformulär',
  'addForm.question': 'Vilken typ av formulär vill du skapa?',
  'addForm.regularForm': 'Prenumerationsformulär',
  'addForm.regularFormText': `Skapa ett formulär som du kan lägga upp på din hemsida. Du får en kod som du kan klistra in var
  du vill på din sajt.`,
  'addForm.select': 'Välj',
  'addForm.popupForm': 'Popup formulär',
  'addForm.popupFormText': `Skapa ett popup formulär till din hemsida. Du kan visa det högst upp i ditt innehåll så att användarna ser formuläret när de är på väg att
  lämna sidan.`,
  'addForm.regularForm.description': `Inkludera formuläret på din hemsida eller länka till det på Get a Newsletter.
  Om du vill göra ändringar uppdaterar du bara fälten nedanför, sparar och kopierar koden på nytt.
  Om du använder dig av en länk behöver du inte kopiera den igen. Alla nya ändringar sparas automatiskt i vårt system.`,
  'addForm.regularForm.use': 'Använd prenumerationsformuläret',
  'addForm.regularForm.copy': `Kopiera den här HTML-koden, klistra in den på din hemsida och börja samla nya prenumeranter.
  Kom ihåg att din hemsida måste vara i HTML-läge när du klistrar in koden.`,
  'addForm.regularForm.useLink': 'Eller använd en länk istället',
  'addForm.regularForm.copy2': ' Använd den här länken i din e-postsignatur, i en knapp på din hemsida, på sociala medier eller skicka den till en potentiella kunder.',
  'addForm.regularForm.nameForm': 'Namnge ditt formulär',
  'addForm.regularForm.nameFormText': 'Ge ditt formulär ett beskrivande namn. Detta är endast för din egen användning, så att du kan skilja på dina formulär.',
  'addForm.regularForm.contactFields': 'Kontaktfält',
  'addForm.regularForm.contactFieldsText': 'Välj om du vill att fälten för- och efternamn ska vara synliga. E-postadressen är obligatorisk.',
  'addForm.regularForm.attributeFields': 'Attributfält',
  'addForm.regularForm.attributeFieldsText': 'Välj om du vill inkludera ytterligare information i formuläret.',
  'addForm.regularForm.listAndSender': 'Lista och avsändare',
  'addForm.regularForm.showAdvancedSettings': 'Visa avancerade inställningar',
  'addForm.regularForm.confirmationEmail': 'Bekräftelsemail',
  'addForm.regularForm.confirmationEmailText': `Bekräftelsemailet går ut till nya prenumeranter så att de kan bekräfta prenumerationen.
  Vi rekommenderar dig att skriva mejlet så personligt som möjligt så att du inte går miste om några prenumeranter.`,
  'addForm.regularForm.formSettings': 'Formulärinställningar',
  'addForm.regularForm.formSettingsText': 'Använd en egen landningssida och skriv en egen text till knappen.',
  'addForm.regularForm.updateForm': 'Uppdatera formulär',
  'addForm.regularForm.createForm': 'Skapa formulär',

  //Home
  'home.greatJob': 'Grymt jobbat! Du har nu slutfört samtliga steg i vår kom igång-guide.',
  'home.hide': 'Göm den här sektionen',
  'home.welcome': 'Välkommen, {name}!',
  'home.welcomeEmpty': `Välkommen!`,
  'home.welcomeBack': 'Välkommen tillbaka, {name}!',
  'home.welcomeBackEmpty': `Välkommen tillbaka!`,
  'home.getStarted': 'Kom igång med Get a Newsletter genom att färdigställa stegen här nedanför.',
  'home.confirmEmail': 'Bekräfta din e-postadress',
  'home.collect': 'Samla prenumeranter',
  'home.createLetter': 'Skapa utskick',
  'home.sendLetter': 'Skicka nyhetsbrev',
  'home.quickLinks': 'Genvägar',
  'home.upgrade': 'Uppgradera konto',
  'home.createList': 'Skapa lista',
  'home.importContacts': 'Importera kontakter',
  'home.createMail': 'Skapa utkast',
  'home.currentSubscripton': 'Din nuvarande prenumeration',
  'home.theCurrentAccountPeriod': 'Nuvarande period löper ut den:',
  'home.mailsLeft': 'Mail kvar den här månaden:',
  'home.balanceResets': 'Din kontobalans återställs den 10:e varje månad',
  'home.upgradeAccount': 'Uppgradera kontot',
  'home.viewBillingHistory': 'Se köphistorik',
  'home.youHave': 'Du har 1 ny kontakt på ditt konto.',
  'home.weWill': 'Beroende på hur många kontakter du har kommer vi automatiskt att flytta dig mellan de olika prisnivåerna. ',

  //Account status
  'accountStatus.free': 'Du har ett gratiskonto',
  'accountStatus.noFree' : 'Du kommer att debiteras med {currency} varje månad',
  'accountStatus.numberOfContacts' : '{minNumberOfContacts} - {maxNumberOfContacts} kontakter',
  'accountStatus.myContacts': 'Du har {content} kontakter på ditt konto.',
  'accountStatus.freeMail': 'Din kontobalans återställs den {balance} varje månad..',
  'accountStatus.billingHistory': 'Se köphistorik',
  'accountStatus.freeMails': 'Du kan skicka ytterligare {balance} mail denna månad',
  "accountStatus.upgrade": "Du måste uppgradera ditt konto för att skicka fler mail.",

  //Application NavBar
  'appNavBar.profileDropDown.upgrade': 'Uppgradera konto',
  'appNavBar.profileDropDown.profile': 'Mina uppgifter',
  'appNavBar.profileDropDown.account': 'Konto',
  'appNavBar.profileDropDown.notifications': 'Meddelanden',
  'appNavBar.profileDropDown.settings': 'Verktygsinställningar',
  'appNavBar.profileDropDown.senders': 'Avsändare',
  'appNavBar.profileDropDown.api': 'API',
  'appNavBar.profileDropDown.affiliate': 'Affiliates',
  'appNavBar.profileDropDown.logout': 'Logga ut',
  'appNavBar.actions.createList': 'Skapa lista',
  'appNavBar.actions.createMail': 'Skapa utskick',
  'appNavBar.actions.createForm': 'Skapa formulär',
  'appNavBar.actions.import': 'Importera kontakter',

  //Charts
  "charts.countOfSubscribers": '{subscribers} prenumeranter',

  //Footer
  "footer.text": '© {year} Get a Newsletter Scandinavia AB',
  "footer.support": 'Support',
  "footer.policy": 'Integritetspolicy',
  "footer.terms": 'Användarvillkor',

  //Invoices
  "invoices.payCard": 'Betala med kort',
  "invoices.paid": 'Betald',
  "invoices.pending": 'Under behandling',
  "invoices.overpaid": 'Överbetald',
  "invoices.resolve": 'Hantera',
  "invoices.inProgress": 'Återbetalning under behandling',
  "invoices.willRepay": `Vi kommer att återbetala dig hela beloppet om {balance} {currency} så snart som möjligt.
  När beloppet är återbetalt kommer status att ändras från "Under behandling" till "Betald".`,
  "invoices.invoiceNo": 'Fakturanummer:',
  "invoices.amount": 'Belopp',
  "invoices.excess": 'Överskjutande belopp:',
  "invoices.followingCard": 'Vi kommer att återbetala {balance} {currency} till det här kortet:',
  "invoices.repayAmount": 'Återbetala belopp',

  "card.yourCurrentCard": `Ditt nuvarande kort`,
  "card.newCard": `Betala med ett nytt kort`,
  "card.payCard": "Betala med kort",
  "card.date": `Datum`,
  "card.pay": `Betala`,

  //MonthSummary
  "monthSummary.heading": 'Månadsöversikt',
  "monthSummary.mails": 'Skickade mail',
  "monthSummary.newSub": 'Nya prenumeranter',
  "monthSummary.cancelSub": 'Avslutade prenumeranter',

  //Page loading
  "pageLoading.error": 'Ett fel uppstod när sidan laddades upp! ',
  "pageLoading.timeOut": 'Det tar lite längre tid för sidan att laddas upp... ',
  "pageLoading.reload": "Försök ladda om webbläsarfönstret igen",

  //Pagination
  "pagination.prev": 'Föregående',
  "pagination.next": 'Nästa',

  //Tables
  "tables.isEmpty": 'Listan är tom',

  //Modal add list
  "listModal.add": 'Skapa lista',

  //Modal delete account
  "modalDeleteAccount.deleteButton": 'Radera mitt konto',
  "modalDeleteAccount.confirm": 'Härmed bekräftar jag att jag vill radera mitt konto',
  "modalDeleteAccount.text1": 'Är du säker på att du vill radera ditt konto permanent?',
  "modalDeleteAccount.text2": 'Om du bekräftar, kommer dina prenumerationer, kontakter och rapporter raderas permanent och du kommer inte kunna logga in i systemet.',
  "modalDeleteAccount.text3": 'Den återstående perioden på ditt abonnemang kommer inte att återbetalas.',
  "modalDeleteAccount.deleteHeader": 'Radera konto',

  //Modal Autoresponder
  "modalResponderAutoresponder.header": 'Planera om ditt automatiska utskick',

  //Modal Replan Scheduled
  "modalReplanScheduled.modalTitle": 'Planera om utskick',
  "modalReplanScheduled.modalText": 'Här kan du ändra datum och tid för ditt utskick. Nuvarande tidsinställning för utskicket är.',

  //Modal Welcome
  "modalWelcome.didNotForgotAboutWorkflow": 'We did not forget about the general worfklow of the tool.',
  "modalWelcome.newLook": 'Nytt utseende.Nya funktioner.',
  "modalWelcome.getANewsletter": 'Ett bättre Get a Newsletter.',
  "modalWelcome.weFinallyHaveTheHonour": 'Äntligen får vi den stora äran att presentera den nya och förbättrade versionen av vårt verktyg. Some key upgrades include a new blockeditor with tons of beautiful templates and designed popup forms with endless possibilities.',
  "modalWelcome.takeATour": 'Prova på',
  "modalWelcome.newForms": 'Nya formulär',
  "modalWelcome.collectSubscribers": 'Samla nya prenumeranter med stil.',
  "modalWelcome.createAppealingPopup": 'Skapa snygga popup formulär till din hemsida. Show the form on page load or make it stick to the bottom of your site.',
  "modalWelcome.newBlockedtor": 'Ny Blockeditor',
  "modalWelcome.createStunningNewsletters": 'Skapa fantastiska nyhetsbrev.',
  "modalWelcome.dozensOfNewFonts": 'Massor av nya typsnitt. Bakgrundsbilder. Smidiga mallar. Ni önskade. Vi lyssnade. Fast er önskelista var lång tror vi oss har lyckats med några riktigt bra Förbättringar...',
  "modalWelcome.betterWorkflow": 'Better workflow',
  "modalWelcome.majorImprovements": 'We have announced some major improvements, but we did not forget about the general worfklow of the tool.',

  //Upgrade Page
  "upgrade.upgradeAccount": 'Uppgradera konto',
  "upgrade.separateBillingAddress": 'Separat faktureringsadress',
  "upgrade.billingCompany": 'Faktureringsadress',
  "upgrade.youNeverPayMore": 'Du betalar aldrig mer än du behöver, antalet kontakter du har på ditt konto placerar dig på rätt prisnivå.',
  "upgrade.alwaysTheRightPrice": 'Alltid det rätta priset',
  "upgrade.noEntryFee": 'Avsluta när du vill',
  "upgrade.freeSupport": 'Kostnadsfri support',
  "upgrade.yourInformation": 'Ange din information',
  "upgrade.accountOwner": 'Kontoägare',
  "upgrade.yourAddress": 'Din adress och företagets/organisationens/personliga information användas vid handläggning av fakturor och för att vi skall kunna hålla koll på vilka som använder vår tjänst.',
  "upgrade.invoiceSettings": 'Fakturainställningar',
  "upgrade.billingAddress": 'Faktureringsadress',
  "upgrade.payByCard": 'Betala med kort',
  "upgrade.payBuy": 'Betala med',
  "upgrade.getInstantAccess": 'Få tillgång till ditt uppgraderade konto direkt.',
  "upgrade.payByInvoice": 'Betala med faktura',
  "upgrade.getAccessToYourUpgradedAccount": 'Få tillgång till ditt uppgraderade konto 3-5 dagar efter att betalningen gjorts.',
  "upgrade.sek120": '120 SEK/Månad',
  "upgrade.sek150": '150 SEK/Månad inklusive moms',
  "upgrade.youWillBeCharged": 'Du kommer debiteras idag för kommande månad. Därefter löpande, den {day}:a varje månad, tills du väljer att avsluta ditt abonnemang.',
  "upgrade.payYearly": `Betala per år och spara 20%.`,
  "upgrade.reviewYourInformation": 'Läs igenom informationen innan du slutför betalningen. Alla dina uppgifter är skyddade hos oss och du kan avsluta prenumerationen när du vill.',
  "upgrade.orgNr": 'Org.nr: ',
  "upgrade.byPlacing": 'Genom att lägga en beställning godkänner jag Get a Newsletters',
  "upgrade.termsOfUse": ' användarvillkor ',
  "upgrade.myCardWillBeCharged": ' och att mitt kort kommer att debiteras varje månad tills jag väljer att avsluta prenumerationen.',
  "upgrade.showingMonthlyPrices": 'Priserna avser månadsabonnemang exklusive moms.',
  "upgrade.switchToYearlyAndSave": 'Byt till årsabonnang och spara 20%. Betalning med faktura är endast tillgängligt vid val av årsabonnemang.',
  "upgrade.myCurrentSubscription": 'Mitt nuvarande abonnemang',
  "upgrade.upgradeSubscription": 'Uppgradera abonnemang',
  "upgrade.howManyContacts": 'Hur många kontakter har du?',
  "upgrade.payMonthly": 'Hur vill du betala?',
  "upgrade.chooseCurrency": 'Välj valuta',
  "upgrade.choosePeriod": 'Välj period',
  "upgrade.startUpgradeNow": 'Starta uppgradering nu',
  "upgrade.upgradeNow": 'Uppgradera nu',
  "upgrade.upgradeLater": 'Uppgradera senare',
  "upgrade.startUpgradeLater": 'Starta uppgradering senare',
  "upgrade.startTheUpgradeRightNowAndPay": 'Starta uppgradering med en gång och betala fullt pris för nuvarande period.',
  "upgrade.startTheUpgradeAtTheBeginning": 'Starta uppgradering från och med kommande period. Du kommer inte kunna skicka några extra mail under nuvarande period.',
  "upgrade.currentSubscription": 'Nuvarande abonnemang',
  "upgrade.discount": `Avdrag`,
  "upgrade.totalDiscount": `Total avdrag`,

  //Start Autoresponder Mail
  "autoresponderMail.start": 'Starta automatiskt utskick',

  //Support Page
  "supportPage.theBestThing": 'Det bästa med er är att ni ständigt utvecklar verktyget så att det är lätt att göra nyhetsbreven. Man behöver inte ha ”ångest” utan kan enkelt och snabbt slutföra. 5 min senare är det klart och tiden kan istället utnyttjas till att utveckla företaget.',
  "supportPage.weHaveUsedGan": 'Vi har använt GAN till våra nyhetsbrev i 7 år och är mycket nöjda! Lätt att använda och aldrig några problem. Utan tvekan vår mest prisvärda marknadsföringskanal.',
  "supportPage.takeAdvantage": 'Dra fördel av vår kunskap via handböcker, blogg, youtubekanal, inspirationsgalleri och vårt nyhetsbrev.',
  "supportPage.trustInUs": 'Varje dag förlitar sig 20 000 användare på oss',

  //Templates
  "templates.create": 'Skapa mall',
  "templates.blockEditor": 'Blockeditor mallar',
  "templates.advancedTemplates": 'Avancerad editor mallar',
  "templates.placeholder.title": 'Vilken typ av layout vill du skapa idag?',
  "templates.placeholder.description": 'En mall sparas som en färdig design som kan återanvändas när du skapar nya nyhetsbrev. Det är dags att vara kreativ och lägga grunden till det som skall bli ditt nyhetsbrev!',


  //Reports
  "reports.view": 'Visa rapport',
  "reports.previewMail": 'Förhandsgranska utskick',
  "reports.copyMail": 'Kopiera utskick',
  "reports.deleteReport": 'Radera rapport',
  "reports.matching": 'Ja, jag vill radera det tillhörande utskicket',
  "reports.delete": 'Du håller på att radera den här rapporten: {name}',

  //Terms
  "terms.title": 'Användarvillkor',
  "terms.theseTerms": 'Dessa villkor (“Avtalet”) reglerar användningen av tjänsten (enligt nedan) och har ingåtts mellan Get a Newsletter Scandinavia AB (“Get a Newsletter”, "företaget" “vi”, “oss” eller “vår”), ett svenskt aktiebolag med registreringsnummer 556831-5765, i enlighet med svensk lag och du som användare av Tjänsten (“du” eller  “Användare”).',
  "terms.theFollowingWords": 'Följande ord ska, i detta avtal, ha den mening de tilldelats enligt nedan:',
  "terms.content": 'Innehåll, syftar till alla texter, grafik, videor, bilder och all annan information som användaren lagrar, publicerar eller skickar till andra via tjänsten ',
  "terms.originalContent": 'Ursprungligt innehåll, syftar till tjänstens ursprungliga innehåll, såsom kod, texter, grafik, videor, bilder och all annan information som tillhandahålls av Get a Newsletter',
  "terms.meaningThePerson": 'Prenumerant, avser den person som är mottagare av avsändarens nyhetsbrev',
  "terms.serviceMeaningAnyOfTheServices": 'Tjänst, avser någon av de tjänster som Get a Newsletter tillhandahåller, inklusive, men inte begränsat till, vårt onlineverktyg för att skicka nyhetsbrev',
  "terms.siteMeaningTheDomain": 'Webbplats, avser domänen eller subdomäner getanewsletter.com och alla andra domäner som ägs av Get a Newsletter',
  "terms.userAccount": 'Användarkonto, avser det konto som användaren använder för att få tillgång till tjänsten',
  "terms.scopeOfTheService": 'Tjänstens omfattning',
  "terms.theServiceIsAnOnlineTool": 'Tjänsten tillhandahåller ett verktyg för att skapa och skicka nyhetsbrev via e-post. Tjänsten består av tre delar, genom vilka du kan; (i) samla kontakter via webbplatser, eller lägga till befintliga kontakter som du kan organisera i olika listor för anpassade utskick, (ii) skapa dina eget utformade nyhetsbrev och (iii) se resultat och statistik över dina prenumeranters engagemang.',
  "terms.theUserShallUseTheSite": 'Användaren ska använda sajten och tjänsten i enlighet med avtalet, instruktioner från Get a Newsletter, tillämpad lag, regler och föreskrifter.',
  "terms.userAccounts": 'Användarkonton',
  "terms.inOrderToUse": 'För att kunna använda Tjänsten, måste du registrera ett Användarkonto. Get a Newsletter förbehåller sig rätten att neka dig åtkomst till Tjänsten.',
  "terms.youMustAtAllTimes": 'Du måste alltid hålla dina kontouppgifter aktuella, korrekta och fullständiga.',
  "terms.theUserAccountMayOnlyBeUsed": 'Användarkontot får endast användas av kontoägaren och dess företrädare. Kontoägaren är ensam ansvarig för all tillgång till användarkontot alla aktiviteter som utförs där. Användaren förbinder sig att behandla inloggningsuppgifter och annan information som rör kontot på ett säkert sätt, liksom att omedelbart informera Get a Newsletter om obehörig användning av Användarkontot.',
  "terms.theUserAccountHasASubscription": 'Användarkontot innefattar en prenumeration som inkluderar ett särskilt antal kontakter och / eller mail(“Kapacitet”) per period. Kapaciteten återställs på ett visst datum varje månad och eventuellt outnyttjad kapacitet kommer inte överföras till efterföljande period.',
  "terms.theServiceIncludes": 'Tjänsten innefattar funktioner för att ladda upp, skicka, länka, kommunicera och på andra sätt tillgängliggöra information, inklusive men inte begränsad av, text, grafik, videor, bilder och annat material. Du är alltid ansvarig för det innehåll som laddas upp och / eller skapas på ditt Användarkonto.',
  "terms.byUploadingContent": 'Genom att ladda upp innehåll till tjänsten, garanterar du att innehåller inte strider mot nationell eller internationell lagstiftning och att du (i) är ägare till det innehåll som laddats upp, eller (ii) har en giltig licens eller annat tillstånd från lämplig rättighetsinnehavare för att använda innehållet.',
  "terms.youHaveTheResponsibility": 'Du är ansvarig för att alla uppgifter som lämnas under användning av Tjänsten alltid är korrekta, fullständiga och uppdaterade.',
  "terms.paymentAndFees": 'Betalning och avgifter',
  "terms.thePriceForTheService": 'Priset för Tjänsten baseras på det antal kontakter du har på ditt konto eller antalet mail reserverade per månad. Nuvarande prislista finns tillgänglig på tjänstens webbsida och visas exklusive moms. Get a Newsletter förbehåller sig rätten att ändra prislistan för Tjänsten genom att publicera en ny prislista på webbplatsen, och / eller skicka ett meddelande via mejl till Användaren.',
  "terms.feesForTheService": 'Avgifter för Tjänsten betalas i förskott per månad eller år. Efter utgången avtalsperiod, förlängs avtalet automatiskt med samma period som Användaren tidigare valt.',
  "terms.theUserWarrants": 'Användaren garanterar att denne har rätt att göra en beställning samt lämna fakturainformation och /eller använda det inlagda kreditkortet.',
  "terms.theUserIsObligated": 'Användaren är skyldig att alltid tillhandahålla Get a Newsletter korrekt fakturainformation och / eller giltig kreditkortsinformation och godkänner att vi regelbundet debiterar den månatliga och / eller årliga avgiften. Användaren är även skyldig att ha ett fungerande kreditkort med medel tillgängliga vid tid för dragning samt att fakturor betalas i tid.',
  "terms.interestOnOverduePayment": 'Ränta på försenad betalning ska uppgå enligt Räntelag (1975: 635) från den dag betalning skulle ha skett. Get a Newsletter förbehåller sig även rätten att stänga av användarens tillgång till Tjänsten tills betalning skett.',
  "terms.affiliateAndPartner": 'Affiliates och partners',
  "terms.getANewsletterOffers": 'Get a Newsletter ger användaren möjlighet att annonsera, länka till och marknadsföra Tjänsten till tredje part. Användaren har rätt till ersättning för varje tredje part som blir användare av Tjänsten efter att ha klickat på användarens unika länk (nedan kallad Ersättningen).',
  "terms.theSizeOfTheRemuneration": 'Ersättningens storlek bestäms i enlighet med Get a Newsletters, vid varje tillfälle, gällande ersättningspolicy som finns tillgänglig i tjänsten. Get a Newsletter förbehåller sig rätten att när som helst ändra ersättningspolicyn. Alla ändringar av nämnda policy kommer att publiceras i tjänsten.',
  "terms.theRemunerationIsTransferred": 'Ersättningen överförs till Användaren på begäran och betalas ut maximalt en gång i månaden och endast om Ersättningen överstiger 500 kr vid utbetalningstillfället. Ersättning som inte överförs till Användaren kommer att överföras till följande månad.',
  "terms.theRemunerationIsOnlyTransferred": 'Ersättningen överförs endast till svenska företag som har ett giltigt F-skattebevis. Observera att användaren är ensam ansvarig för betalning av skatter och andra avgifter. Get a Newsletter förbehåller sig rätten att göra avdrag från ersättningen för att täcka eventuella avgifter vid administration eller betalning av ersättningen.',
  "terms.transferWillOnlyBeMade": 'Överföring sker endast till det bankkonto som är registrerat i användarkontot. Om inga bankkontouppgifter är registrerade i användarkontot sparas överföringen tills användaren lägger in korrekta bankuppgifter.',
  "terms.getANewsletterReserves": 'Get a Newsletter förbehåller sig rätten att när som helst dra tillbaka rätten till ersättning och utesluta användaren från möjligheten att samarbeta med Get a Newsletter. Detta kan vara fallet, men är inte begränsat till, om användaren bryter mot avtalet, om Get a Newsletter anser att trafiken genererad av användaren inte är kvalitativ eller om trafiken genereras på ett otillbörligt sätt.',
  "terms.theUserAcknowledges": 'Användaren är medveten om och godkänner att:',
  "terms.trafficToTheService": 'All trafik till tjänsten som genereras av användaren måste göras genom en aktiv åtgärd, vilket innebär att personen som följer användarens annons, länk eller rekommendation måste vara medveten om konsekvenserna av att följa sådan annons, länk eller rekommendation.',
  "terms.thatHasBeenObtained": 'Ersättning som erhållits av Användaren måste återbetalas till Get a Newsletter om de fakturor som ersättningen baseras på makuleras eller krediteras.',
  "terms.collectionOfData": 'All insamling av data som ersättningen baseras på hanteras av Get a Newsletter eller tredje part anlitad av Get a Newsletter. Get a Newsletter använder bland annat cookies, i enlighet med vår Integritetspolicy, för att spåra aktivitet som Ersättningen baseras på.',
  "terms.theAgreementShallBeInForce": 'Avtalet ska gälla så länge som Användaren har ett registrerat Användarkonto. Om användaren vill säga upp Avtalet måste Användaren avsluta Användarkontot.',
  "terms.ifTheUserTerminates": 'Om användaren avslutar kontot sker ingen återbetalning av redan erlagda avgifter, kontot raderas och användaren har inte längre tillgång till tjänsten.',
  "terms.thisAgreementIsConsidered": 'Avtalet anses vara avslutat när användarkontot har raderats.',
  "terms.getANewsletterReservesTheRight": 'Get a Newsletter förbehåller sig rätten att stänga av eller avsluta användarens konto omedelbart om betalning inte görs eller om användaren bryter mot avtalet, våra instruktioner eller gällande nationell eller internationell lag, regler eller förordningar. Vid avtalsbrott sker ingen återbetalning av redan erlagda avgifter.',
  "terms.terminationOfThisAgreement": 'Uppsägning av detta avtal frigör inte användaren från ansvar under den tid avtalet var i kraft.',
  "terms.getANewsletterMaintains": 'Get a Newsletter förbehåller sig rätten att använda användarens information, inklusive innehåll och information om abonnenter, på ett anonymiserat, konsoliderat och aggregerat sätt för att förbättra, utveckla och modifiera tjänsten och för att sammanställa statistik och annan marknadsföringsinformation.',
  "terms.allInformationThatIsStored": 'All information som användaren lagrar i Tjänsten ägs av användaren, är konfidentiell och används endast av användaren. Get a Newsletter förbehåller sig rätten att använda informationen för att verifiera att avtalet följs. Under inga omständigheter kommer Get a Newsletter att sälja eller på annat sätt ge tredje part tillgång till användarens information om så inte krävs enligt lag.',
  "terms.inEventOfTerminationOfTheAgreement": 'Vid uppsägning av avtalet kommer användarens filer, inställningar och e-postmeddelanden att raderas inom nittio (90) dagar från uppsägningen. Get a Newsletter kommer efter avtalets upphörande endast att lagra sådan information som följer av detta avtal, krävs enligt lag eller som krävs för att administrera eventuella återstående fakturor.',
  "terms.getANewsletterWillAlsoAfterTheTermination": 'Get a Newsletter kommer också efter avtalets upphörande att lagra information om användaren för konventionell CRM-användning, inklusive marknadsföring, för statistiska ändamål och för att förbättra tjänsten.',
  "terms.getANewsletterWillProcessPersonalData": 'Get a Newsletter behandlar personuppgifter om Prenumeranter på uppdrag av Användaren. Därför anses Användaren vara Personuppgiftsansvarigs medan Get a Newsletter är användarens Personuppgiftsbiträdet. Användarens integritetspolicy eller instruktioner är därför tillämpliga för behandling av personuppgifter som utförs av Get a Newsletter på uppdrag av användaren, i den utsträckning sådan policy och instruktioner har blivit tillkännagivna och godkända av Get a Newsletter.',
  "terms.intellectualProperty": 'Immateriella rättigheter',
  "terms.theServiceAndItsOriginalContent": 'Tjänsten och dess ursprungliga innehåll, funktioner, funktionalitet och designelement är och kommer att förbli Get a Newsletters, eller dess leverantörers, exklusiva egendom. Användarens användning av sajten och tjänsten är begränsad till rätten som beviljats användaren enligt detta avtal. Get a Newsletters immateriella rättigheter får inte användas i samband med någon produkt eller tjänst utan Get a Newsletters skriftliga samtycke.',
  "terms.theSite": 'Webbplatsen, Tjänsten eller någon del därav får inte reproduceras, dupliceras, kopieras, säljas, vidareförsäljas, besökas eller på annat sätt utnyttjas för något syfte som inte överensstämmer med de begränsade rättigheter som beviljats Användaren enligt avtalet.',
  "terms.changesOfTheService": 'Ändringar av tjänsten och underhåll',
  "terms.getANewsletterReservesToModify": 'Get a Newsletter förbehåller sig rätten att när som helst förändra och/eller helt eller delvis stänga ner hela eller delar av tjänsten utan förvarning. All ny funktionalitet och tilläggstjänster omfattas av avtalet.',
  "terms.weWillMakeReasonableEfforts": 'Vi kommer att göra vårt bästa för att hålla tjänsten operativ och fullt fungerande under uppdateringar. Om ändringar eller systemunderhåll är av betydande natur och kräver omfattande nertid, kommer Get a Newsletter att meddela användaren.',
  "terms.getANewsletterReservesTheRightToShutDown": 'Get a Newsletter förbehåller sig rätten att stänga av tjänsten för uppdatering och / eller av säkerhetsskäl.',
  "terms.limitationOfLiability": 'Ansvarsbegränsning',
  "terms.allUseOfTheService": 'All användning av tjänsten sker på egen risk och tillhandahålls "som den är", utan garantier av något slag. Vilket bland annat innebär att Get a Newsletter inte tar något ansvar för eventuellt förlorad data, den respons användaren får på sina utskick eller att alla utskick kommer fram till mottagaren. Get a Newsletter kan inte heller garantera att tjänsten är helt fri från störningar eller fel.',
  "terms.getANewsletterIsNotResponsible": 'Get a Newsletter ansvarar inte för skada eller förlust som uppstår till följd av hacking, manipulering eller annan obehörig åtkomst, eller användning av Tjänsten eller användarens konto.',
  "terms.getANewslettersLiability": 'Get a Newsletters ansvar enligt avtalet ska alltid vara begränsat till ett totalt belopp motsvarande de avgifter som användaren betalat under de senaste tolv (12) månaderna.',
  "terms.prohibitedUseOfService": 'Förbjuden användning av tjänsten',
  "terms.theUserAgreesToUseTheService": 'Användaren får endast använda tjänsten för lagliga och etiska ändamål och förbinder sig att inte använda Tjänsten för att skicka, sända eller på annat sätt kommunicera information som kan vara; förolämpande, kränkande, rasistisk, terroristisk, oanständig eller olaglig. Det är heller inte tillåtet att använda tjänsten för affiliatemarknadsföring eller destruktiva aktiviteter såsom spridning av virus, spam eller andra aktiviteter som kan skada Get a Newsletter, tjänsten eller mottagarna på något sätt.',
  "terms.youAreNotAllowedToSell": 'Du har inte rätt att sälja, licensera, ändra, distribuera, kopiera, reproducera, dekompilera Tjänsten eller skapa kopior av Tjänsten.',
  "terms.theServiceMayNeverBeUsed": 'Tjänsten får aldrig användas för att skicka oönskade e-postmeddelanden (s k "spam") och utskick får under inga omständigheter göras till prenumeranter som inte har godkänt sin prenumeration.',
  "terms.changesOfTheAgreement": 'Ändringar av avtalet',
  "terms.getANewsletterMay": 'Get a Newsletter äger rätten att ändra villkoren för tjänsten genom att publicera en uppdaterad version av dem på hemsidan. Det ändrade avtalet träder automatiskt i kraft vid publicering.',
  "terms.governingLaw": 'Styrande lagar, tolkning och tvistlösning',
  "terms.theAgreementShall": 'Avtalet ska tolkas i enlighet med och regleras av svensk lag.',
  "terms.anyDisputeArising ": 'Eventuell tvist som härrör från eller relaterar till detta avtal skall avgöras i Svensk domstol varvid Stockholms tingsrätt är första instans.',
  "terms.theAgreementIsTranslated": 'Avtalet finns översatt till flera språk, om avtalet kan tolkas olika på grund av språkliga skillnader har det svenska avtalet företräde.',
  "terms.dataProcessingAgreement": 'Personuppgiftsbiträdesavtal',
  "terms.addendumToTheCurrentTerms": 'Tillägg till de nuvarande användarvillkoren mellan dig ("Personuppgiftsansvarig") och Get a Newsletter Scandinavia AB ("Personuppgiftsbiträde") angående behandling av den Personuppgiftsansvariges personuppgifter. Detta Personuppgiftsbiträdesavtal kallas hädanefter "Biträdesavtal". I de fall det finns motsägelser mellan Användarvillkoren och Biträdesavtalet har användarvillkoren företräde.',
  "terms.theProcessorUndertakesToProcess": 'Personuppgiftsbiträde åtar sig att behandla personuppgifter i enlighet med användarvillkoren, Personuppgiftsansvarigs instruktioner samt Personuppgiftslagen (1998:204) och personuppgiftsförordningen (1998:1191). Den 25 maj 2018 ersätts dessa lagar och förordningar av den allmänna dataskyddsförordning (EU) 2016/679 ("GDPR") som från och med detta datum gäller istället.',
  "terms.itMayBeThatTheUser": 'Det kan hända att användaren av tjänsten är ett Personuppgiftsbiträde. I sådana fall gäller denna överenskommelse som ett underbehandlingsavtal där användaren betraktas som Personuppgiftsbiträde och Get a Newsletter är Underbiträde. I sådana fall gäller vad som anges angående Personuppgiftsansvarig i detta avtal för Personuppgiftsbiträdet och vad som anges angående Personuppgiftsbiträdet ska gälla för Underbiträdet.',
  "terms.instructionsFrom": 'Instruktioner och kontroller av Personuppgiftsansvarig',
  "terms.theProcessorAndAnyPerson": 'Personuppgiftsbiträdet och personer anlitade av denne, får endast behandla personuppgifter i enlighet med användarvillkoren eller sådana instruktioner som givits av Personuppgiftsansvarig. Om Personuppgiftsbiträdet anser att instruktionerna är otillräckliga för att detta avtal ska kunna fullföljas, ska Personuppgiftsbiträdet utan dröjsmål informera Personuppgiftsansvarig om detta och avvakta ytterligare instruktioner från Personuppgiftsansvarig.',
  "terms.theControllerShallHaveTheRight": 'Personuppgiftsansvarig har rätt att, på egen bekostnad, kontrollera att Personuppgiftsbiträdet följer villkoren. Personuppgiftsbiträdet är skyldig att ge det stöd som krävs för att utföra en sådan kontroll. Om Personuppgiftsansvarig hittar allvarliga brister eller överträdelser har Personuppgiftsansvarige rätt att säga upp detta avtalet med omedelbar verkan.',
  "terms.newFeaturesAffecting": 'Nya funktioner som påverkar användarvillkoren',
  "terms.ifNewFeatures": 'Om nya funktioner i tjänsten leder till förändrad hantering av data eller införande av nya datatyper ska detta informeras om på tjänstens hemsida. Personuppgiftsansvarig kan inom två (2) veckor från dagen för offentliggörandet motsätta sig förändringarna och om rimliga skäl anförs har denne rätt att säga upp användaravtalet. Sådan uppsägning skall inte betraktas som ett avtalsbrott och därmed inte medföra några påföljder.',
  "terms.prohibitionAgainstTransfer": 'Förbud mot överföring till tredje land',
  "terms.theProcessorShallProcessPersonalData": 'Personuppgiftsbiträde ska behandla personuppgifter inom, och på anordningar som är fysiskt belägna inom, EU / EES eller ett sådant tredjeland som anses vara tillräckligt skyddade av Europeiska kommissionen.',
  "terms.requestsFromAndContacts": 'Begäran från och kontakter med myndigheter och registrerade',
  "terms.inCaseADataSubject": 'Om en prenumerant, Datainspektionen, Integritetsskydds-myndigheten eller någon tredje part begär information om behandling av personuppgifter från Personuppgiftsbiträde ska Personuppgiftsbiträde hänvisa begäran till Personuppgiftsansvarig. Personuppgiftsbiträde har inte rätt att avslöja personuppgifter eller information om behandling av personuppgifter om det inte krävs enligt lag eller uttryckligen instrueras av Personuppgiftsansvarig.',
  "terms.theProcessorShallWithoutDela": 'Personuppgiftsbiträde ska utan dröjsmål informera Personuppgiftsansvarig om eventuella förfrågningar eller andra kontakter med Datatillsynsmyndigheten, eller någon annan myndighet som berör eller kan påverka behandlingen av personuppgifter. Personuppgiftsbiträde har ingen rätt att företräda eller agera å Personuppgiftsansvarig-s vägnar  i förhållande till prenumeranten, Datainspektionen, Integritetsskydds-myndigheten, någon annan myndighet eller någon tredje part.',
  "terms.theProcessorShallAgainstReasonable": 'Personuppgiftsbiträde ska, mot rimlig ersättning, bistå Personuppgiftsansvarig med att presentera information som har begärts av Datainspektionen, Integritetsskydds-myndigheten, annan myndighet eller den registrerade.',
  "terms.theProcessorShallTakeAppropriate": 'Personuppgiftsbiträde ska vidta lämpliga tekniska och organisatoriska åtgärder för att skydda personuppgifterna från obehörig tillgång, förstörelse, förlust eller ändring. Åtgärderna ska vara lämpliga med avseende på (a) tillgänglig teknologi, (b) kostnader, (c) särskilda risker som är förknippade med behandlingen och (d) personuppgifternas känslighet.',
  "terms.theProcessorShallForThisPurpose": 'Personuppgiftsbiträdet ska i detta syfte överensstämma med Datainspektionens instruktioner, i synnerhet Datainspektionens allmänna råd om säkerhet för personuppgifter.',
  "terms.theProcessorWarrantsThatAll": 'Personuppgiftsbiträde garanterar att alla som har tillgång till personuppgifter är bundna av sekretess. För att undvika tvekan ska sådan konfidentialitet gälla även i kontakter med myndigheter och registrerade.',
  "terms.theProcessorShallHaveTheRightToUseSubcontractors": 'Personuppgiftsbiträdet ska ha rätt att använda underleverantörer för behandling av personuppgifter ("Underbiträde"), under förutsättning att Underbiträden är bundna av åtminstone samma åtaganden och skyldigheter gentemot Personuppgiftsansvarig som Personuppgiftsbiträdet i enlighet med detta Avtal. Personuppgiftsbiträdet är helt ansvarig gentemot Personuppgiftsansvarig för Underbiträdets handlingar och hantering av personuppgifter.',
  "terms.erasureAndReturning": 'Radering och återlämnande av personuppgifter',
  "terms.theProcessorAndAnyOfItsSub": 'Personuppgiftsbiträde och dess Underbiträden ska, i enlighet med Personuppgiftsansvarigs beslut om radering av personuppgifter, helt eller delvis radera sådana personuppgifter från vilket medium de lagras, så att personuppgifterna inte kan återskapas eller se till att de är anonymiserade på ett sådant sätt att det inte går att koppla till en individ. Raderingen eller anonymiseringen ska vara genomförd inom nittio (90) dagar efter Personuppgiftsansvarig beslut om radering av personuppgifter.',
  "terms.theControllerAndProcessorAgree": 'Personuppgiftsansvarig och Personuppgiftsbiträde är överens om att Personuppgiftsbiträdet och alla Underbiträden ska, efter avslutad bearbetning, antingen returnera alla överförda personuppgifter, inklusive kopior, till Personuppgiftsansvarig eller radera dem i enlighet med ovanstående stycke.',
  "terms.uponRequest": 'På begäran ska Personuppgiftsbiträdet intyga skriftligen (e-post eller skriftligt meddelande i tjänsten) att sådan retur och/eller radering eller anonymisering har slutförts eller, i förekommande fall, tidsramen inom vilken sådan åtgärd kommer att utföras.',
  "terms.theControllerIsResponsible": 'Personuppgiftsansvarige ansvarar för att behandling sker i enlighet med den för varje tidpunkt gällande Lagstiftning.',
  "terms.theProcessorShallBeLiable": 'Personuppgiftsbiträdet ska ansvara för eventuella skador som orsakats Personuppgiftsansvarig, om behandling av personuppgifter skett i strid med Personuppgiftsansvariges instruktioner, detta avtal eller Användarvillkoren. Personuppgiftsbiträdets ansvar ska emellertid omfattas av samma begränsningar som anges i Användarvillkoren.',
  "terms.termAndTermination": 'Villkor och uppsägning',
  "terms.thisAgreementShallRemain": 'Detta avtal ska gälla under den tid Personuppgiftsbiträdet behandlar personuppgifter för Personuppgiftsansvarig.',
  "terms.thePartiesAgree": 'Parterna är införstådda med och godkänner att om Lagstiftningen eller tillämpade myndighetsanvisningar avsevärt förändras, ska villkoren i detta Biträdesavtal justeras så att de i största möjliga utsträckning motsvarar de principer Parterna ursprungligen avsåg när detta Biträdesavtal ingicks.',
  "terms.disputeAndApplicable": 'Tvister och tillämpliga lagar',
  "terms.theAgreementShallBeConstrued": 'Avtalet ska tolkas i enlighet med och regleras av svensk lag.',
  "terms.anyDisputeArising": 'Eventuell tvist som härrör från eller relaterar till detta avtal skall avgöras i Svensk domstol varvid Stockholms tingsrätt är första instans.',
  "terms.contentTitle": 'Innehåll',
  "terms.durationAndTermination": 'Avtalstid och uppsägning',
  "terms.useOfData": 'Användning av data',
  "terms.personalData": 'Personlig information',
  "terms.generalTitle": 'Allmänt',
  "terms.subProcessors": 'Underbiträden',
  "terms.liability": 'Ansvar',
  "terms.byAcceptingTheAgreement": 'Genom att godkänna Avtalet,',
  "terms.theDataProcessingAgreement": 'Personuppgiftsbiträdesavtalet',
  "terms.alsoBecomesBinding": 'also becomes binding between the parties.',
  "terms.furtherInformation": 'Ytterligare information om Get a Newsletters behandling av personuppgifter hittar du under',
  "terms.thePrivacyPolicy": 'Integritetspolicyn.',

  //Privacy page
  "privacy.privacyPolicy": 'Sekretesspolicy',
  "privacy.thisPrivacyPolicy": 'Denna sekretesspolicy (" Sekretesspolicy ") förklarar hur vi på Get a Newsletter Scandinavia AB, organisationsnummer 556831-5765, samlar in, använder och skyddar dina uppgifter.',
  "privacy.theFollowingWords": 'Följande ord ska i den här sekretesspolicyn ha den mening som anges nedan:',
  "privacy.refersToTheCompany": '"Get a Newsletter", "vår", " oss " eller "vi" avser företaget Get a Newsletter Scandinavia AB;',
  "privacy.refersToAnyOfTheServices": '"Tjänsten" avser någon av de tjänster som Get a Newsletter tillhandahåller, inklusive men inte begränsat till vårt onlineverktyg för att skicka nyhetsbrev;',
  "privacy.refersToTheDomain": 'Webbplatsen" avser domänen eller underdomänerna på getanewsletter.com samt alla andra domäner som ägs av Get a Newsletter;',
  "privacy.refersToYou": '"Användaren" syftar till dig som användare av tjänsten.',
  "privacy.refersToTheRecipients": '"Användare" avser dig som användare av Tjänsten;',
  "privacy.refersToTheList": '“Prenumerationslista" avser listan över prenumeranter och all tillhörande information som är relaterad till dem, inklusive men inte begränsat till e-postadresser;',
  "privacy.refersToYouAsEither": '“Du" hänvisar till dig som antingen en användare, en besökare på vår webbplats eller någon annan person som kommunicerar med oss.',
  "privacy.thePurpose": 'Syftet med denna sekretess policy',
  "privacy.thisPrivacyPolicyIsDesigned": 'Denna sekretesspolicy är utformad för att hjälpa dig att förstå hur Get a Newsletter samlar in och använder dina personuppgifter. Genom att använda eller besöka sajten eller tjänsten godkänner du att vi hanterar dina personuppgifter enligt beskrivningen i denna sekretesspolicy.',
  "privacy.informationToTheSubscribers": 'Information till prenumeranter (Mottagare av nyhetsbrev)',
  "privacy.allNewsletters": 'Alla nyhetsbrev som skickas via Get a Newsletter skickas av våra användare. Om du är en prenumerant och inte längre vill ha nyhetsbrev från någon av våra Användare, vänligen avsluta prenumerationen direkt från Användarens nyhetsbrev eller kontakta avsändaren direkt för att uppdatera eller radera dina uppgifter.',
  "privacy.personalData": 'Personlig information',
  "privacy.withPersonalData": 'Med "Personuppgifter" menar vi all information genom vilken du direkt eller indirekt kan identifieras. Till exempel: ditt namn, din adress, ditt personnummer, din IP-adress, dina inloggningsuppgifter, din e-postadress och eventuella andra uppgifter som du kan identifiera genom.',
  "privacy.dataCollectedFor": 'Data som samlas in åt Användaren',
  "privacy.whenYouAsAUser": 'När du som användare använder vår tjänst kan du importera personuppgifter som du har samlat in från dina prenumeranter till vårt system. Var medveten om att vi inte har några direkta relationer med dina prenumeranter eller någon annan än dig. Du som användare är juridiskt ansvarig för insamling och behandling av prenumeranternas personuppgifter.',
  "privacy.theUserIsTherefore": 'Användaren är därför Personuppgiftsansvarig för personuppgifterna som kan hänföras i enlighet med svensk och EU-lagstiftning, medan vi är Personuppgiftsbiträdet. Det innebär att användaren bestämmer processens syfte och sätt och ansvarar för sin egen och vår behandling av personuppgifter.',
  "privacy.whenYouAdd": 'När du lägger in information på ditt användarkonto eller skapar ett nyhetsbrev med Tjänsten, har vi tillgång till och kan komma åt informationen för att tillhandahålla Tjänsten och processa informationen enligt instruktioner.',
  "privacy.getANewsletterAcknowledges": 'Get a Newsletter bekräftar och godkänner att Prenumerationslistan ägs av Användaren. Vi kommer inte att sälja eller hävda äganderätt till dina Prenumerationslistor.',
  "privacy.getANewsletterUndertakes": 'Get a Newsletter åtar sig att vidta lämpliga åtgärder för att säkerställa att Prenumerationslistorna är lagrade säkert.',
  "privacy.dataCollectedBy": 'Uppgifter som samlats in av och för Get a Newsletter',
  "privacy.getANewsletterCollect": 'Get a Newsletter samlar in information som webbläsare och servrar gör tillgänglig, till exempel din webbläsartyp, språkinställningar, IP, referenswebbplats samt datum och tid för varje sidvisning. Vi samlar in statistik för tjänsten och webbplatsen.Vårt syfte med att samla in sådan information är att bättre förstå hur våra besökare använder Tjänsten och Webbplatsen samt för marknadsföring och reklamändamål.',
  "privacy.weReceiveInformation": 'Vi tar emot information som du frivilligt ger oss när du registrerar dig och använder tjänsten, skickar oss e-postmeddelanden eller kontaktar oss på något sätt. Den informationen kan innehålla personuppgifter som namn, e-postadress, telefonnummer, kreditkortsinformation, företagsinformation, fysisk adress och annan information som är kopplad till dig som användaren. Genom att ge oss den här informationen anses du vara informerad om vilka personuppgifter vi behandlar om dig. Vi bearbetar sådan information för att tillhandahålla tjänsten (syfte att utföra ett kontrakt) och för att hålla dig informerad om uppdateringar i Tjänsten, för konventionell CRM-användning, inklusive marknadsföring, för statistiska ändamål och för att förbättra vår Service (legitimt intresse). Om du informerar oss om att du inte vill att dina personuppgifter ska användas på ett visst sätt, var snäll och kontakta oss så kommer vi att försöka tillgodose din begäran utan att hindra din tillgång till Tjänsten. Observera att viss bearbetning, till exempel fakturering, måste behandlas enligt lagkravet.',
  "privacy.getANewsletterPersonal": 'Vi, Get a Newsletter, är Personuppgiftsansvariga för behandlingen enligt denna paragraf (Uppgifter som samlats in av och för Get a Newsletter).',
  "privacy.weAlsoCollectYouIPNumber": 'Vi samlar också in ditt IP-nummer och använder cookies (se avsnitt 10 nedan) för att förhindra missbruk av tjänst eller webbplats, för att bättre förstå användningen av tjänsten och webbplatsen och för att kunna erbjuda bästa möjliga användarupplevelse.',
  "privacy.rightToInformation": 'Rätt till information och rättelse etc.',
  "privacy.youMayRequest": 'Du kan begära information om vilken Personlig data Get a Newsletter har om dig. För att göra en sådan förfrågan, skicka ett mail till adressen nedan (punkt Kontakt). Observera att Get a Newsletter kan ta ut en rimlig avgift, som täcker administrativa kostnader, för att uppfylla sådana förfrågningar om begäran anses vara orimliga.',
  "privacy.youHaveTheRight": 'Du har rätt att kräva att felaktiga personuppgifter korrigeras.',
  "privacy.underCertainCircumstances": 'Under vissa omständigheter kan du ha rätt till dataöverföring.',
  "privacy.ifYouAreUncertain": 'Om du är osäker på hur vi hanterar dina personuppgifter, inte håller med om hur vi hanterar dina personuppgifter eller om du annars har frågor. Skicka oss ett mejl till adressen nedan (punkt Kontakt) . Du har också rätt att lämna ett klagomål till den behöriga myndigheten (för kontaktuppgifter, se www.integritetsskyddsmyndigheten.se).',
  "privacy.thirdParties": 'Tredjeparter',
  "privacy.getANewsletter": 'Get a Newsletter kommer inte att sälja eller på annat sätt dela med sig av dina personuppgifter till någon tredje part eller använda din e-postadress för oönskade e-postmeddelanden.',
  "privacy.howeverGetANewsletter": 'Men Get a Newsletter kan dela med sig av dina personuppgifter med våra underleverantörer för att underlätta, tillhandahålla och utföra tjänsten eller analysera hur tjänsten används. I så fall är vi ansvariga för våra underleverantörers behandling av dina personuppgifter. Våra underleverantörer har endast tillgång till dina personuppgifter för att utföra dessa uppgifter å våra vägnar och är skyldiga att inte avslöja eller använda dina personuppgifter för något annat syfte.',
  "privacy.weWillTakeAllSteps": 'Vi kommer att vidta alla nödvändiga åtgärder för att se till att dina personuppgifter behandlas säkert och i enlighet med denna sekretesspolicy. Alla personuppgifter som lämnas till oss lagras på säkra servrar. Tyvärr är överföringen av information via internet inte helt säker. Trots att vi gör vårt bästa för att skydda dina personuppgifter, kan vi inte garantera säkerheten för de personuppgifter som skickas via vår webbplats. Varje överföring sker på egen risk. När vi väl har fått dina personuppgifter använder vi strikta rutiner och säkerhetsfunktioner för att förhindra obehörig åtkomst.',
  "privacy.inTheEvent": 'I händelse av intrång, som påverkar antingen dig eller dina prenumeranter, kommer vi så snart som möjligt meddela dig om intrånget och de åtgärder som vi har vidtagit.',
  "privacy.cookies": 'Cookies',
  "privacy.getANewsletterUsesCookies": 'Get a Newsletter använder cookies på sajten för att förbättra vår service och skapa statistik över din användning av webbplatsen. Cookies är filer som sparas av din webbläsare på din enhet (t.ex. dator eller surfplatta). Dessa filer består av text och namnges efter de webbplatser du har besökt. Du kan välja att inte ta emot cookies i din webbläsare. Om du väljer att inte ta emot cookies kan dock funktionerna på webbplatsen påverkas negativt.',
  "privacy.changes": 'Ändringar av avtalet',
  "privacy.weMayChange": 'Denna sekretesspolicy kan ändras när som helst, vi kommer då att meddela sådana uppdateringar, inklusive men inte begränsat till, genom att publicera den senaste versionen av denna sekretesspolicy på webbplatsen. Det ändrade avtalet träder automatiskt i kraft vid publicering.',
  "privacy.contact": 'Kontakt',
  "privacy.ifYouHaveAnyQuestions": 'Om du har några frågor om vår sekretesspolicy, hur vi hanterar personuppgifter eller om du vill uppdatera, radera eller ändra personlig information vi har sparad, vänligen skicka ett meddelande via vårt',
  "privacy.contactForm": 'kontaktformulär',

  //Pause Account
  "pause.pausePageTitle" : `Pause account`,
  "pause.activatePageTitle" : `Activate account`,
  "pause.areYouSureYouWant" : 'Är du säker på att du vill pausa konto? ',
  "pause.ifYouConfirm" : `Ditt konto kommer att pausas när nuvarande period går ut och det kommer inte dras några pengar tills du väljer att återaktivera kontot.
  Under tiden kontot är pausat kan du göra allt, förutom att skicka utskick.
  Du kan när som helst återaktivera ditt konto.`,
  "pause.iConfirm" : ' Ja, jag vill pausa mitt konto.',
  "pause.pauseMyAccount" : ' Pausa ditt konto',
  "pause.willBePaused" : 'Ditt konto kommer att bli pausat: ',
  "pause.willBePausedDescription" : 'När nuvarande period slutar, {date}, kommer ditt konto bli pausat. När kontot är pausat kommer du inte kunna skicka några utskick. Du har fortfarande tillgång till kontot och kan närsomhelst återaktivera ditt konto.',
  "pause.reactivateFailed": "Du har försökt att aktivera ditt konto men din betalning misslyckades. Kontrollera din betalningsinformation och försök igen.",

   //Activate Account
   "activate.pageTitle" : 'Återaktivera ditt konto',
   "activate.description" : 'Ditt konto är för tillfället pausat. För att skicka ut ett utskick, återaktivera ditt konto genom att betala för nästkommande period',
   "activate.changeMind" : 'Ville du inte pausa konto? Inga problem – klicka på “Ångra paus av konto” för att återaktivera konto igen.',
   "activate.activateMyAccount" : 'Ångra paus av konto',

  //404 Page is not found!
  "page404.heading": ' Förlåt, men sidan finns inte längre. Försök att gå tillbaka till sidan du kom från, eller klicka på något av alternativen i menyn.',

  //Mails Pages
  "mails.sendOutATestMail": 'Skicka ett testmail',
  "mails.yourTestMail": 'Ditt testmail är på väg',
  "mails.weHaveNowSent": ' Grymt jobbat! Ditt test mail är nu skickat till dina mottagare på listan som du valde att skicka till.',
  "mails.CheckOutYourInbox": 'Gå till din inkorg och se hur ditt utskick kommer se ut, så att inget är missat när du skickar det till dina mottagare.',
  "mails.editMail": 'Redigera utkast',
  "mails.sendMailBtn": 'Skicka utkast',
  "mails.sendMail": 'Hur vill du skicka ut ditt nyhetsbrev?',
  "mails.ifYourTestMail": ' Ditt testmail är på väg till dina prenumeranter i den lista du valde. Kika i din inkorg och se till så att utkastet ser ut som förväntat innan du skickar iväg det som ett skarpt utskick.',
  "mails.wait30Minutes": 'Oroa dig inte om ditt testmail inte dyker upp direkt. Kika först i din skräpkorg eller ladda om ditt e-postprogram. Ibland kan det dröja lite innan mailet dyker upp. Kontakta oss på Get a Newsletter så kan vi kolla på det.',
  "mails.youAreOnlyAStepTestMail": 'Du är bara ett steg ifrån att skicka ut ett testutskick. Du kan skicka ett test till',
  "mails.chooseWhichList": `Välj den lista du vill koppla det automatiska utskicket till. Endast prenumeranter som skriver upp sig efter att det automatiska utskicket är skapat kommer att få utskicket.`,
  "mails.chooseWhichLists": 'Välj en eller flera listor som du vill skicka ditt nyhetsbrev till. Om en e-postadress finns med på flera listor får den bara ett utskick.',
  "mails.chooseNewSubscribersWhichList": `Eftersom du skickar till flera listor måste du välja vilken lista nya prenumeranter kommer att prenumerera på. Nya prenumeranter kan börja prenumerera på dina listor om du, eller en befintlig prenumerant, delar ditt nyhetsbrev.`,
  "mails.chooseSubject": 'Skriv ämnesrad och välj avsändare',
  "mails.label.chooseSubject": `Skriv ämne`,
  "mails.label.chooseSender": `Välj avsändare`,
  "mails.reviewBeforeSending": 'Granska dina val',
  "mails.takeASecondAndReview": 'Ta en stund och granska dina val innan du skickar ut ditt nyhetsbrev. Förhandsgranska nyhetsbrevet en sista gång och se till att du valt rätt lista.',
  "mails.receiver": ' 20 mottagare',
  "mails.forTestMails": '.',
  "mails.ifYouWouldLikeToSend": 'Om du vill skicka ut ett nyhetsbrev istället,',
  "mails.clickHere": 'Klicka här.',
  "mails.getYourMail": 'Testa att skicka ditt utskick till dig själv och ett par vänner.',
  "mails.sendOutANewsletter": 'Skicka nu',
  "mails.sendTheMail": 'Skicka ut ditt utkast direkt till en eller flera listor.',
  "mails.scheduleANewsletter": 'Skicka senare',
  "mails.scheduleTheMail": 'Schemalägg ditt utkast till en eller flera listor.',
  "mails.startAutoresponder": 'Skicka automatiskt',
  "mails.sendOutTheMail": 'Skicka ut ett nyhetsbrev vid varje ny registrering på din lista.',
  "mails.yourMailIsScheduled": 'Schemalägg ditt utskick.',
  "mails.youAreOnlyAStepScheduling": 'Du är bara några steg från att schemalägga ett utskick. Om du för säkerhets skull vill skicka ett testmail istället, klicka här.',
  "mails.yourNewsletterIsNowMover": 'Grymt jobbat! Nu är ditt utskick flyttat till schemalagt. Du hittar utkastet under “Schemalagda utskick”. Utskicket kommer att skickas ut:',
  "mails.toScheduledMails": 'Gå till dina schemalagda utskick',
  "mails.rescheduleNow": 'Planerna om',
  "mails.youAreOnlyAStepMail": 'Du är några steg från att schemalägga ditt utkast. Vill du för säkerhets skull skicka ett testmail istället, klicka här.',
  "mails.chooseSendDayAndTime": 'Välj datum & tid',
  "mails.chooseSendDay": `Dagar`,
  "mails.chooseSendHour": `Timmar`,
  "mails.yourAutoresponderHasNowStarted": 'Ditt automatiska utskick är nu igång nu',
  "mails.yourAutoresponderHasNowStartedDescription": `Ditt automatiska utskick är nu startat och kommer gå ut till nya prenumeranter när de prenumererar på ditt nyhetsbrev.`,
  "mails.chooseLists": 'Välj lista',
  "mails.chooseNewSubscribersList": `Hantera nya prenumeranter`,
  "mails.noteThatOnlyContacts": 'Ditt automatiska utskick kommer att skickas till nytillkomna prenumeranter.',
  "mails.afterTheSubscription": 'Efter att prenumerationen bekräftats.',
  "mails.sendingToList": 'Skickar till listan',
  "mails.createMail": 'Skapa utkast',
  "mails.createTemplate": 'Skapa mall',
  "mails.publicTemplates": 'Offentliga mallar',
  "mails.ownTemplates": 'Dina egna mallar',
  "mails.designedTemplates": 'Designmallar',
  "mails.standardTemplates": 'Standardmallar',
  "mails.advancedTemplates": 'Koda din egen',
  "mails.advancedEditor": 'Avancerad editor',
  "mails.addGoogle": 'Använd Google Analytics spårningsfunktion',
  "mails.trackMyNewsletter": 'Ja, spåra mitt nyhetsbrev via Google Analytics',
  "mails.googleAnalyticsCanHelp": 'Google Analytics hjälper dig spåra nyhetsbrevet och ger dig information om hur många läsare som kommer till din webbplats via ditt nyhetsbrev',
  "mails.googleAnalytics": 'Google analytics',
  "mails.sendWhen": `Välj när ditt nyhetsbrev ska skickas`,
  "mails.sendWhenDate": `Tid och datum`,
  "mails.yesTheNewsletterIsTracked": 'Nyhetsbrevet kommer att spåras via Google analytics',
  "mails.NoTheNewsletterIsNotTracked": 'Nyhetsbrevet spåras inte via Google analytics',
  "mails.doYouWantToScheduleYourMail": 'Skickas direkt. Vill du schemalägga nyhetsbrevet istället?',
  "mails.doYouWantToSendNowInstead": `Vill du skicka nyhetsbrev nu istället?`,
  "mails.youAreOnlyAStep": 'Du är några val från att skicka ut ditt nyhetsbrev. Vill du för säkerhets skull skicka ett testmail istället,',
  "mails.yourNewsletter": 'Ditt nyhetsbrev är på väg',
  "mails.scheduleBtn": `Schemalägg nyhetsbrev`,
  "mails.shareOnSocialMedia": 'Dela nyhetsbrevet',
  "mails.linkToShareOnline": 'Länk för att dela online',
  "mails.copyToClipboard": 'Kopiera URL',
  "mails.openInNewTab": 'Länk till webbversion',
  "mails.shareOnFacebook": 'Dela på Facebook',
  "mails.shareOnTwitter": 'Dela på Twitter',
  "mails.shareOnLinkedIn": 'Dela på Linkedin',
  "mails.weHaveMovedYourMail": 'Grymt jobbat! Du hittar ditt utskick under “Skickade mail”. Så fort en av mottagarna har öppnat utskicket skapas en rapport där du kan hålla koll på statistiken. Kolla in rapporten eller dela med dig av ditt nyhetsbrev genom att använda länkarna nedan.',
  "mails.stoppedMails": 'Stoppade utskick',
  "mails.pendingMails": 'Utskick under granskning',
  "mails.sentMails": 'Skickade mail',
  "mails.autoresponders": 'Automatiska utskick',
  "mails.overview": 'Översikt',
  "mails.scheduledMails": 'Schemalagda utskick',
  "mails.templates": 'Mallar',
  "mails.drafts.placeholder.title": 'Ditt utkast är början på ett nyhetsbrev',
  "mails.drafts.placeholder.description":'Du är bara steg från att göra ditt utskick - använd en av de designade mallarna eller skapa ditt mästerverk från grunden.',
  "mails.drafts.title": 'Utkast',
  "mails.drafts.add": 'Skapa utkast',
  "mails.drafts.edit": 'Redigera utkast',
  "mails.drafts.preview": 'Förhandsgranska utkast',
  "mails.drafts.copy": 'Kopiera utkast',
  "mails.drafts.going": 'Du kommer att ta bort följande utkast: {draft}',
  "mails.drafts.delete": 'Radera utkast',
  "mails.drafts.prepare": 'Sänd utkast',
  "mails.drafts.pendingReview.back": 'Tillbaka till utskick',
  "mails.drafts.pendingReview.title": 'Utskick under granskning',
  "mails.drafts.pendingReview.description": 'Första gången du skickar ett nyhetsbrev genomför vi en granskning för att säkerställa att innehållet följer våra villkor. Så fort granskningen är klar skickar vi mailet till dina prenumeranter.',
  "mails.scheduled.previewMail": "Förhandsgranska utskick",
  "mails.scheduled.copyMail": "Kopiera utskick",
  "mails.scheduled.stopMail": "Stoppa utskick",
  "mails.scheduled.replanMail": "Planera om ditt utskick",

  //Page Logged Out
  "loggedOut.loggedOut": 'Utloggad',
  "loggedOut.thanksForSpendingQualityTime": 'Tack för att du väljer att spendera lite tid med oss på Get a Newsletter.',
  "loggedOut.latestFromOurBlog": 'Senaste nytt från vår blogg',
  "loggedOut.needHelp": 'Funderar du på något?',
  "loggedOut.weRegularlyUpdateOurKnowledge": 'Vi uppdaterar regelbundet all information med relevanta artiklar och videoklipp som hjälper dig.',
  "loggedOut.yourVanityUrl": 'Your vanity URL',
  "loggedOut.orByBrowsing": 'Eller genom att bläddra i våra hjälpartiklar',
  "loggedOut.recommendUs": 'Rekommendera oss',
  "loggedOut.weReallyHope": 'Vi hoppas verkligen att du är nöjd med vårt verktyg och den service vi tillhandahåller och att du tipsar dina vänner om oss!.',
  "loggedOut.recommendUsOnFacebook": 'Rekommendera oss på Facebook',
  "loggedOut.recommendUsOnTwitter": 'Rekommendera oss på Twitter',
  "loggedOut.recommendUsOnGoogle": 'Rekommendera oss på Google plus',
  "loggedOut.secondThoughts": 'Ångrat dig?',
  "loggedOut.loginAgain": 'Logga in igen',

  // Feedback
  "feedback.modal.header": "Skicka feedback till Get a Newsletter",
  "feedback.modal.text": "Har du förslag på hur vi kan göra verktyget ännu bättre eller har du hittat en bugg någonstans? Vi uppskattar din feedback! Fyll i formuläret nedan så återkommer vi så fort vi kan.",

  //Template
  "template.editAdvancedTemplate": 'Redigera din avancerade mall',
  "template.createAdvancedTemplate": 'Skapa en avancerad mall',

  //Subscriptions
  "subscription.delete.areYouSure": `{count, plural,
    one {Är du säker på att du vill radera en prenumeration?}
    other {Är du säker på att du vill radera  {count} prenumerationer?}
  }`,
  "subscription.cancel.areYouSure": `{count, plural,
    one {Är du säker på att du vill avprenumerera en prenumeration?}
    other {Är du säker på att du vill avprenumerera {count} prenumerationer?}
  }`,
  "subscription.move.areYouSure": `{count, plural,
    one {Är du säker på att du vill flytta en prenumeration?}
    other {Är du säker på att du vill flytta {count} prenumerationer?}
  }`,
  "subscription.copy.areYouSure": `{count, plural,
    one {Är du säker på att du vill kopiera en prenumeration?}
    other {Är du säker på att du vill kopiera {count} prenumerationer?}
  }`,
  'subscriptions.modalHeader.deleteSubscriptions': `{count, plural,
    one {Radera prenumeration}
    other {Radera prenumerationer}
  }`,
  'subscriptions.modalHeader.exportSubscriptions': `{count, plural,
    one {Exportera prenumeration}
    other {Exportera {count} prenumerationer}
  }`,
  'subscriptions.delete.areYouSure': `{count, plural,
    one {Är du säker på att du vill radera en prenumeration?}
    other {Är du säker på att du vill radera {count} prenumerationer?}
  }`,
  'subscriptions.listEmpty': 'Just nu finns det inga prenumerationer på den här listan.',
  'subscriptions.cancelled': 'Prenumerationen avslutades {date}',
  'subscriptions.created': 'Prenumerationen skapades {date}',

  "contacts.subscription.title": "Skapa innehåll som dina läsare verkligen vill ha",
  "contacts.subscription.description": "Dina prenumeranter är nyckeln till din framgång. Du lägger till prenumerationer när du importerar dina kontakter. Är du redo att sätta igång?",

  //Page titles

  "title.default": 'Get a Newsletter',
  "title.privacyPolicy": 'Integritetspolicy',
  "title.signup": 'Registrera ett konto',
  "title.myAccount": 'Mitt konto',
  "title.forms": 'Formulär',
  "title.attributes": 'Attribut',
  "title.contacts": 'Kontakter',
  "title.autoresponders": 'Automatiska utskick',
  "title.termsOfUse": 'Användarvillkor',
  "title.reports": 'Rapporter',
  "title.upgradeAccount": 'Uppgradera ditt konto',
  "title.lists": 'Listor',
  "title.mails": 'Utskick',
  "title.templates": 'Mallar',
  "title.subscriptions": 'Prenumerationer',
  "title.forgotPassword": 'Glömt lösenord',
  "title.login": 'Logga in',
  "title.dashboard": 'Start',
  "title.createSubscriptionForm": 'Skapa prenumerationsformulär',
  "title.popupForm": 'Popup-formulär',
  "title.regularForm": 'Inbäddat formulär',
  "title.scheduledMails": 'Schemalagda mail',
  "title.stoppedMails": 'Stoppade mail',
  "title.pendingMails": 'Utskick under granskning',
  "title.drafts":  'Utkast',
  "title.sentMails": 'Skickade mail',

  //Feedback
  "feedback.title": 'FEEDBACK',

  "header.privateBeta": `Du är i beta-versionen av vårt nya verktyg.`,
  "header.goBack": `Gå tillbaka`,
  "header.toOld": `till den gamla versionen eller`,
  "header.sendFeedback": `skicka feedback`,

  'accountStatus.numberOfMails' : `{maxNumberOfMails} mails`,
  'accountStatus.mailsLeft': `Antal mail kvar att använda denna månad: {balance}/{maxNumberOfMails}`,
  'accountStatus.nextBilling': 'Nästa dragning sker {date}.',

  // 27.06.2019
  "reports.sendToSubscribers.text": `We are gathering your new subscribers. This can take some time, you can put this process in the background and we will send the mails when it's ready.`,
  "reports.sendToSubscribers.success": 'All done, sent to {count} new subscribers.',
  "reports.sendToSubscribers.error": 'Something went wrong :(',
  "reports.sendToSubscribers.btn": 'Skicka till nytillkommna prenumeranter',

  // 01.07.2019
  "mails.pending.delete": `Radera mail`,

  // 04.07.2019
  "contacts.yourFilteredResult": "Ditt filtrerade resultat: Visar {firstNum} av {secNum} kontakter.",
  'subscriptions.yourFilteredResult': "Ditt filtrerade resultat: Visar {firstNum} av {secNum} prenumeranter.",
  "contacts.resetFilters": "Återställ filter",
  "contacts.noResultsInFilter": "Inga filtrerade resultat",
  "contacts.noSearchInSearch": "Inga sökträffar",
  'subscriptions.numberOf': "Antal prenumerationer:",

  //05.07.2019
  "accountStatus.nextPaymentDue": "Nästa dragning sker {date}",
  "accountStatus.theBalanceReset": "Kontobalansen återställs den {numOfDay}:e varje månad",

  //09.07.2019
  "importContacts.summary.errorsLink": 'Ladda ner error-file',
  "importContacts.summary.errorsCount": '{count} fel',

  //10.07.2019
  "overviewChart.empty.noActivities": 'Ingen aktivitet än',
  "overviewChart.empty.title": 'Du har inte skickat några nyhetsbrev än',
  "overviewChart.empty.text": `I den här sektionen kommer statistik från ditt senaste nyhetsbrev att visas. Skicka ditt första nyhetsbrev och ta del av värdefull statistik.`,
  "overviewChart.emptyLinks": 'Här kommer du se de mest populära länkarna.',
  "overviewChart.emptyRecipients": 'Här kommer du se de mest aktiva mottagare.',

  //11.07.2019
  "common.rememberMe": 'Kom ihåg mig',
  "recipients.cancelled.title": 'Grymt jobbat! ',
  "recipients.cancelled.description": 'Ingen har valt att avsluta sin prenumeration.',
  "recipients.opened.title": "Ännu har ingen öppnat, men var inte orolig.",
  "recipients.opened.description": "Inom kort kommer du att se en lista av alla mottagare som har läst ditt utskick.",
  "subscriptions.copy.agree": `{count, plural,
    one {Kopiera prenumeration}
    other {Kopiera prenumerationer}
  }`,
  "subscriptions.move.agree": `{count, plural,
    one {Flytta prenumeration}
    other {Flytta prenumerationer}
  }`,
  "contacts.modal.reactivate": `Återaktivera kontakt`,
  "contacts.modal.reactivateModal": 'Är du säker att du vill återaktivera den här kontakten?',

  // 15.07.2019
  "toasters.feedback.sent": 'Tack för din feedback, ditt meddelande har skickats',
  "toasters.token.updated": 'Token har uppdaterats',
  "toasters.token.created": 'Token har skapats',
  "toasters.token.deleted": 'Token har raderats',
  "toasters.owner.updated": 'Kontoägare har uppdaterats',
  "toasters.billingAddress.updated": 'Dina faktureringsadress har uppdaterats',
  "toasters.invoice.updated": 'Dina fakturainställningar har uppdaterats',
  "toasters.accountDetails.updated": 'Dina kontouppgifter har uppdaterats',
  "toasters.sender.updated": 'En avsändare har uppdaterats',
  "toasters.sender.created": 'En avsändare har skapats',
  "toasters.sender.deleted": 'En avsändare har raderats',
  "toasters.profile.updated": 'Din profil har uppdaterats',
  "toasters.partner.removed": 'En partner har raderats',
  "toasters.resetLink.check": 'En återställningslänk har skickats till din e-post. Kika i din inkorg',
  "toasters.password.changed": 'Ditt lösenord har ändrats',
  "toasters.subscription.reactivated": 'Prenumeration har återaktiverats',
  "toasters.subscription.cancelled": `{count, plural,
    one {En prenumeration har avprenumererats}
    other {{count} prenumerationer har avprenumererats}
  }`,
  "toasters.subscription.deleted": `{count, plural,
    one {En prenumeration har raderats}
    other {{count} prenumerationer har raderats}
  }`,
  "toasters.subscription.copied": `{count, plural,
    one {En prenumeration har kopierats}
    other {{count} prenumerationer har kopierats}
  }`,
  "toasters.subscription.moved": `{count, plural,
    one {En prenumeration har flyttats}
    other {{count} prenumerationer har flyttats}
  }`,
  "toasters.contact.deleted": `{count, plural,
    one {En kontakt har raderats}
    other {{count} kontakter har raderats}
  }`,
  "toasters.list.removed": 'Listan har raderats',
  "toasters.contact.edited": 'Kontakten har redigerats',
  "toasters.contact.reactivated": 'Kontakten har återaktiverats',
  "toasters.form.deleted": 'Formuläret har raderats',
  "toasters.attribute.deleted": 'Attributet har raderats',
  "toasters.attribute.created": 'Attributet har skapats',
  "toasters.attribute.updated": 'Attributet har uppdaterats',
  "toasters.contact.added": 'Kontakten har skapats',
  "toasters.subscription.added": `{count, plural,
    one {En prenumeration har skapats}
    other {{count} prenumerationer har skapats}
  }`,
  "toasters.contact.updated": 'Kontakten har uppdaterats',
  "toasters.contact.created": 'Kontakten har skapats',
  "toasters.contact.moved": 'Kontakter har flyttats',
  "toasters.contact.copied": 'Kontakten har kopierats',
  "toasters.contact.cancelled": 'Kontakten har avprenumererats',
  "toasters.mail.sent": 'Ditt utskick har nu gått iväg',
  "toasters.email.confirmed": 'Din e-post är bekräftad',
  "toasters.form.updated": 'Formuläret har uppdaterats',
  "toasters.mail.updated": 'Utskicket har uppdaterats',
  "toasters.mail.created": 'Utskicket har skapats',
  "toasters.template.updated": 'Mallen har uppdaterats',
  "toasters.template.created": 'Mallen har skapats',
  "toasters.autoresponder.paused": 'Det automatiska utskicket har pausats',
  "toasters.autoresponder.resumed": 'Det automatiska utskicket har återupptagits',
  "toasters.autoresponder.copied": 'Det automatiska utskicket har kopierats',
  "toasters.autoresponder.deleted": 'Det automatiska utskicket har raderats',
  "toasters.draft.copied": 'Utkastet har kopierats',
  "toasters.draft.deleted": 'Utkastet har raderats',
  "toasters.template.copied": 'Mallen har kopierats',
  "toasters.template.deleted": 'Mallen har raderats',
  "toasters.pending.deleted": 'Väntande utskick har raderats',
  "toasters.scheduled.copied": 'Schemalagda utskick har kopierats',
  "toasters.scheduled.stopped": 'Schemalagda utskick har stoppats',
  "toasters.sent.copied": 'Skickade mail har kopierats',
  "toasters.sent.deleted": 'Skickade mail har raderats',
  "toasters.sending.inprocess": 'Pågående utskick',
  "toasters.mail.setAsDraft": 'Mail har nu omvandlats till ett utkast',
  "toasters.mail.deleted": 'Mailet har raderats',
  "toasters.account.paused": 'Kontot kommer snart att pausas',
  "toasters.account.activated": 'Kontot kommer snart att aktiveras',
  "toasters.report.deleted": 'Rapporten har raderats',
  "toasters.list.updated": 'Listan har uppdaterats',
  "toasters.list.created": 'Listan har skapats',
  "toasters.partner.updated": 'En partner har uppdaterats',
  "toasters.partner.created": 'En partner har skapats',

  // validation
  "validations.email": 'E-postadressen är ogiltig',
  "validations.url": 'Ogiltig URL',
  "validations.password.match": "Det angivna lösenordet matchar inte",
  "validations.characters.max": "Lösenordet kan bara innehålla {max} tecken eller mindre",
  "validations.characters.min": "Lösenordet måste innehålla minst {min} tecken",
  "validations.required": 'Det här fältet är obligatoriskt',
  "validations.html": 'Kod krävs',
  "validations.columns": "Det går inte att koppla en kolumn till flera fält",

  // errors
  "errors.payment": "Din betalning kunde inte slutföras, prova gärna med ett annat kort",

  // 17.06.2019
  "dashboard.latestNewsletter": 'Mitt senaste nyhetsbrev {data}',
  "overview.opened": 'har öppnat',
  "overview.notOpened": 'har inte öppnat',
  "overview.bounced": 'har studsat',
  "overview.cancelled": 'har avslutat ',
  "overview.clicked": 'har klickat',
  "common.send": 'Skicka',
  "contacts.action.choose": 'Välj åtgärd',
  "contacts.action.export": 'Exportera',
  "contacts.action.delete": 'Radera',
  "contacts.action.addSubscription": 'Lägg till prenumeration(er) för...',
  "contacts.table.email": 'E-post',
  "contacts.table.name": 'Namn',
  "contacts.table.subscriptions": 'Prenumerationer',
  "contacts.table.updated": 'Uppdaterad',
  "contacts.table.active": 'Aktiv',
  "contacts.table.search": 'Sök på namn eller e-postadress...',
  "common.download.browse": 'Bläddra',
  "common.download.fileChoosen": 'Fil vald',
  "common.select.dots": 'Välj...',
  "common.select.noOptions": "Inga alternativ",
  "common.select.create": 'Skapa "{inputValue}"',
  "subscriptions.table.contact": 'Kontakt',
  "subscriptions.table.list": 'Lista',
  "subscriptions.table.status": 'Status',
  "subscriptions.table.created": 'Skapat',
  "subscriptions.table.cancelled": 'Avslutad',
  "subscriptions.table.autoresponder": 'Automatiskt utskick',
  "attributes.table.name": 'Attributnamn',
  "attributes.table.usage": 'Användning',
  "attributes.table.code": 'Kod att använda i utskick',
  "attributes.modal.delete": 'Du kommer att ta bort följande attribut: {value}',
  "forms.table.name": 'Namn',
  "forms.table.list": 'Lista',
  "forms.table.forms": 'formulär',
  "drafts.table.subject": 'Ämne',
  "drafts.table.updated": 'Uppdaterad',
  "drafts.table.actions": 'Verktyg',
  "drafts.table.items": 'utkast',
  "autoresponder.table.subject": 'Ämne',
  "autoresponder.table.status": 'Status',
  "autoresponder.table.sentAfter": 'Skickas efter',
  "autoresponder.table.list": 'Lista',
  "autoresponder.table.actions": 'Verktyg',
  "autoresponder.table.preview": 'Förhandsgranska automatiskt utskick',
  "autoresponder.table.new": 'Skapa kopia av automatiskt utskick',
  "autoresponder.table.deleteModal": 'Du kommer att ta bort följande automatiskt utskick: {name}',
  "autoresponder.table.deleteReport": 'Ja, jag vill radera den tillhörande rapporten',
  "autoresponder.table.delete": 'Radera automatiskt utskick',
  "autoresponder.table.pause": 'Pausa automatiskt utskick',
  "autoresponder.table.resume": 'Återaktivera automatiskt utskick',
  "autoresponder.table.edit": 'Redigera automatiskt utskick',
  "autoresponder.table.replan": 'Planera om ditt automatiskt utskick',
  "autoresponder.table.pausedSince": 'Pausat sedan {time}',
  "autoresponder.table.running": 'Pågår',
  "autoresponder.table.title": 'automatiskt utskick',
  "scheduled.table.items": 'schemalagda utskick',
  "scheduled.table.subject": 'Ämne',
  "scheduled.table.isSent": 'Skickas',
  "scheduled.table.list": 'Lista',
  "scheduled.table.actions": 'Verktyg',
  "scheduled.table.pause": 'Stoppa utskick',
  "scheduled.table.replan": 'Planera om ditt utskick',
  "scheduled.table.stopMessage": 'Är du säker på att du vill stoppa detta utskick?',
  "sent.table.items": 'skickade mail',
  "sent.table.subject": 'Ämne',
  "sent.table.sent": 'Skickat',
  "sent.table.list": 'Lista',
  "sent.table.actions": 'Verktyg',
  "mail.delete.areYouSure": 'Du kommer att ta bort följande utskick: {subject}?',
  "stopped.table.items": 'stoppade utskick',
  "stopped.table.subject": 'Ämne',
  "stopped.table.status": 'Status',
  "stopped.table.list": 'Lista',
  "stopped.table.actions": 'Verktyg',
  "stopped.table.setAsDraft": 'Flytta till utkast',
  "stopped.table.sendNow": 'Skicka nu',
  "stopped.table.notEnough": 'Stoppat, kontot räckte inte till -',
  "pending.table.items": 'väntar på utskick',
  "pending.table.subject": 'Ämne',
  "pending.table.list": 'Lista',
  "pending.table.actions": 'Verktyg',
  "templates.table.items": 'templates',
  "templates.table.subject": 'Ämne',
  "templates.table.updated": 'Uppdaterad',
  "templates.table.actions": 'Verktyg',
  "templates.table.edit": 'Redigera mall',
  "templates.table.preview": 'Förhandsgranska mall',
  "templates.table.copy": 'Kopiera mall',
  "templates.table.deleteMessage": 'Du kommer att ta bort följande mall: {name}',
  "templates.table.delete": 'Radera mall',
  "templates.table.openAsNew": 'Öppna mall som nytt utkast',
  "reports.placeholder.title": `Håll dig uppdaterad!`,
  "reports.placeholder.description": `Genom detaljerade rapporter kan du lära dig mer om dina prenumeranter. För varje varje nyhetsbrev du skickar, får du en ny rapport med värdefull information om ditt senaste utskick.`,
  "reports.table.items": 'rapporter',
  "reports.table.report": 'Rapport',
  "reports.table.sent": 'Skickat',
  "reports.table.lists": 'Lista',
  "reports.table.sentTo": 'Skickat till',
  "reports.table.opened": 'Öppnade',
  "reports.table.actions": 'Verktyg',
  "reports.tab.newsletters": 'Nyhetsbrev',
  "reports.tab.autoresponders": 'Automatiska utskick',
  "invoices.table.items": 'fakturor',
  "invoices.table.invoiceNo": 'Fakturanummer',
  "invoices.table.dueDate": 'Förfallodatum',
  "invoices.table.amount": 'Summa',
  "invoices.table.payment": 'Betalning',
  "invoices.table.actions": 'Verktyg',
  "invoices.table.notes": 'Anteckning',
  "account.card.removed": 'Om du klickar fortsätt så kommer ditt nuvarande kort att försvinna.',
  "languages.swedish": 'Svenska',
  "languages.english": 'Engelska',
  "senders.table.items": 'avsändare',
  "senders.table.name": 'Avsändarnamn',
  "senders.table.email": 'Avsändarmail',
  "senders.table.created": 'Skapat',
  "senders.table.actions": 'Verktyg',
  "common.create": 'Skapa',
  "lists.table.items": 'listor',
  "forms.input.placeholder": 'Mitt grymma formulär...',
  "forms.input.confirmationMail": 'Välkommen som prenumerant på ##list_name##',
  "forms.input.message": `Hej!
Du har lagts till som prenumerant på ##list_name##.
Innan du kan få våra utskick ber vi dig bekräfta din e-postadress genom att klicka på nedanstående länk:
##confirmation_link##`,
  "forms.input.subscribe": 'Prenumerera',
  "templates.startFromScratch": 'Börja från grunden',
  "upgrade.largestAccount.title": 'Du har redan vårt största konto',
  "upgrade.largestAccount.description": '{link} för mer information',
  "upgrade.largestAccount.getInTouch": 'Kontakta oss',

  // 25.07.2019
  "draft.editAdvancedDraft": 'Redigera din avancerade utkast',
  "draft.createAdvancedDraft": 'Skapa en avancerad utkast',

  // Actions tooltips
  "actions.list.edit": 'Redigera lista',
  "actions.list.remove": 'Radera lista',
  "actions.attribute.edit": 'Redigera attribut',
  "actions.attribute.remove": 'Radera attribut',
  "actions.form.edit": 'Redigera formulär',
  "actions.form.remove": 'Radera formulär',
  "actions.partner.remove": 'Radera partner',
  "actions.sender.edit": 'Redigera avsändare',
  "actions.sender.remove": 'Radera avsändare',
  "actions.token.edit": 'Redigera token',
  "actions.token.remove": 'Radera token',

  // 11.11.2019
  "bounces.permanent": "permanenta studsar",
  "bounces.temporary": "tillfälliga studsar",

  // 12.11.2019
  "common.subscribers": "Prenumeranter",

  // 13.11.2019
  "common.titles.didnotopen": "Öppnade inte",
  "common.table.column.contact": "E-post",
  "common.table.column.opens": "Öppningar",
  "common.table.column.clicks": "Länkklick",
  "common.table.column.error_type": "Typ",
  "common.table.column.description": "Beskrivning",
  "common.table.column.active": "Aktiva",

  "common.delete.areYouReallySure": "Ja, jag är säker. Jag är också medveten om att denna åtgärd är permanent och inte kan ångras.",

  // 21.11.2019
  "recaptcha.protected.text": "Denna webbplats är skyddad av reCAPTCHA och Google {privacy} och {terms} gäller.",
  "recaptcha.privacy.text": "Integritetspolicy",
  "recaptcha.terms.text": "Användarvillkor",

  "common.welcomeBack": "Välkommen tillbaka.",
  "common.loginToGetStarted": "Logga in för att komma igång.",

  "common.authlayout.signin.img.alt": "Logga in för Get a Newsletter",
  "common.authlayout.signup.img.alt": "Uppgradera konto",
  "common.authlayout.forgot.img.alt": "Glömt ditt lösenord",

  "commoun.authlayout.bluelogo.alt": "Get a Newsletter blå logotyp",
  "advancededitor.saveAndPreview": "Spara och förhandsgranska",
  "advancededitor.visualHTML": "Visuell HTML",
  "advancededitor.saveAndGo": "Spara och gå",
  "advancededitor.saveAndStay": "Spara och stanna",
  "advancededitor.HTMLCode": "HTML-kod",
  "advancededitor.editCss": "Redigera CSS",
  "advancededitor.textMessage": "Textmeddelande",
  "advancededitor.tab.html.text": "För att lägga till CSS till din e-post, lägg till den i fältet nedan. Du måste spara e-posten innan css ger någon påverkan. CSS läggs till i huvudet i posten och du behöver inte ange stil-taggen, bara skriva CSS direkt.",
  "advancededitor.tab.css.text": "För att lägga till CSS till din e-post, lägg till den i fältet nedan. Du måste spara e-posten innan css ger någon påverkan. CSS läggs till i huvudet i posten och du behöver inte ange stil-taggen, bara skriva CSS direkt.",
  "advancededitor.tab.plain.text": "Som ett komplement till nyhetsbrevet med bilder, skriv en version i ren text för dem som inte kan läsa html-meddelanden i sitt e-postprogram. Om textmeddelandefältet är tomt får abonnenten vårt standardmeddelande och en länk för att öppna e-posten i deras webbläsare.",

  // 25.11.2019
  "extramail.description": "För att starta sändningen kan du antingen uppgradera ditt konto till nästa nivå eller {newline} köpa {mailsToBuy} för totalt {priceCurrency}",
  "extramail.mailsToBuy": "{count} e-post",
  "extramail.question": "Hur vill du fortsätta?",
  "extramail.buyExtraMail": "Köp extra mail",
  "extramail.or": "eller",
  "extramail.upgradeAccount": "Uppgradera ditt konto",
  "common.export.error": "Felet uppstår och rapportens data kan inte exporteras.",

  // 28.11.2019
  'common.notifications': 'Notifikationer',

  // 02.12.2019
  "contacts.subscription.nextAutoresponder": "Nästa utskick",
  "extramail.title": "Du har inte tillräckligt med e-post kvar på ditt konto",

  // 04.12.2019
  "extracontacts.title": "Du har för många kontakter på ditt konto.",
  "extracontacts.description": "För att starta sändningen måste du uppgradera ditt konto",

  // 09.12.2019
  "paymentmodal.epay.security.message": "ePay / Payment Solutions är PCI-certifierat av VISA / Mastercard och all kommunikation är krypterad via en säker anslutning.",
  "apitoken.edit": "Redigera API-token",
  "pausedaccount.warning": "Ditt konto är för närvarande pausat och du måste aktivera det för att skicka e-post igen.",
  "pausedaccount.activate": "Aktivera ditt konto",

  // 10.12.2019
  "common.saveAndContinue": "Spara och fortsätt",

  // 11.12.2019
  "form.error.invalidVat": "Ogiltigt VAT ID",
  "form.error.invalidOrgNumber": "Ogiltigt organisationsnummer",

  // 19.12.2019
  "common.select.field.dots": 'Välj {field} kolumn...',

  //16.01.2020
  "auth.form.title.common": 'Registrera konto',
  "auth.form.title.forFree.common": ' ',

  "auth.form.description.starweb": 'Som Starweb kund har du 1000 kostnadsfria mail/månad. Inga dolda avgifter, ingen uppsägningstid.',
  "auth.logo.title.starweb": "I SAMARBETE MED STARWEB",

  "auth.form.description.wikinggruppen": 'Som kund till Wikinggruppen har du {mails} kostnadsfria mail/månad. Inga dolda avgifter, ingen uppsägningstid.',
  "auth.logo.title.wikinggruppen": "I SAMARBETE MED WIKINGGRUPPEN",

  "auth.form.description.ehandel": 'Som medlem i Ehandel.se får du utrymme för 2000 kontakter helt kostnadsfritt. Inga dolda avgifter, ingen uppsägningstid.',
  "auth.logo.title.ehandel": "I SAMARBETE MED EHANDEL.SE",

  "auth.form.description.payson": 'Som kund till Payson får du skicka nyhetsbrev gratis upp till 2000 kontakter i tre månader. Inga dolda avgifter, ingen uppsägningstid.',
  "auth.logo.title.payson": "I SAMARBETE MED PAYSON",

  "auth.form.description.nyforetagar": 'Som kund till NyföretagarCentrum får du skicka 2 500 mail/månad till 500 kontakter helt kostnadsfritt. Inga dolda avgifter, ingen uppsägningstid.',
  "auth.logo.title.nyforetagar": "I SAMARBETE MED NYFÖRETAGARCENTRUM",

  "contacts.table.lists.search": 'Sök efter namn...',
  'lists.numberOf': "Antal listor:",
  "lists.yourFilteredResult": "Ditt filtrerade resultat: Visar {firstNum} av {secNum} -listor.",
  "banner.title": "Verktyg för nyhetsbrev",
  "common.form.name.placeholder": "Mitt formulärnamn...",

  "offline.modal.title": "Anslutningsproblem",
  "offline.modal.description": "Vi kan tyvärr inte ansluta till våra servrar just nu. Prova att ansluta genom att klicka på knappen här nedan.",
  "offline.modal.checkIfOnlineBtn": "Ladda om sidan",
}
