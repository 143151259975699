import React from 'react';

let key = 1;
const getKey = () => key += 1;

const FormValidationError = ({ validationMessages = null, className }) => {
  if (Array.isArray(validationMessages)) {
    return validationMessages.map(msg => <div className={`invalid-feedback ${className}`} key={getKey()}>{msg}</div>);
  }

  if (
    (typeof validationMessages === 'string' || typeof validationMessages === 'object') &&
    validationMessages !== null
  ) {
    return <div className={`invalid-feedback ${className}`}>{validationMessages}</div>;
  }

  return null;
};

export default FormValidationError;
