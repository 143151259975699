import React from 'react'
import { compose } from 'redux'

import { connectResource } from 'common/utils/resource'
import Spinner from 'components/Spinner'
import { TextField } from 'common/forms/fields'
import AddAttributeForm from './AddAttributeForm'

export function AttributesForm(props) {
  let Component = (
    <AddAttributeForm attributes={props.attributes}/>
  )
  if (props.showItems !== false) {
    Component = (
      <>
        <div className="row">
          <div className="col">
            {
              props.attributes.data && props.attributes.data.map((item, id) => (
                <div className="form-group" key={item.name + id}>
                  <TextField name={`attributes.${item.code}`} label={item.name}/>
                </div>
              ))
            }
          </div>
        </div>
        <AddAttributeForm attributes={props.attributes}/>
      </>
    )
  }
  return (
    <>
      <Spinner show={props.attributes && props.attributes.isLoading} />
      {Component}
    </>
  )
}

export default compose(
  connectResource({
    namespace: 'attributes',
    endpoint: 'attributes',
    list: true,
    filters: { paginate_by: 100 },
    async: true,
  }),
)(AttributesForm)
