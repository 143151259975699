import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { Modal, ModalBody, ModalHeader, ModalFooter } from 'components/Modal'
import ButtonSpinner from 'components/ButtonSpinner'

export class OfflineModal extends Component {
  render() {
    const { checkOnline, isLoading } = this.props
    return (
      <Modal>
        <ModalHeader
          name={<FormattedMessage id="offline.modal.title" />}
        />
        <ModalBody className="text-center">
          <p className="lead mt-4">
            <FormattedMessage id="offline.modal.description" />
          </p>
        </ModalBody>
        <ModalFooter className="text-center">
          <ButtonSpinner
            className="btn btn-success"
            onClick={checkOnline}
            spin={isLoading}
            disabled={isLoading}
          >
            <FormattedMessage id="offline.modal.checkIfOnlineBtn" />
          </ButtonSpinner>
        </ModalFooter>
      </Modal>
    )
  }
}

export default OfflineModal
