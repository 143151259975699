import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { compose } from 'redux'

import { getCookie } from 'lib/utils'
import { PrivateRoute } from 'common/widgets'
// Pages
import AddPopupForm from 'components/Pages/Forms/AddPopupForm'
import AddRegularForm from 'components/Pages/Forms/AddRegularForm/AddRegularFormContainer'
import LoadingComponent from 'components/PageLoading'
import NotFound from 'components/Pages/NotFound'
import SigninContainer from 'components/Pages/Auth/SigninContainer'
// Layouts
import AppLayout from './AppLayout'
import AuthLayout, { AUTH_LAYOUT_TYPES } from 'components/Pages/Auth/AuthLayout'

const Home = Loadable({
  loader: () => import('../Pages/Home'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const Contacts = Loadable({
  loader: () => import('../Pages/Contacts'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const Mails = Loadable({
  loader: () => import('../Pages/Mails'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const StartAutoresponder = Loadable({
  loader: () => import('../Pages/Mails/AutoresponderMail/AutoresponderMailContainer'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const Reports = Loadable({
  loader: () => import('../Pages/Reports'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const DetailedReport = Loadable({
  loader: () => import('../Pages/DetailedReport/DetailedReportContainer'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const Support = Loadable({
  loader: () => import('../Pages/Support'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const Privacy = Loadable({
  loader: () => import('../Pages/Privacy'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const Terms = Loadable({
  loader: () => import('../Pages/Terms'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const Account = Loadable({
  loader: () => import('../Pages/Account'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const Upgrade = Loadable({
  loader: () => import('../Pages/Upgrade'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const SignUp = Loadable({
  loader: () => import('../Pages/Auth/SignupContainer'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const Forgot = Loadable({
  loader: () => import('../Pages/Auth/ForgotPasswordContainer'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const Reset = Loadable({
  loader: () => import('../Pages/Auth/ResetPasswordContainer'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const LoggedOut = Loadable({
  loader: () => import('../Pages/LoggedOut/LoggedOutContainer'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});

const EmailConfirmation = Loadable({
  loader: () => import('../Pages/EmailConfirmation/EmailConfirmationContainer'),
  loading: LoadingComponent,
  timeout: 10000,
  delay: 200
});


const Routes = ({ token, code }) =>{
  return (
  <Switch>
    <AppLayout>
      <PrivateRoute
        needRedirect={!token}
        redirectTo="/"
        exact
        path="/signin"
        layout={AuthLayout}
        layoutProps={{type: AUTH_LAYOUT_TYPES.SIGNIN }}
        component={SigninContainer}
      />
      <PrivateRoute
        needRedirect={!token}
        redirectTo="/"
        exact
        path="/signup"
        layout={AuthLayout}
        layoutProps={{type: AUTH_LAYOUT_TYPES.SIGNUP, code}}
        component={SignUp}
      />
      <PrivateRoute
        needRedirect={!token}
        redirectTo="/"
        exact
        path="/forgot"
        layout={AuthLayout}
        layoutProps={{type: AUTH_LAYOUT_TYPES.FORGOT}}
        component={Forgot}
      />
      <PrivateRoute
        needRedirect={!token}
        redirectTo="/"
        exact
        path="/reset/:uidb64/:token"
        layout={AuthLayout}
        layoutProps={{type: 'forgot'}}
        component={Reset}
      />

      <Route needRedirect={!token} redirectTo="/" exact path="/logged_out" component={LoggedOut} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" exact path="/" component={Home} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" path="/email-confirmation/:id?" component={EmailConfirmation} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" path="/contacts" component={Contacts} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" exact path="/subscriptions" component={Contacts} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" exact path="/attributes" component={Contacts} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" exact path="/lists" component={Contacts} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" exact path="/forms" component={Contacts} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" exact path="/forms/add/popup" component={AddPopupForm} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" exact path="/forms/regular/add" component={AddRegularForm} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" exact path="/forms/regular/edit/:id" component={AddRegularForm} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" path="/mails" component={Mails} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" path="/responders/start/:id" component={StartAutoresponder} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" exact path="/reports" component={Reports} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" path="/reports/:id" component={DetailedReport} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" exact path="/support" component={Support} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" path="/account" component={Account} />
      <PrivateRoute needRedirect={token} redirectTo="/signin" path="/upgrade" component={Upgrade} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/404" component={NotFound} />
    </AppLayout>
  </Switch>
)}

export default compose(
  withRouter,
  connect((state, props) => {
    const params = new URLSearchParams(props.location.search)
    return {
      token: get(state.resource, 'session.data.token') || getCookie('jwt'),
      code: params.get('c')
    }
  }),
)(Routes)

